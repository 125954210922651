/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import outletslider from "../../common/images/outletslider.jpg";
import MembershipDelivery from "../../common/images/membership-delivery.png";
import MembershipDinein from "../../common/images/membership-dinein.png";
import MembershipTakeaway from "../../common/images/membership-takeaway.png";
import JoinOne from "../../common/images/join-barn-family-one.png";
import JoinTwo from "../../common/images/join-barn-family-two.png";
import JoinThree from "../../common/images/join-barn-family-three.png";
import Barnmember from "../../common/images/barn-member.png";
import Seperator from "../../common/images/sec-seperator.png";


import seperatorleft from "../../common/images/sec-seperator-left.png";

import seperatorright from "../../common/images/sec-seperator-right.png";



import Silvermember from "../../common/images/silver-member.png";
import Goldmember from "../../common/images/gold-member.png";
import EarnPoints from "../../common/images/earn-points.png";
import Repate from "../../common/images/repate.png";
import Refer from "../../common/images/refer-friend.png";
import BirthdayCake from "../../common/images/benefits-cake.png";
import WelcomeGift from "../../common/images/benefits-gift.png";
import BenefitsCode from "../../common/images/benefits-promo-code.png";
import clientimage from "../../common/images/client-1.jpg";
import clientimageone from "../../common/images/client-2.jpg";
import clientimagetwo from "../../common/images/client-3.jpg";
import clientimagethree from "../../common/images/client-4.jpg";
import clientimagefour from "../../common/images/client-5.jpg";

import { GET_STATIC_BLOCK, GET_BANNER_LIST } from "../../actions";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import Testimonial from "../Home/HomeSubCompo/Testimonial";
import Slider from "react-slick";

var Parser = require("html-react-parser");
const settingsJourney = {
  items: 1,
  autoplay: false,
  loop: true,
  nav: true,
};

var settingsourclient = {
  items: 6,
  nav: false,
  autoplay: true,
  dots: true,
  margin: 0,
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};
class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staticblacks: [],
      homejournyBlk: "",
      partnerBlk: "",
      partnerimgpath: "",
      partnerimg: "",
      homejournyimgBlk: [],
    };
    this.props.getStaticBlock();
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".dvLoadrCls").hide();
    }, 2000);

    $(document).ready(function() {
      $(document).on('touchstart', '.carousel', function(event){
         const xClick = event.originalEvent.touches[0].pageX;
         $(this).one('touchmove', function(event){
             const xMove = event.originalEvent.touches[0].pageX;
             const sensitivityInPx = 5;
     
             if( Math.floor(xClick - xMove) > sensitivityInPx ){
                 $(this).carousel('next');
             }
             else if( Math.floor(xClick - xMove) < -sensitivityInPx ){
                 $(this).carousel('prev');
             }
         });
         $(this).on('touchend', function(){
             $(this).off('touchmove');
         });
     });
   });

  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var homeAboutBlk = "",
        homePantryBlk = "",
        homepromoBlk = "",
        homeBlogBlk = "",
        homejournyBlk = "",
        homejournyimgpath = "",
        homegrammedBlk = "",
        homecafe = "",
        homeblksorting = "",
        partnerBlk = "",
        partnerimgpath = "",
        partnerimg = [];
      var homejournyimgBlk = [];
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "home-about-us") {
            homeAboutBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-pantry") {
            homePantryBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-promotion") {
            homepromoBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-blog-post") {
            homeBlogBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-our-journey") {
            homejournyBlk = data.staticblocks_description;
            homejournyimgBlk = data.gallery_images;
            homejournyimgpath = data.gallery_image_path;
            return "";
          }
          if (data.staticblocks_slug === "home-journey-grammed") {
            homegrammedBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-order-now") {
            homecafe = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "membership-block-sorting") {
            homeblksorting = data.staticblocks_description;
            homeblksorting = homeblksorting.replace("<p>", "");
            homeblksorting = homeblksorting.replace("</p>", "");
            return "";
          }
          if (data.staticblocks_slug === "our-shop-partner") {
            partnerBlk = data.staticblocks_description;
            partnerimgpath = data.gallery_image_path;
            partnerimg = data.gallery_images;
            return "";
          }
          partnerBlk;
        });
      }
      homeAboutBlk =
        homeAboutBlk !== "" && homeAboutBlk !== null
          ? Parser(homeAboutBlk)
          : homeAboutBlk;
      homePantryBlk =
        homePantryBlk !== "" && homePantryBlk !== null
          ? Parser(homePantryBlk)
          : homePantryBlk;
      homepromoBlk =
        homepromoBlk !== "" && homepromoBlk !== null
          ? Parser(homepromoBlk)
          : homepromoBlk;
      homeBlogBlk =
        homeBlogBlk !== "" && homeBlogBlk !== null
          ? Parser(homeBlogBlk)
          : homeBlogBlk;
      homejournyBlk =
        homejournyBlk !== "" && homejournyBlk !== null
          ? Parser(homejournyBlk)
          : homejournyBlk;
      homegrammedBlk =
        homegrammedBlk !== "" && homegrammedBlk !== null
          ? Parser(homegrammedBlk)
          : homegrammedBlk;
      homecafe =
        homecafe !== "" && homecafe !== null ? Parser(homecafe) : homecafe;

      partnerBlk =
        partnerBlk !== "" && partnerBlk !== null
          ? Parser(partnerBlk)
          : partnerBlk;

      this.setState({
        staticblacks: PropsData.staticblack,
        homeAboutBlk: homeAboutBlk,
        homePantryBlk: homePantryBlk,
        homepromoBlk: homepromoBlk,
        homeBlogBlk: homeBlogBlk,
        homejournyBlk: homejournyBlk,
        homejournyimgBlk: homejournyimgBlk,
        homejournyimgpath: homejournyimgpath,
        homegrammedBlk: homegrammedBlk,
        homecafe: homecafe,
        homeblksorting: homeblksorting,
        partnerBlk: partnerBlk,
        partnerimg: partnerimg,
        partnerimgpath: partnerimgpath,
      });
    }
  }

  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Membership</p>
          </div>
        </div>

        <div className="membership-info-page">
          <div className="container-one cms-content">
            <div className="container">
               <section className="home-our-journey welcombarn">
                  <div className="container">
                     <h2 className="home-title">
                     welcome to the <span> barn</span></h2>
                     <p>We’re making shopping at FoodBarn more rewarding. That's why we've build our Barn programme to help you earn more Barn points on any FoodBarn transactions on our website! Redeem points every time you shop and share with us. 
                     </p>
                     <div className="membership-infographic-products-main membership-infographic-welcome-section">
                           <ul className="products-list-ulmain">
                              <li className="products-single-li">
                                 <div className="products-image-div">
                                    <img src={MembershipTakeaway} />
                                    </div>
                                    <div className="product-info-div">
                                    <div className="product-title-maindiv">
                                       <div className="product-title">
                                          <h3>TAKEAWAYS</h3>
                                          <p>Grab and go</p>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li className="products-single-li">
                                 <div className="products-image-div">
                                    <img src={MembershipDelivery} />
                                 </div>
                                 <div className="product-info-div delivery-info-div">
                                    <div className="product-title-maindiv">
                                       <div className="product-title">
                                          <h3>DELIVERY</h3>
                                          <p>Within 1 hour</p>
                                       </div>
                                    </div>
                                 </div>    
                              </li>
                              <li className="products-single-li">
                                 <div className="products-image-div">
                                    <img src={MembershipDinein} />
                                 </div>
                                 <div className="product-info-div dine-info-div">
                                    <div className="product-title-maindiv">
                                       <div className="product-title">
                                          <h3>DINE IN</h3>
                                          <p>Skip the queue</p>
                                       </div>
                                    </div>
                                 </div>      
                              </li>
                           </ul>
                           
                     </div>
                  </div>
               </section>
                
                    <div className="member-bar">
                    <ul>
                    <li><img src={seperatorleft} /></li>
                    <li><span><img src={Seperator} /></span></li>
                    <li><img src={seperatorright} /></li>
                    </ul>
                    </div>

               <section className="home-our-journey">
                  <div className="container">
                  <h2 className="home-title">
                  Join the<span> barn</span> family</h2>
                  <p>With your Barn membership, enjoy exclusive member privileges, promotions, birthday treats and more. Your’re only a few steps away.  </p>
                    <div className="join-membership-infographic-products-main membership-infographic-products-main-desktop">
                        <ul className="products-list-ulmain">
                           <li className="products-single-li">
                                 <div className="products-image-div">
                                 <img src={JoinOne} />
                                 </div>
                                 <div className="product-info-div">
                                    <div className="product-title-maindiv">
                                       <div className="product-title">
                                          <p>Create a FoodBarn account under the ‘Login’ tab.</p>
                                       </div>
                                    </div>
                                 </div>
                           </li>
                           <li className="products-single-li">
                              <div className="products-image-div">
                                 <img src={JoinTwo} />
                                 </div>
                                 <div className="product-info-div ">
                                    <div className="product-title-maindiv">
                                       <div className="product-title">
                                          <p>Verify your email address to activate your account.</p>
                                       </div>
                                    </div>
                                 </div>    
                           </li>
                           <li className="products-single-li">
                              <div className="products-image-div">
                                 <img src={JoinThree} />
                                 </div>
                                 <div className="product-info-div ">
                                    <div className="product-title-maindiv">
                                       <div className="product-title">
                                          <p>Start collecting points with every spend and earn exclusive rewards!</p>
                                       </div>
                                    </div>
                                 </div>      
                           </li>
                        </ul>
                     </div>

            <div id="myCarousel" className="carousel slide mobile-barn-family" data-interval="false" data-ride="carousel">
               <ol className="carousel-indicators">
                  <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                  <li data-target="#myCarousel" data-slide-to="1"></li>
                  <li data-target="#myCarousel" data-slide-to="2"></li>
               </ol>

               <div className="carousel-inner">
                  <div className="item active">
                    <div className="products-single-li">
                     <div className="products-image-div">
                     <img src={JoinOne} />
                     </div>
                     
                     <div className="product-info-div">
                        <div className="product-title-maindiv">
                           <div className="product-title">
                              <p>Create a FoodBarn account under the ‘Login’ tab.</p>
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>

                  <div className="item">
                    <div className="products-single-li">
                     <div className="products-image-div">
                     <img src={JoinTwo} />
                     </div>
                     <div className="product-info-div">
                        <div className="product-title-maindiv">
                           <div className="product-title">
                              <p>Verify your email address to activate your account.</p>
                           </div>
                        </div>
                     </div>
                     </div> 
                  </div>
               
                  <div className="item">
                    <div className="products-single-li">
                     <div className="products-image-div">
                     <img src={JoinThree} />
                     </div>
                     <div className="product-info-div">
                        <div className="product-title-maindiv">
                           <div className="product-title">
                              <p>Start collecting points with every spend and earn exclusive rewards!</p>
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>

               <div className="carousel-arrow-parent">

                 <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                    <span className="glyphicon glyphicon-chevron-left"></span>
                    <span className="sr-only">Previous</span>
                 </a>
                 <a className="right carousel-control" href="#myCarousel" data-slide="next">
                    <span className="glyphicon glyphicon-chevron-right"></span>
                    <span className="sr-only">Next</span>
                 </a>
                </div>
            </div>


<div className="join-membership-infographic-products-main membership-infographic-products-main-mobile  destop-barn-family">
   <ul className="products-list-ulmain">
      <li className="products-single-li">
            <div className="products-image-div">
             <img src={JoinOne} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title">
                     <p>Create a FoodBarn account under the ‘Login’ tab.</p>
                  </div>
               </div>
            </div>
      </li>
      <li className="products-single-li">
           <div className="products-image-div">
             <img src={JoinTwo} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title">
                      <p>Verify your email address to activate your account.</p>
                  </div>
               </div>
            </div>    
      </li>
      <li className="products-single-li">
          <div className="products-image-div">
             <img src={JoinThree} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title">
                     <p>Start collecting points with every spend and earn exclusive rewards!</p>
                  </div>
               </div>
            </div>      
      </li>
   </ul>
</div>
  <div className="barn-family-button barn-family"> <p>Not a Member yet?</p>
  <a href="#signup-popup" data-effect="mfp-zoom-in" className="open-popup-link hordertype_btn" title="Join Now">Join Now</a></div>
  </div>

   <div className="membership-infographic-products-main earn-points-section">
   <h3>Ways To Earn Points</h3>
   <ul className="products-list-ulmain">
      <li className="products-single-li">
            <div className="products-image-div">
             <img src={Repate} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title">
                     <p><strong>Receive 5% Rebate</strong> on all transactions and redeem $1 for every 1 point earned.</p>
                  </div>
               </div>
            </div>
      </li>
      <li className="products-single-li">
           <div className="products-image-div">
             <img src={EarnPoints} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title">
                      <p><strong>Earn 2 points</strong> for each product review.</p>
                  </div>
               </div>
            </div>    
      </li>
      <li className="products-single-li">
          <div className="products-image-div">
             <img src={Refer} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title">
                     <p><strong>Refer a Friend:</strong> Share your personal promo code or link and you and your friend will receive 5 points each.</p>
                  </div>
               </div>
            </div>      
      </li>
   </ul>

      
</div>
                </section>

                <div className="member-bar">
                <ul>
                <li><img src={seperatorleft} /></li>
                <li><span><img src={Seperator} /></span></li>
                <li><img src={seperatorright} /></li>
                </ul>
                </div>
  
                <section className="home-our-journey">
                  <div className="container">
                  <h2 className="home-title">
                  Know your<span> benefits</span></h2>
                  <h4>Earn more points, unlock more perks!</h4>
                    <div className="join-membership-infographic-products-main membership-infographic-products-main-benefits membership-benefits-desktop">
   <ul className="products-list-ulmain">
      <li className="products-single-li border-right">
            <div className="products-image-div">
             <img src={Barnmember} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title benefits-head">
                     <h3>Barn Member</h3>
                     <p>Free</p>
                  </div>
                   <div className="benefits-body">
                    
                  <div className="benefits-inner"> <div className="benefits-inner-img"> <img src={BenefitsCode} /></div> <p> Promo codes & Vouchers</p></div>
                  <div className="benefits-inner"> <div className="benefits-inner-img"><img src={BirthdayCake} /> </div> <p> 10% Café Birthday Voucher</p></div>
                  </div>
               </div>
            </div>
      </li>
      <li className="products-single-li border-right">
           <div className="products-image-div">
             <img src={Silvermember} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title benefits-head">
                     <h3>Silver Member</h3>
                      <p>Spend $300</p>
                  </div>
                  <div className="benefits-body">
                    
                     <div className="benefits-inner"><div className="benefits-inner-img"> <img src={BenefitsCode} /> </div><p> Promo codes & Vouchers</p></div>
                     <div className="benefits-inner"><div className="benefits-inner-img"><img src={WelcomeGift} /></div> <p> $5 Welcome Gift</p></div>
                    <div className="benefits-inner"><div className="benefits-inner-img"><img src={BirthdayCake} /></div> <p> 15% Café Birthday Voucher <br/>1.5 x Birthday Points</p></div>
                  </div>
               </div>
            </div>    
      </li>
      <li className="products-single-li ">
          <div className="products-image-div">
             <img src={Goldmember} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title benefits-head">
                     <h3>Gold Member</h3>
                     <p>Spend $800</p>
                  </div>
                  <div className="benefits-body">
                  <div className="benefits-inner"> <div className="benefits-inner-img"><img src={BenefitsCode} /></div> <p> Promo codes & Vouchers</p></div>
                     <div className="benefits-inner"><div className="benefits-inner-img"><img src={WelcomeGift} /></div> <p> $5 Welcome Gift</p></div>
                    <div className="benefits-inner"><div className="benefits-inner-img"><img src={BirthdayCake} /></div> <p> 20% Café Birthday Voucher <br/>2 x Birthday Points</p></div>
                  </div>
               </div>
            </div>      
      </li>
   </ul>
</div>
          <section className="mobile-benefits">
            <div id="myCarousel1" className="carousel slide" data-interval="false" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#myCarousel1" data-slide-to="0" className="active"></li>
                <li data-target="#myCarousel1" data-slide-to="1"></li>
                <li data-target="#myCarousel1" data-slide-to="2"></li>
              </ol>

              <div className="carousel-inner">
                <div className="item active">
                  <div className="products-single-li">
                    <div className="products-image-div">
                      <img src={Barnmember} />
                    </div>
                    <div className="product-info-div">
                      <div className="product-title-maindiv">
                        <div className="product-title benefits-head">
                          <h3>Barn Member</h3>
                          <p>Free</p>
                        </div>
                        <div className="benefits-body">
                          <div className="benefits-inner"> <img src={BenefitsCode} /> <p> Promo codes & Vouchers</p></div>
                          <div className="benefits-inner"><img src={WelcomeGift} /> <p> 10% Café Birthday Voucher</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="products-single-li">
                    <div className="products-image-div">
                      <img src={Silvermember} />
                    </div>
                    <div className="product-info-div">
                       <div className="product-title-maindiv">
                          <div className="product-title benefits-head">
                            <h3>Silver Member</h3>
                            <p>Spend $300</p>
                          </div>
                          <div className="benefits-body">
                            <div className="benefits-inner"> <img src={BenefitsCode} /><p> Promo codes & Vouchers</p></div>
                            <div className="benefits-inner"><img src={WelcomeGift} /> <p> $5 Welcome Gift</p></div>
                            <div className="benefits-inner"><img src={BirthdayCake} /><p> 15% Café Birthday Voucher <br/>1.5 x Birthday Points</p></div>
                          </div>
                       </div>
                    </div> 
                  </div>
                </div>
               
                <div className="item">
                  <div className="products-single-li">
                    <div className="products-image-div">
                      <img src={Goldmember} />
                    </div>
                    <div className="product-info-div">
                      <div className="product-title-maindiv">
                        <div className="product-title benefits-head">
                          <h3>Gold Member</h3>
                          <p>Spend $800</p>
                        </div>
                        <div className="benefits-body">
                          <div className="benefits-inner"> <img src={BenefitsCode} /><p> Promo codes & Vouchers</p></div>
                          <div className="benefits-inner"><img src={WelcomeGift} /> <p> $5 Welcome Gift</p></div>
                          <div className="benefits-inner"><img src={BirthdayCake} /><p> 20% Café Birthday Voucher <br/>2 x Birthday Points</p></div>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>

                <div className="carousel-arrow-parent">
                  <a className="left carousel-control" href="#myCarousel1" data-slide="prev">
                    <span className="glyphicon glyphicon-chevron-left"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="right carousel-control" href="#myCarousel1" data-slide="next">
                    <span className="glyphicon glyphicon-chevron-right"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
         </section>

<div className="join-membership-infographic-products-main membership-infographic-products-main-benefits membership-benefits-mobile destop-benefits">
   <ul className="products-list-ulmain">
      <li className="products-single-li">
            <div className="products-image-div">
             <img src={Barnmember} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title benefits-head">
                     <h3>Barn Member</h3>
                     <p>Free</p>
                  </div>
                   <div className="benefits-body">
                    
                  <div className="benefits-inner"> <img src={BenefitsCode} /> <p> Promo codes & Vouchers</p></div>
                  <div className="benefits-inner"><img src={WelcomeGift} /> <p> 10% Café Birthday Voucher</p></div>
                  </div>
               </div>
            </div>
      </li>
      <li className="products-single-li">
           <div className="products-image-div">
             <img src={Silvermember} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title benefits-head">
                     <h3>Silver Member</h3>
                      <p>Spend $300</p>
                  </div>
                  <div className="benefits-body">
                    
                     <div className="benefits-inner"> <img src={BenefitsCode} /><p> Promo codes & Vouchers</p></div>
                     <div className="benefits-inner"><img src={WelcomeGift} /> <p> $5 Welcome Gift</p></div>
                    <div className="benefits-inner"><img src={BirthdayCake} /><p> 15% Café Birthday Voucher <br/>1.5 x Birthday Points</p></div>
                  </div>
               </div>
            </div>    
      </li>
      <li className="products-single-li ">
          <div className="products-image-div">
             <img src={Goldmember} />
             </div>
            <div className="product-info-div">
               <div className="product-title-maindiv">
                  <div className="product-title benefits-head">
                     <h3>Gold Member</h3>
                     <p>Spend $800</p>
                  </div>
                  <div className="benefits-body">
                  <div className="benefits-inner"> <img src={BenefitsCode} /><p> Promo codes & Vouchers</p></div>
                     <div className="benefits-inner"><img src={WelcomeGift} /> <p> $5 Welcome Gift</p></div>
                    <div className="benefits-inner"><img src={BirthdayCake} /><p> 20% Café Birthday Voucher <br/>2 x Birthday Points</p></div>
                  </div>
               </div>
            </div>      
      </li>
   </ul>
</div>
                  </div>
                </section>
            <div className="membership-tc">
            <p>Terms and conditions apply.</p>
            </div>
            </div>

          </div>
        </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return { staticblack: blacksArr };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

Aboutus.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Aboutus)
);
