/* eslint-disable */
import React, { Component } from 'react';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appId, apiUrl, cateringId, apiUrlV2, baseUrl, timThumpUrl } from "../../Helpers/Config"
import axios from 'axios';
import { getReferenceID, showLoader, hideLoader, stripslashes, callImage, showAlert, smoothScroll } from "../../Helpers/SettingHelper";
import { GET_CATEGORY_LIST, GET_CATERING_PRODCUTLIST } from '../../../actions';

import noimage from "../../../common/images/no-img-product.png";

var Parser = require('html-react-parser');

class Productlist extends Component {

    constructor(props) {
        super(props);

        this.state = {
			categorylist: [],
			productlist: [],
			pro_image_source: "",
			product_id: ""
        };
		
		this.props.getCategorylist();
    }
	
	componentWillReceiveProps(nxtProps) {
		
		if(this.props.categorylist !== nxtProps.categorylist) {
			var categorylist = nxtProps.categorylist;
			this.setState({categorylist: categorylist }, () => {
				if(Object.keys(categorylist).length > 0) {
					this.props.sateValChangefun('category_id', categorylist[0].pro_cate_id);
					this.props.sateValChangefun('category_name', categorylist[0].pro_cate_name);
					if(Object.keys(categorylist[0].subcategory).length > 0) {
						this.props.sateValChangefun('subcategory_id', categorylist[0].subcategory[0].pro_subcate_id);
						const parameter = "&subcategory_id="+categorylist[0].subcategory[0].pro_subcate_id;
						this.props.getProduct(parameter);
					}
				}
			});
		}

		if(this.props.productlist!==nxtProps.productlist) {
			if(Object.keys(nxtProps.productlist).length > 0) {
				this.setState({ productlist: nxtProps.productlist.result_set, pro_image_source: nxtProps.productlist.common.image_source })
			}
		}
		
	}

    componentDidMount() {
		showLoader('categorylist_norecord','class');
	}

	selectProduct(productinfo) {
		if(Object.keys(productinfo).length > 0) {
			cookie.save('catering_product_id', productinfo.product_id);
			this.props.sateValChangefun('catering_product_id', productinfo.product_id);
			smoothScroll(100,50);
			this.setState({product_id: productinfo.product_id}, () => {
				this.props.setActiveTab(2);
			})
		}
	}
	
	// getCategoryListHtml() {
	// 	var categoryPryId = this.state.catePrimaryId;
	// 	var catgPryId = cookie.load('catering_categoryPryId');
	// 	categoryPryId = (categoryPryId === '') ? catgPryId : categoryPryId;
	// 	if(this.state.categorylist.length > 0) {
			
	// 		const Categoryhtml = this.state.categorylist.map((category, index) =>
	// 							 <li className="pkg_gcol catbox-bottom-list" key={index}>
	// 							   <div className={(category.pro_cate_primary_id === categoryPryId)?"pkg_gitem category-active":"pkg_gitem"}>
	// 							     <div className="pkg_gimg catbox-bottom-img">
	// 									{/* {callImage(this.state.commonimgurl.category_image_url, category.pro_cate_image, 436, 330, timThumpUrl)} */}
	// 									<div className="pkg_ginfo catbox-bottom-info">
	// 										<h4>{stripslashes(category.pro_cate_name)}</h4>
	// 										<p>{(category.pro_cate_short_description !== '') ? stripslashes(category.pro_cate_short_description) : ""}</p>
	// 									</div>
	// 							     </div>
	// 								 <div className="pkg_gbtsec text-center catbox-bottom">
	// 								 {/*<a href="javascript:void(0)" onClick={this.viewMenuDetails.bind(this, category)} className="button viewmenu-but" title="Download Menu">View Menu</a>*/}
	// 									<a href="javascript:void(0)" onClick={this.selectProduct.bind(this, category)} className="button ordernw-but" title="Place Order">Place Order</a>
	// 								 </div>
	// 							   </div>
	// 							  </li>); 
	// 		return Categoryhtml;					  
								  
	// 	} else {
	// 		return (<div className="categorylist_norecord"> Catering category was not available. </div>);
	// 	}
		
	// }

	getProductListHTML() {
		if(this.state.productlist!== undefined && this.state.productlist.length > 0) {
			const Producthtml = this.state.productlist.map((product, index) =>
									<div className="products_list_content" key={index}>
									<div className="products_list_image">
										{product.product_thumbnail !== "" ? (
										<img
											src={this.state.pro_image_source + "/" + product.product_thumbnail}
											alt="catering_categories"
										/>
										) : (
										<img src={noimage} alt="catering_categories" />
										)}

										<h4>
											{product.product_alias
											? stripslashes(product.product_alias)
											: stripslashes(product.product_name)}
										</h4>
									</div>
								
									<div className="products_list_desc">
							
									<div className="plistbtn_left">
									<h5>Min Pax : {product.product_min_pax}</h5>
									
									<p>
										{product.product_short_description != ""
										? Parser(stripslashes(product.product_short_description))
										: ""}
									</p>
									</div>

									<div className="plistbtn_right">
										<h3>${ product.product_price } per pax</h3>
										<a href="javascript:void(0)" onClick={this.selectProduct.bind(this, product)} className="button ordernw-but" title="Order Now">Order Now</a>
									</div>
									{(typeof product.product_tag !== 'undefined' && product.product_tag.length > 0) &&
									<div className="flavours">
										{(product.product_tag || []).map((tag, index) => {
										return (
											<img
											src={tag_image_source + tag.pro_tag_image}
											alt={tag.pro_tag_name}
											key={index}
											/>
										);
										})}
									</div>
									}
									</div>
								</div>
							); 
			return Producthtml;	
		} else {
			return (<div className="categorylist_norecord"> Catering product was not available. </div>);
		}
	}

	saveCategoryDetails(categoryDet,eventtype) {

		if(Object.keys(categoryDet).length > 0) {
			
			this.props.sateValChangefun('cateVenueFlag', eventtype);
			this.props.sateValChangefun('catePrimaryId', categoryDet.pro_cate_primary_id);
			this.props.sateValChangefun('category_id', categoryDet.pro_cate_id);
			this.props.sateValChangefun('category_name', stripslashes(categoryDet.pro_cate_name));
			this.props.sateValChangefun('venueTypeChanged', 'Yes');
			
			cookie.save('catering_eventType', eventtype);
			cookie.save('catering_categoryPryId', categoryDet.pro_cate_primary_id);
			cookie.save('catering_categoryId', categoryDet.pro_cate_id);
			cookie.save('catering_categoryName', stripslashes(categoryDet.pro_cate_name));
			smoothScroll(100,50);
			
			this.setState({cateVenueFlag: eventtype, catePrimaryId: categoryDet.pro_cate_primary_id, category_id: categoryDet.pro_cate_id, category_name: stripslashes(categoryDet.pro_cate_name)}, function () { this.props.setActiveTab(2); }.bind(this));
			
		}
	}
	
    render() {

        return (<div>
		           <div className="tab_mobrow" style={{display:this.props.showsActiveDiv(1)}}>
						<div className="pkg_gridsec" style={{paddingTop:'10px'}}>
						
						 <div className="innerproduct" >
							<ul className="row-pgk-list">
								<div className="products_list_heading">
									<h3>select menu</h3>
								</div>
								{this.getProductListHTML()}
							</ul>
						 </div>				 
						</div>	
					</div>
				</div>);
     }
}

const mapStateTopProps = (state) => {
  var categoryRst = Array();var commonImg = Array();var productList=Array();
  if(Object.keys(state.categorylist).length > 0) {
	  if(state.categorylist[0].status === 'ok') {
		 categoryRst = state.categorylist[0].result_set;
		 commonImg = state.categorylist[0].common;
	  }
  }

  if (Object.keys(state.cateringprodcutlist).length > 0) {
    if ( state.cateringprodcutlist[0].status === "ok" && Object.keys(state.cateringprodcutlist[0].result_set).length > 0 ) {
		productList = state.cateringprodcutlist[0];
		console.log(productList, "productList")
    }
  }
    
  return {
    categorylist: categoryRst,
    catcommonimgurl: commonImg,
	productlist: productList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCategorylist: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
	getProduct: parameter => {
		dispatch({ type: GET_CATERING_PRODCUTLIST, parameter });
	},
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Productlist));
