/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import iconcategory from "../../common/images/icon-category.png";
import icondarkcategory from "../../common/images/icon-category-dark.png";

class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    var outletType = this.props.match.path;
    if (outletType === "/cafe" || outletType === "/cafe/:slugType/:slugValue") {
      outletType = "cafe";
    } else if (
      outletType === "/pantry" ||
      outletType === "/pantry/:slugType/:slugValue"
    ) {
      outletType = "pantry";
    }
    this.state = {
      navigateMenu: [],
      outletType: outletType,
    };
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    let navigateCommon = this.props.navigateCommon;

    var selectedNav = this.props.productState.selectedNavigation;
    var selectedNavigationFilter =
      this.props.productState.selectedNavigationFilter;
    $(".productpage-main-div").removeClass("pantry-work-in-progress");
    $(
      ".product-cconmid-lhs, .product-cms-content-bottom, .product-menu-listing"
    ).show();
    var selectedMainCatId = this.props.productState.selectedMainCatId;
    var totalNav = 0;
    var mainMenu = "";
    if (Object.keys(navigateMenuArr).length > 0) {
      mainMenu = navigateMenuArr.map((loaddata, index) => {
        var checkCategory = 0;
        if (this.state.outletType === "pantry") {
          if (selectedMainCatId === loaddata.pro_cate_main_category_id) {
            checkCategory = 1;
          }
        } else {
          checkCategory = 1;
        }
        if (checkCategory === 1) {
          totalNav++;
          if (loaddata.menu_type == "main") {
            return (
              <div
                key={index + 1}
                className={
                  selectedNavigationFilter !== ""
                    ? selectedNavigationFilter === loaddata.pro_cate_slug
                      ? "menu-list-intvl active"
                      : "menu-list-intvl"
                    : selectedNav === loaddata.pro_cate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={
                    "/" +
                    this.state.outletType +
                    "/category/" +
                    loaddata.pro_cate_slug
                  }
                  id={"menuNav_" + loaddata.pro_cate_slug}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                >
                  {this.props.productState.outletType == "pantry" && (
                    <div>
                      {loaddata.pro_cate_default_image !== "" &&
                      loaddata.pro_cate_default_image !== null ? (
                        <>
                          <img
                            src={
                              navigateCommon.category_image_url +
                              "/" +
                              loaddata.pro_cate_default_image
                            }
                            alt={loaddata.menu_custom_title}
                            className="menu-list-normal"
                          />
                          <img
                            src={
                              navigateCommon.category_image_url +
                              "/" +
                              loaddata.pro_cate_active_image
                            }
                            className="menu-list-hover"
                            alt={loaddata.menu_custom_title}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={iconcategory}
                            alt={loaddata.menu_custom_title}
                            className="menu-list-normal"
                          />
                          <img
                            src={icondarkcategory}
                            className="menu-list-hover"
                            alt={loaddata.menu_custom_title}
                          />
                        </>
                      )}
                    </div>
                  )}
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </Link>
              </div>
            );
          } else if (loaddata.menu_type == "sub") {
            return (
              <div
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_subcate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={"/products/subcategory/" + loaddata.pro_subcate_slug}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </Link>
              </div>
            );
          }
        }
      });
      if (totalNav === 0 && selectedMainCatId !== "") {
        $(
          ".product-cconmid-lhs, .product-cms-content-bottom, .product-menu-listing"
        ).hide();
        $(".productpage-main-div").addClass("pantry-work-in-progress");
        mainMenu = (
          <div className="pantry-comming-soon">
            Work in Progress.....
            <br />
            Coming Soon !!
          </div>
        );
      }
      setTimeout(function () {
        if ($(".owl-stage-outer .owl-stage .owl-item .owl-stage").length > 0) {
          $(".owl-stage-outer .owl-stage .owl-item .owl-stage").each(
            function () {
              $(this).parents(".owl-item").hide();
            }
          );
        }
      }, 100);
      return mainMenu;
    } else {
      return;
    }
  }
  render() {
    var settingsTestimonial = {
      items: 6,
      autoplay: false,
      margin: 10,
      responsive: {
        0: {
          items: 2,
        },
        500: {
          items: 3,
        },
        900: {
          items: 4,
        },
        1200: {
          items: 6,
        },
      },
    };
    return (
      <>
        {Object.keys(this.props.navigateMenu).length > 0 &&
          (this.state.outletType === "cafe" ? (
            <div className="category-nav-list">{this.navigateMenuList()}</div>
          ) : (
            <OwlCarousel options={settingsTestimonial}>
              {this.navigateMenuList()}
            </OwlCarousel>
          ))}
      </>
    );
  }
}

export default MenuNavigation;
