/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import validator from "validator";

const isEmpty = (value) => (value === "" ? "This field is required." : null);
const isEmail = (email) => validator.isEmail(email) ? null : "This is not a valid email.";
const isChecked = (terms) => terms ? null : "Please accept terms and conditions";

function validationConfig(props) {
  const { firstname, lastname, email, terms } = props.fields;

  return {
    fields: ["firstname", "lastname", "email", "terms"],

    validations: {
        firstname: [[isEmpty, firstname]],
        email: [[isEmail, email]],
        terms: [[isChecked, terms, 1]],
    },
  };
}

class NewsLetter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;

    let errMsgEmail, errMsgFirstName, errMsgTerms;

    if ($validation.email.error.reason !== undefined) {
        errMsgEmail = $validation.email.show && ( <span className="error">{$validation.email.error.reason}</span> );
    }
    if ($validation.firstname.error.reason !== undefined) {
        errMsgFirstName = $validation.firstname.show && ( <span className="error ferror">{$validation.firstname.error.reason}</span> );
    }
    if ( $validation.terms.error.reason !== undefined) {
        errMsgTerms = $validation.terms.show && ( <span className="error">{$validation.terms.error.reason}</span> );
    }

    return (
        <div className="container">
            <div class="home-newletter">
                { this.props.homesubscriptionblk }
                {/* <span>Sign up for our newsletter</span>
                <h4>Be the First to Get the Scoop on Our Freshest Deals, Launches &amp; Recipes!</h4> */}
                <div class="home-newletter-form">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-6">
                          <input type="text" placeholder="First Name" value={fields.firstname} {...$field("firstname", (e) => onChange("firstname", e.target.value))} class="form-half" />
                          {errMsgFirstName}
                        </div>

                        <div className="col-md-6 mrg-btm">
                          <input type="text" placeholder="Last Name" value={fields.lastname} {...$field("lastname", 
                          (e) => onChange("lastname", e.target.value))} class="form-half" />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-12">
                          <input type="text" placeholder="Email Address" value={fields.email} {...$field("email", (e) => onChange("email", e.target.value))} class="form-full" />
                          {errMsgEmail}
                        </div>
                      </div>
                    </div>
                </div>
                <div class="home-newletter-bottom">
                    <input type="checkbox" checked={fields.terms} {...$field("terms", (e) => onChange("terms", e.target.value), null, false )} />
                    <label> By signing up, i agree to receive communications about Food Barn’s services store events and matters via email and i have also acknowledge &amp; agreed to Food Barn’s <a href="/privacy-policy" target="_blank" className="home-newletter-para-link"> Privacy Policy </a> and <a href="/terms-and-conditions"  target="_blank" className="home-newletter-para-link"> Terms &amp; Conditions </a> </label>
                    {errMsgTerms}
                </div>
                <button type="submit" class="button subscribe_submit" 
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.$submit(onValid, onInvalid);
                    }}
                >Subscribe</button>
            </div>
        </div>
    );
  }
}
NewsLetter = validated(validationConfig)(NewsLetter);

export default NewsLetter;
