/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import iconcategory from "../../common/images/icon-category.png";
import icondarkcategory from "../../common/images/icon-category-dark.png";

class MenuNavigationMobile extends Component {
  constructor(props) {
    super(props);
    var outletType = this.props.match.path;
    if (outletType === "/cafe" || outletType === "/cafe/:slugType/:slugValue") {
      outletType = "cafe";
    } else if (
      outletType === "/pantry" ||
      outletType === "/pantry/:slugType/:slugValue"
    ) {
      outletType = "pantry";
    }
    this.state = {
      navigateMenu: [],
      outletType: outletType
    };
  }

  componentDidMount() {
    // let thiss = this;
    // $(document).click(".owl-next", function (e) {
    //   var link = $(".owl-item.active").find(".menu-title-link").get(0);
    //   link = $(link).data("ref");
    //   thiss.props.history.push(link);
    // });

    // $(document).click(".owl-prev", function (e) {
    //   var link = $(".owl-item.active").find(".menu-title-link").get(0);
    //   link = $(link).data("ref");
    //   thiss.props.history.push(link);
    // });
  }

  // navigateMenuList() {
  //   let navigateMenuArr = this.props.navigateMenu;
  //   let navigateCommon = this.props.navigateCommon;

  //   var selectedNav = this.props.productState.selectedNavigation;
  //   var selectedNavigationFilter =
  //     this.props.productState.selectedNavigationFilter;

  //   var selectedMainCatId = this.props.productState.selectedMainCatId;

  //   if (Object.keys(navigateMenuArr).length > 0) {
  //     const mainMenu = navigateMenuArr.map((loaddata, index) => {
  //       var checkCategory = 0;
  //       if (this.state.outletType === "pantry") {
  //         if (selectedMainCatId === loaddata.pro_cate_main_category_id) {
  //           checkCategory = 1;
  //         }
  //       } else {
  //         checkCategory = 1;
  //       }

  //       if (checkCategory === 1) {
  //         if (loaddata.menu_type == "main") {
  //           return (
  //             <div
  //               key={index + 1}
  //               className={
  //                 selectedNavigationFilter !== ""
  //                   ? selectedNavigationFilter === loaddata.pro_cate_slug
  //                     ? "menu-list-intvl active"
  //                     : "menu-list-intvl"
  //                   : selectedNav === loaddata.pro_cate_slug
  //                   ? "menu-list-intvl active"
  //                   : "menu-list-intvl"
  //               }
  //             >
  //               <Link
  //                 to={
  //                   "/" +
  //                   this.state.outletType +
  //                   "/category/" +
  //                   loaddata.pro_cate_slug
  //                 }
  //                 data-ref= {
  //                   "/" +
  //                   this.state.outletType +
  //                   "/category/" +
  //                   loaddata.pro_cate_slug
  //                 }
  //                 id={"menuNav_" + loaddata.pro_cate_slug}
  //                 className="menu-title-link"
  //                 title={loaddata.menu_custom_title}
  //               >
  //                 {this.props.productState.outletType == "pantry" && (
  //                   <div>
  //                     {loaddata.pro_cate_default_image !== "" &&
  //                     loaddata.pro_cate_default_image !== null ? (
  //                       <>
  //                         <img
  //                           src={
  //                             navigateCommon.category_image_url +
  //                             "/" +
  //                             loaddata.pro_cate_default_image
  //                           }
  //                           alt={loaddata.menu_custom_title}
  //                           className="menu-list-normal"
  //                         />
  //                         <img
  //                           src={
  //                             navigateCommon.category_image_url +
  //                             "/" +
  //                             loaddata.pro_cate_active_image
  //                           }
  //                           className="menu-list-hover"
  //                           alt={loaddata.menu_custom_title}
  //                         />
  //                       </>
  //                     ) : (
  //                       <>
  //                         <img
  //                           src={iconcategory}
  //                           alt={loaddata.menu_custom_title}
  //                           className="menu-list-normal"
  //                         />
  //                         <img
  //                           src={icondarkcategory}
  //                           className="menu-list-hover"
  //                           alt={loaddata.menu_custom_title}
  //                         />
  //                       </>
  //                     )}
  //                   </div>
  //                 )}
  //                 {stripslashes(loaddata.menu_custom_title.toLowerCase())}
  //               </Link>
  //             </div>
  //           );
  //         } else if (loaddata.menu_type == "sub") {
  //           return (
  //             <div
  //               key={index + 1}
  //               className={
  //                 selectedNav === loaddata.pro_subcate_slug
  //                   ? "menu-list-intvl active"
  //                   : "menu-list-intvl"
  //               }
  //             >
  //               <Link
  //                 to={"/products/subcategory/" + loaddata.pro_subcate_slug}
  //                 className="menu-title-link"
  //                 title={loaddata.menu_custom_title}
  //               >
  //                 {stripslashes(loaddata.menu_custom_title.toLowerCase())}
  //               </Link>
  //             </div>
  //           );
  //         }
  //       }
  //     });
  //     setTimeout(function () {
  //       if ($(".owl-stage-outer .owl-stage .owl-item .owl-stage").length > 0) {
  //         $(".owl-stage-outer .owl-stage .owl-item .owl-stage").each(
  //           function () {
  //             $(this).parents(".owl-item").hide();
  //           }
  //         );
  //       }
  //     }, 100);
  //     return mainMenu;
  //   } else {
  //     return;
  //   }
  // }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    // let navigateCommon = this.props.navigateCommon;

    // var selectedNav = this.props.productState.selectedNavigation;
    // var selectedNavigationFilter = this.props.productState.selectedNavigationFilter;

    var selectedMainCatId = this.props.productState.selectedMainCatId;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        var checkCategory = 0;
        if (this.state.outletType === "pantry") {
          if (selectedMainCatId === loaddata.pro_cate_main_category_id) {
            checkCategory = 1;
          }
        } else {
          checkCategory = 1;
        }

        if (checkCategory === 1) {
          if (loaddata.menu_type == "main") {
            return (
              <li> 
                <Link to={ "/" + this.state.outletType + "/category/" + loaddata.pro_cate_slug } data-ref= { "/" + this.state.outletType + "/category/" + loaddata.pro_cate_slug } id={"menuNav_" + loaddata.pro_cate_slug} className="menu-title-link" title={loaddata.menu_custom_title} > 
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </Link> 
              </li>
            );
          }
        }
      });
      return mainMenu;
    } else {
      return;
    }
  }

  render() {
    // var settingsTestimonial = {
    //   items: 6,
    //   autoplay: false,
    //   nav: true,
    //   dots: false,
    //   margin: 0,
    //   responsive: {
    //     0: {
    //       items: 1,
    //     },
    //     500: {
    //       items: 2,
    //     },
    //     900: {
    //       items: 4,
    //     },
    //     1200: {
    //       items: 6,
    //     },
    //   },
    // };
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    var selectedMenuTitle = "";
    if(selectedNav !== "") {
      if (Object.keys(navigateMenuArr).length > 0) {
        navigateMenuArr.map((loaddata, index) => {
          if(loaddata.pro_cate_slug === selectedNav) {
            selectedMenuTitle = stripslashes(loaddata.menu_custom_title.toLowerCase());
          }
        })
      }
    }

    return (
      <>
        <div className="owlmobile">
          <div className="container foodbarn-search">
            {/* {Object.keys(this.props.navigateMenu).length > 0 && (
              <OwlCarousel options={settingsTestimonial}>
                {this.navigateMenuList()}
              </OwlCarousel>
            )} */}

            <div className="hcategory_sec">
              <a href="/" className="hcategory_selected_text disbl_href_action"> { selectedMenuTitle } <span className="hcategory_trigger"></span> </a>
              <div className="hcategory_menu">
                  {Object.keys(this.props.navigateMenu).length > 0 && (<ul className="hcategory_menulist">
                    {this.navigateMenuList()}                  
                  </ul> )}
              </div>
            </div>
              <div className="input-sec"><input type="text" name="searchkey" placeholder="Search" onKeyUp={this.props.searchFunction} className="productsearch"/>
              <a href="/" id="clearSearch" className="search_text_clear disbl_href_action">X</a></div>
          </div>
        </div>
      </>
    );
  }
}

export default MenuNavigationMobile;
