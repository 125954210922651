/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import { Link, useHistory} from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import MenuNavigationStick from "./MenuNavigationStick";

import MenuNavigationMobile from "./MenuNavigationMobile";

import ProductList from "./ProductList";

import {
  baseUrl,
  cateringId,
  deliveryId,
  apiUrl,
  appId,
  ConfigoutletCategory,
  defaultpantryOutlet,
} from "../Helpers/Config";
import { showLoader, hideLoader, stripslashes } from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
  GET_ZONE_DETAIL,
  GET_OUTLETCATEGORIES,
  GET_MAINCATEGORIES,
} from "../../actions";

import categorybanner from "../../common/images/category-banner.jpg";
const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");


class Products extends Component {
  constructor(props) {
    super(props);
    
    var outletType = this.props.match.path;
    if (outletType === "/cafe" || outletType === "/cafe/:slugType/:slugValue") {
      outletType = "cafe";
    } else if (
      outletType === "/pantry" ||
      outletType === "/pantry/:slugType/:slugValue"
    ) {
      outletType = "pantry";
    }
    var outletCategory =
      cookie.load("outletCategory") !== "" &&
      typeof cookie.load("outletCategory") !== undefined &&
      typeof cookie.load("outletCategory") !== "undefined"
        ? cookie.load("outletCategory")
        : "";

    this.state = {
      outletCategory: outletCategory,
      navigateMenu: [],
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedNavigationFilter: "",
      selectedSlugType: "",
      searchSubCat: "",
      searchProVal: "",
      viewProductSlug: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
      productload: "Yes",
      outletType: outletType,
      cartList: [],
      cartItemsLength: 0,
      outletcategories: [],
      maincategories: [],
      selectedMainCatId: (cookie.load("selectedMainCatId")!=="" && cookie.load("selectedMainCatId")!==undefined && cookie.load("selectedMainCatId")!=="undefined") ? cookie.load("selectedMainCatId"): "",
      selectedMainCat: (cookie.load("selectedMainCat")!=="" && cookie.load("selectedMainCat")!==undefined && cookie.load("selectedMainCat")!=="undefined") ? cookie.load("selectedMainCat"): "",
      startFilter: "No",
      sort_By_PriceName: "Sort By",
      sort_By_Price: "",
      stopFilterPrice: "No",
      showShortByPrice: false,
      showShortByCategory: false,
      showShortByTag: false,
      showShortByBrandTag: false,
      searchKeyWord: (cookie.load("searchKeyWord")!=="" && cookie.load("searchKeyWord")!==undefined ) ? cookie.load("searchKeyWord"): "",
      emptysearchKey: 0,
      proTagList: [],
      brandTagList: [],
      filterTag: [],
      setFiltetTag: "No",
      filterBrandTag: [],
      setFiltetBrandTag: "No",
    };

    if (outletType === "pantry") {
      if (outletCategory === "1") {
        cookie.save("openWarningPopup", "Yes", { path: "/" });
        cookie.save("ordernowcategory", "pantry", { path: "/" });

        location.href = baseUrl;
        //this.props.history.push("/refpage/home");
        return;
      }
    } else if (outletType === "cafe") {
      if (outletCategory === "2") {
        cookie.save("openWarningPopup", "Yes", { path: "/" });
        cookie.save("ordernowcategory", "cafe", { path: "/" });
        location.href = baseUrl;

        //this.props.history.push("/refpage/home");
        return;
        //
      }
    }

    cookie.remove("selectedMainCat", { path: "/" });
    cookie.remove("selectedMainCatId", { path: "/" });

    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    if (avilablityIdTxt === cateringId) {
      location.href = baseUrl;
      // this.props.history.push("/");
      return;
    }
    if (outletCategory !== "") {
      if (outletType !== ConfigoutletCategory[outletCategory].toLowerCase()) {
        location.href = baseUrl;
        // this.props.history.push("/");
        return;
      }
    }

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      if (outletType === "pantry") {
        var outltIdTxt = defaultpantryOutlet;
      } else {
        cookie.save("orderPopuptrigger", "Yes", { path: "/" });
        if (outletType === "pantry") {
          cookie.save("outletCategory", "2", { path: "/" } )
        } else {
          cookie.save("outletCategory", "1", { path: "/" } )
        }
        location.href = baseUrl;
        //this.props.history.push("/");
        return;
      }
    } else {
      var outltIdTxt =
        typeof cookie.load("orderOutletId") === "undefined"
          ? ""
          : cookie.load("orderOutletId");
    }

    this.props.getSettings();
    var availbty = cookie.load("defaultAvilablityId");

    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
      this.state["delivery_outlet_id"] = outltIdTxt;
      this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
    }

    this.props.getOutletCategories(outletType);
    this.props.getMainCategories();
    if (this.state.outletCategory === "1") {
      this.loadTagList();
      this.loadBrandTagList();
    }
  }

  componentDidMount() {

    showLoader("productlist-main-div", "class");
    $(".disbl_href_action").click(function (e) {
      e.preventDefault();
    });
    $(".dvLoadrCls").fadeOut(500);  

    // $('html, body').animate({scrollTop: $(".productlist-main-div").offset().top - 122 }, 2000);
    // $(window).scrollTo($(".productlist-main-div"), 100);

    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();
        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });

    let myInterval = setInterval(() => {
      if ($(".products-list-ulmain li").length > 0) {
        clearInterval(myInterval);
        hideLoader("productlist-main-div", "class");
      }
    }, 1000);
    var currentthis = this;
    $(document).click(function (e) {
      if (!$(e.target).is(".sort_by_price, .sort_by_price * ")) {
        currentthis.setState({ showShortByPrice: false });
      }
      if (!$(e.target).is(".filter_by_tag, .filter_by_tag * ")) {
        currentthis.setState({ showShortByTag: false });
      }
      if (!$(e.target).is(".filter_by_brand_tag, .filter_by_brand_tag * ")) {
        currentthis.setState({ showShortByBrandTag: false });
      }
    });

    $(".product_search_result").hide();
    
  }

  componentDidUpdate() {
    // $(window).scrollTo($(".pantry-category-parent"), 0);
    // $('html, body').animate({scrollTop: $(".productlist-main-div").offset().top - 122 }, 2000);
  }

  componentWillReceiveProps(nextProps) {
    let slugType =
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";
    let slugValue =
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    let proValue =
      typeof this.props.match.params.proValue !== "undefined"
        ? this.props.match.params.proValue
        : "";
    let selectedNavigation = nextProps.selectedNavigation;
    let selectedSlugType = nextProps.selectedSlugType;
    var searchSubCat = "";
    if (!isEqual(this.props.match.params, nextProps.match.params)) {
      slugType =
        typeof nextProps.match.params.slugType !== "undefined"
          ? nextProps.match.params.slugType
          : "";
      slugValue =
        typeof nextProps.match.params.slugValue !== "undefined"
          ? nextProps.match.params.slugValue
          : "";
      proValue =
        typeof nextProps.match.params.proValue !== "undefined"
          ? nextProps.match.params.proValue
          : "";
      this.setState({
        startFilter: "Yes",
        selectedNavigationFilter: "",
        sort_By_PriceName: "Sort By",
        sort_By_Price: "",
        showShortByPrice: false,
      });
    }

    if (slugValue !== "") {
      if (slugType !== "category" && slugType !== "subcategory") {
        searchSubCat = slugValue;
        slugValue = slugType;
        slugType = "category";
        /*$('.search_result').hide();*/
        var tmpVl = "";
        $("#productsearch").val(tmpVl);
        $("#clearSearch").hide();
        $(".hsearch_sec").removeClass("open");
        $(".hsearch_trigger").removeClass("active");
        /* setTimeout(function () {
          $(window).scrollTo($("." + proValue), 100);
        }, 2000); */
      }
      selectedNavigation = slugValue;
    }

    if (slugType === "") {
      slugType = selectedSlugType;
    }

    if (
      selectedNavigation !== this.state.selectedNavigation ||
      this.state.selectedCategoryName !== nextProps.selectedCatry
    ) {

      if ($(".productlist-main-div.loader-main-cls").length === 0) {
        // showLoader("productlist-main-div", "class");
      }
      if (proValue === "") {
        setTimeout(function () {
          $(window).scrollTo($(".common-inner-banner"), 800);
        }, 3000);
      }

      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        if (navIndex > 0) {
          var categoryNameTxt =
            Object.keys(nextProps.navigateMenu).length > 0
              ? nextProps.navigateMenu[navIndex].category_name
              : nextProps.selectedCatry;
        }
      }

      this.setState({
        selectedNavigation: selectedNavigation,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        selectedCategoryName: categoryNameTxt,
        searchSubCat: searchSubCat,
        searchProVal: proValue,
      });
    }

    if (this.state.outletcategories !== nextProps.outletcategories) {
      this.setState({ outletcategories: nextProps.outletcategories });
    }
    if (this.state.maincategories !== nextProps.maincategories) {
      this.setState({ maincategories: nextProps.maincategories }, function () {
        if (nextProps.maincategories.length > 0) {
          if(cookie.load("selectedMainCatId")!=="" && cookie.load("selectedMainCatId")!==undefined && cookie.load("selectedMainCatId")!=="undefined" && cookie.load("selectedMainCat")!=="" && cookie.load("selectedMainCat")!==undefined && cookie.load("selectedMainCat")!=="undefined") {
            var defaultpantry = "";
            if (this.state.outletType === "pantry") {
              defaultpantry = defaultpantryOutlet;
            }

            this.props.getMenuNavigationList(defaultpantry);
          } else {
            cookie.save("selectedMainCat", nextProps.maincategories[0].main_category_slug, { path: "/" })
            cookie.save("selectedMainCatId", nextProps.maincategories[0].main_category_id, { path: "/" })
            this.setState(
            {
              selectedMainCat: nextProps.maincategories[0].main_category_slug,
              selectedMainCatId: nextProps.maincategories[0].main_category_id,
            }, function () {
                var defaultpantry = "";
                if (this.state.outletType === "pantry") {
                  defaultpantry = defaultpantryOutlet;
                }

                this.props.getMenuNavigationList(defaultpantry);
              }
            );
          }
        }
      });
    }
    if (this.state.outletCategory !== "") {
      var outlet_Type = nextProps.match.path;
      if (
        outlet_Type === "/cafe" ||
        outlet_Type === "/cafe/:slugType/:slugValue"
      ) {
        outlet_Type = "cafe";
      } else if (
        outlet_Type === "/pantry" ||
        outlet_Type === "/pantry/:slugType/:slugValue"
      ) {
        outlet_Type = "pantry";
      }

      if (
        outlet_Type !==
        ConfigoutletCategory[this.state.outletCategory].toLowerCase()
      ) {
        cookie.save("openWarningPopup", "Yes", { path: "/" });
        cookie.save("ordernowcategory", outlet_Type, { path: "/" });
        location.href = baseUrl;
        return false;
      }
    }
  }

  loadTagList() {
    axios
      .get(apiUrl + "products/product_tags?app_id=" + appId)
      .then((result) => {
        if (result.data.status === "ok") {
          this.setState({ proTagList: result.data.result_set });
        }
      });
  }
  loadBrandTagList() {
    axios.get(apiUrl + "products/brand_tag?app_id=" + appId).then((result) => {
      if (result.data.status === "ok") {
        this.setState({ brandTagList: result.data.result_set });
      }
    });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "productlist" && value !== "") {
      this.setState({
        searchProResult: value,
        selectedProResult: value,
      });
    }
    if (field === "setFiltetTag" && value !== "") {
      this.setState({
        setFiltetTag: value,
      });
    }
    if (field === "setFiltetBrandTag" && value !== "") {
      this.setState({
        setFiltetBrandTag: value,
      });
    }
    if (field === "cartList") {
      this.setState({ cartList: value });
    }
    if (field === "cartItemsLength") {
      this.setState({ cartItemsLength: value });
    }
    if (field === "categorySlug") {
      this.setState({ selectedNavigationFilter: value });
    }
    if (field === "startFilter") {
      this.setState({ startFilter: value });
    }
    if (field === "stopFilterPrice") {
      this.setState({ stopFilterPrice: value });
    }
    if (field === "emptysearchKey") {
      this.setState({ emptysearchKey: value });
    }

    if(field === "selectedMainCat") {
      this.setState({ selectedMainCat: value });
      cookie.save("selectedMainCat", value, { path: "/" })
    }

    if(field === "selectedMainCatId") {
      this.setState({ selectedMainCatId: value });
      cookie.save("selectedMainCatId", value, { path: "/" })
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  openProDetailPopup() {
    // showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  handleChange(section, event) {
    let filterTag = section.state.filterTag;
    if (event.target.checked === true) {
      filterTag.push(event.target.value);
    } else {
      var index = filterTag.indexOf(event.target.value);
      filterTag.splice(index, 1);
    }
    section.setState({ filterTag: filterTag, setFiltetTag: "Yes" });
  }
  selectMainCategory(selectedMainCat, selectedMainCatId, event) {
    event.preventDefault();
    cookie.save("selectedMainCat", selectedMainCat, { path: "/" })
    cookie.save("selectedMainCatId", selectedMainCatId, { path: "/" })
    this.setState({
      selectedMainCat: selectedMainCat,
      selectedMainCatId: selectedMainCatId,
    }, function() {
      this.props.history.push($('.owl-stage a').attr("href"));
    });
    
  }

  applyCategoryFilter(nav_data, event) {
    event.preventDefault();
    if(nav_data) {
      this.setState({ showShortByCategory: false });
      let cat_url = "/" + this.state.outletType + "/category/" + nav_data.pro_cate_slug;
      this.props.history.push(cat_url);
    }
  }

  sortByPrice(sort, event) {
    event.preventDefault();
    var sort_By_PriceName = "";
    if (sort === "ASC") {
      sort_By_PriceName = "Price Low to High";
    } else {
      sort_By_PriceName = "Price High to Low";
    }
    this.setState({
      sort_By_Price: sort,
      sort_By_PriceName: sort_By_PriceName,
      stopFilterPrice: "Yes",
    });
  }
  applyTagFilter(tag_id, event) {
    event.preventDefault();
    var filterTag = this.state.filterTag;
    if (filterTag.indexOf(tag_id) >= 0) {
      var index = filterTag.indexOf(tag_id);
      filterTag.splice(index, 1);
    } else {
      filterTag.push(tag_id);
    }
    this.setState({ filterTag: filterTag, setFiltetTag: "Yes" });
  }
  applyBrandTagFilter(tag_id, event) {
    event.preventDefault();
    var filterBrandTag = this.state.filterBrandTag;
    if (filterBrandTag.indexOf(tag_id) >= 0) {
      var index = filterBrandTag.indexOf(tag_id);
      filterBrandTag.splice(index, 1);
    } else {
      filterBrandTag.push(tag_id);
    }
    this.setState({ filterBrandTag: filterBrandTag, setFiltetBrandTag: "Yes" });
  }

  showSortBy(type) {
    if (type === "price") {
      if (this.state.showShortByPrice === true) {
        this.setState({ showShortByPrice: false });
      } else {
        this.setState({
          showShortByPrice: true,
          showShortByCategory: false,
          showShortByTag: false,
        });
      }
    } else if (type === "category") {
      if (this.state.showShortByCategory === true) {
        this.setState({ showShortByCategory: false });
      } else {
        this.setState({
          showShortByCategory: true,
          showShortByPrice: false,
          showShortByTag: false,
        });
      }
    } else if (type === "tag") {
      if (this.state.showShortByTag === true) {
        this.setState({ showShortByTag: false });
      } else {
        this.setState({
          showShortByTag: true,
          showShortByCategory: false,
          showShortByPrice: false,
        });
      }
    } else if (type === "brandtag") {
      if (this.state.showShortByBrandTag === true) {
        this.setState({ showShortByBrandTag: false });
      } else {
        this.setState({
          showShortByBrandTag: true,
          showShortByTag: false,
          showShortByCategory: false,
          showShortByPrice: false,
        });
      }
    }
  }
  searchProKeyPress = (event) => {
    this.setState({ searchKeyWord: event.target.value });
    if (event.target.value === "") {
      this.setState({ emptysearchKey: 1 });
    }
  };

  render() {
    var categoryNav = "";
    let navigateCommon = "";
    if (this.props.navigateMenu.length > 0) {
      navigateCommon = this.props.navigateCommon;
      if (
        this.state.catNavIndex !== "" &&
        typeof this.state.catNavIndex !== undefined &&
        typeof this.state.catNavIndex !== "undefined"
      ) {
        if (
          typeof this.props.navigateMenu[this.state.catNavIndex] !==
            undefined &&
          typeof this.props.navigateMenu[this.state.catNavIndex] !== "undefined"
        ) {
          categoryNav = this.props.navigateMenu[this.state.catNavIndex];
        }
      }
    } 
    return (
      <div className="productpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
        />
        {/* Header End */}

        {this.state.outletType === "pantry" && (
          <>
            <section className="product-cms-content">
              <div className="product-cms-content-top">
                <div className="container">
                  {this.state.outletcategories.length > 0 && (
                    <>
                      <h3>
                        {this.state.outletcategories[0].outlet_category_title}
                      </h3>
                      {this.state.outletcategories[0]
                        .outlet_category_description !== "" &&
                        this.state.outletcategories[0]
                          .outlet_category_description !== null &&
                        Parser(
                          stripslashes(
                            this.state.outletcategories[0]
                              .outlet_category_description
                          )
                        )}
                      {this.state.maincategories.length > 0 && (
                        <ul>
                          {this.state.maincategories.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className={
                                  this.state.selectedMainCat ===
                                  item.main_category_slug
                                    ? "maincategory_"+item.main_category_id+" active"
                                    : "maincategory_"+item.main_category_id
                                }
                              >
                                <a
                                  href="#"
                                  className="button"
                                  onClick={this.selectMainCategory.bind(
                                    this,
                                    item.main_category_slug,
                                    item.main_category_id
                                  )}
                                >
                                  {item.main_category_title}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="product-cms-content-middle">
                <div className="container">
                  <div className="normal-menu-nav" id="normal_view">
                    <div className="product-cconmid-lhs">
                      <h3>Product Categories</h3>
                    </div>
                    <div className="product-cconmid-rhs">
                      <MenuNavigation
                        {...this.props}
                        productState={this.state}
                        sateValChange={this.sateValChange}
                      />
                    </div>
                  </div>
                  <div className="sticky-menu-nav" id="responsive_view">
                    <MenuNavigationStick
                      {...this.props}
                      productState={this.state}
                      sateValChange={this.sateValChange}
                      searchFunction={this.searchProKeyPress}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="product-cms-content-bottom">
              {categoryNav !== "" && (
                <>
                  {categoryNav.pro_cate_image !== "" &&
                  categoryNav.pro_cate_image !== null ? (
                    <img
                      src={
                        navigateCommon.category_image_url +
                        "/" +
                        categoryNav.pro_cate_image
                      }
                      alt={categoryNav.menu_custom_title}
                    />
                  ) : (
                    <img
                      src={categorybanner}
                      alt={categoryNav.menu_custom_title}
                    />
                  )}
                  <div className="container">
                    <div className="category-desc-section">
                      <h3>{categoryNav.menu_custom_title}</h3>
                      <div className="category-desc">
                        {stripslashes(categoryNav.pro_cate_description)}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </section>
          </>
        )}

        <section className="product-menu-listing" ref={ (ref) => this.myRef=ref } >
          {this.state.outletType === "cafe" && (
            <MenuNavigationMobile
              {...this.props}
              productState={this.state}
              sateValChange={this.sateValChange}
              searchFunction={this.searchProKeyPress}
            />
          )}
          <div className="container">
            {this.state.outletType === "cafe" && (
              <div className="cafe-category-lhs">
                <div className="input-sec">
                  <input
                    type="text"
                    name="searchkey"
                    className="productsearch"
                    placeholder="Search..."
                    // value={ (cookie.load("searchKeyWord")!=="" && cookie.load("searchKeyWord")!==undefined ) ? cookie.load("searchKeyWord"): "" }
                    onKeyUp={this.searchProKeyPress}
                  />
                  <a
                    href="/"
                    id="clearSearch"
                    className="search_text_clear disbl_href_action"
                  >
                    X
                  </a>
                </div>
                <MenuNavigation
                  {...this.props}
                  productState={this.state}
                  sateValChange={this.sateValChange}
                />

                {/* {this.props.navigateMenu.length > 0 && (
                  <div className="cafe-lhs-filter filter_by_brand_tag">
                    <span className="filter-label">Filter By Category</span>
                    <span
                      className="filter-act"
                      onClick={this.showSortBy.bind(this, "category")}
                    >
                      { stripslashes(this.state.selectedCategoryName) }
                    </span>
                    <ul
                      className={
                        this.state.showShortByCategory === true ? "active" : ""
                      }
                    >
                      {this.props.navigateMenu.map((loaddata, index) => {
                        return (
                          <li key={index}>
                            <a href="#" onClick={this.applyCategoryFilter.bind(this, loaddata)}>
                              { stripslashes(loaddata.menu_custom_title) }
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )} */}

                {this.state.brandTagList.length > 0 && (
                  <div className="cafe-lhs-filter filter_by_brand_tag">
                    <span className="filter-label">Filter By</span>
                    <span
                      className="filter-act"
                      onClick={this.showSortBy.bind(this, "brandtag")}
                    >
                      Most Popular
                    </span>
                    <ul
                      className={
                        this.state.showShortByBrandTag === true ? "active" : ""
                      }
                    >
                      {this.state.brandTagList.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className={
                              this.state.filterBrandTag.indexOf(
                                item.pro_brand_tag_id
                              ) >= 0
                                ? "active"
                                : ""
                            }
                          >
                            <a
                              href="#"
                              onClick={this.applyBrandTagFilter.bind(
                                this,
                                item.pro_brand_tag_id
                              )}
                            >
                              {item.pro_brand_tag_name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {this.state.proTagList.length > 0 && (
                  <div className="cafe-lhs-filter filter_by_tag">
                    <span className="filter-label">Filter By Tag</span>
                    <span
                      className="filter-act"
                      onClick={this.showSortBy.bind(this, "tag")}
                    >
                      Tag{" "}
                    </span>
                    <ul
                      className={
                        this.state.showShortByTag === true ? "active" : ""
                      }
                    >
                      {this.state.proTagList.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className={
                              this.state.filterTag.indexOf(item.pro_tag_id) >= 0
                                ? "active"
                                : ""
                            }
                          >
                            <a
                              href="#"
                              onClick={this.applyTagFilter.bind(
                                this,
                                item.pro_tag_id
                              )}
                            >
                              {item.pro_tag_name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                    {/* <div className="tag-filter-result">
                    <span>
                      Indian <a href="/">X</a>
                    </span>
                    <span>
                      Chines <a href="/">X</a>
                    </span>
                  </div> */}
                  </div>
                )}

                <div className="cafe-lhs-filter sort_by_price">
                  <span className="filter-label">Sort By</span>
                  <span
                    className="filter-act"
                    onClick={this.showSortBy.bind(this, "price")}
                  >
                    {this.state.sort_By_PriceName}
                  </span>
                  <ul
                    className={
                      this.state.showShortByPrice === true ? "active" : ""
                    }
                  >
                    <li
                      className={
                        this.state.sort_By_Price === "ASC" ? "active" : ""
                      }
                      onClick={this.sortByPrice.bind(this, "ASC")}
                    >
                      <a href="#">Price Low to High</a>
                    </li>
                    <li
                      className={
                        this.state.sort_By_Price === "DESC" ? "active" : ""
                      }
                    >
                      <a href="#" onClick={this.sortByPrice.bind(this, "DESC")}>
                        Price High to Low
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <div
              className={
                this.state.outletType === "cafe"
                  ? "cafe-category-rhs"
                  : "pantry-category-parent"
              }
            >
              <ProductList
                {...this.props}
                productState={this.state}
                sateValChange={this.sateValChange}
              />
            </div>
          </div>
        </section>
        <div id="dvLoading" className="dvLoadrCls"></div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if (Object.keys(state.productmenunav).length > 0) {
    var tempArr = !("menuNavigation" in state.productmenunav[0])
      ? Array()
      : state.productmenunav[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var outletcategoriesArr = Array();
  if (Object.keys(state.outletcategories).length > 0) {
    if (state.outletcategories[0].status === "ok") {
      outletcategoriesArr = state.outletcategories[0].result_set;
    }
  }

  var maincategoriesArr = Array();
  if (Object.keys(state.maincategories).length > 0) {
    if (state.maincategories[0].status === "ok") {
      maincategoriesArr = state.maincategories[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    zonedetails: zonedetailArr,
    outletcategories: outletcategoriesArr,
    maincategories: maincategoriesArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getMenuNavigationList: (outletID) => {
      dispatch({ type: GET_MENU_NAVIGATION, outletID });
    },
    getOutletCategories: (outlet_category_slug) => {
      dispatch({ type: GET_OUTLETCATEGORIES, outlet_category_slug });
    },
    getMainCategories: () => {
      dispatch({ type: GET_MAINCATEGORIES });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Products);
