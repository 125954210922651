/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appId, cateringId, apiUrlV2, CountryTxt } from "../../Helpers/Config"
import { getReferenceID, showLoader, showCartItemCount, showCustomAlert, stripslashes, smoothScroll } from "../../Helpers/SettingHelper";
import cookie from 'react-cookies';
import axios from 'axios';
var qs = require('qs');
var Parser = require('html-react-parser');
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { GET_AVAILABLE_TIME, GET_AVAILABLE_DATE } from "../../../actions";

import warning from "../../../common/images/warning.png";

class Eventdatetime extends Component {

    constructor(props) {
        super(props);

        this.state = {
            available_datelist: [],
            common_datalist: [],
            time_slotlist: [],
            interval_time_sat: "",
            catTimeLoading: "",
            catering_date: "",
            disable_dates: [],
            mindate: "",
            orderDate: "",
            maxdate: "",
            time_flag_key: "START",
            timeslot_arr: [],
            common_timelist: [],
            catering_time: "",
            catergTime: []
        };

        this.props.getCartdatedata({
            dateFlagkey: "START",
            outletId: (cookie.load("cateringOutletId")!==undefined)?cookie.load("cateringOutletId"):""
        });
				
    }
	
	componentWillReceiveProps(nextProps) {
		
      if ( this.props.cartdate !== nextProps.cartdate && typeof nextProps.cartdate !== "undefined" ) {
        if (nextProps.cartdate.status === "success") {
            let dateArrList = [];
            let commonArrLt = [];
            let timeslotArrLt = [];
            let interval_time = 15;
            let mindate = new Date();
            let maxdate,
                orderDate = "";
    
            dateArrList = nextProps.cartdate.result_set;
            commonArrLt = nextProps.cartdate.common_data;
            timeslotArrLt = nextProps.cartdate.timeslot_data;
            interval_time = nextProps.cartdate.interval_time;
    
            let data_max = Object.keys(dateArrList).length;
            let ind = parseInt(data_max) - parseInt(1);
            maxdate = new Date(nextProps.cartdate.result_set[ind].fulldate);
                    
            this.setState({ available_datelist: dateArrList });
            this.setState({ common_datalist: commonArrLt });
            this.setState({ time_slotlist: timeslotArrLt });
            this.setState({ interval_time_sat: interval_time });
            var catergDate = [];
            var disable_dates = [];
            if (dateArrList.length > 0) {
                dateArrList.map((date, index) => {
                if (date.isclose === "No") {
                    catergDate.push(new Date(date.fulldate));
                    return false;
                } else {
                    disable_dates.push(new Date(date.fulldate));
                    return false;
                }
                });
            }
            mindate = new Date(catergDate[0]);
            var errorHtml = "";
            if (Object.keys(dateArrList).length > 0) {
                this.setState({ date_errorHtml: "" });
            } else {
                this.setState({ date_errorHtml: errorHtml });
            }
  
            this.setState({
              catTimeLoading: false,
              catering_date: catergDate,
              disable_dates: disable_dates,
              mindate: mindate,
              orderDate: orderDate,
              maxdate: maxdate
            }, () => {
              this.getAvailableTime();
            });
        }
      }

      if (typeof nextProps.carttime != "undefined") {
        this.setState({ catTimeLoading: true });
  
        let timeArrList = [];
        let comnTmArrLt = [];
  
        if (nextProps.carttime.status === "success") {
          timeArrList = nextProps.carttime.result_set;
          comnTmArrLt = nextProps.carttime.common_data;
        }
  
        let cateringTime =
          Object.keys(timeArrList).length > 0 ? timeArrList[0].timetext : "";
        this.setState({ timeslot_arr: timeArrList });
        this.setState({ common_timelist: comnTmArrLt });
  
        if (this.state.orderDate === "") {
          this.setState({ catering_time: cateringTime });
          // this.props.sateValChangefun('catering_time', cateringTime);
        }
  
        if (Object.keys(timeArrList).length > 0) {
          var catergTime = [];
          this.setState({ time_errorHtml: "", catergTime: catergTime });
        }
      }
	}

	dateTmChange = (field, value) => {
		if(field === 'catering_date') {
			this.setState({catering_date: value});
			this.props.sateValChangefun('catering_date', value);
		}
		else if(field === 'catering_time') {
			this.setState({catering_time: value});
			this.props.sateValChangefun('catering_time', value);
		}
		else if(field === 'date_changflg') {
			this.setState({catering_date_changflg: value});
		}
		else if(field === 'breaktime_enable') {
			this.setState({breaktime_enable: value});
			this.props.sateValChangefun('breaktime_enable', value);
		}
		else if(field === 'breaktime_count') {
			this.setState({breaktime_count: value});
			this.props.sateValChangefun('breaktime_count', value);
		}
		else if(field === 'break_timeHtml') {
			this.setState({break_timeHtml: value});
			this.props.sateValChangefun('break_timeHtml', value);
			/*if(this.state.cart_outlet_id !== '') {
				this.props.sateValChangefun('break_timeHtml', this.state.break_timeHtml);
			}*/
		}
    }
	
	
	setPropActiveTab(tagvalue) {
		var tabflag = this.props.categoryState.active_tabflag;
		if(parseInt(tabflag) !== parseInt(tagvalue)) {
			this.props.setActiveTab(parseInt(tagvalue));
		} else {
			this.props.sateValChangefun('active_tabflag', 0);
		}
	}
	
    handleChange = date => {
        if (date === null) {
          date = "";
          this.setState({ date });
          $(".order_date_error").hide();
          cookie.save("order_date", "", { path: "/" } );
          this.setState({ orderDate: date }, () => {
            this.getAvailableTime();
          });
        } else {
          this.setState({ date });
          $(".order_date_error").hide();
          cookie.save("order_date", moment(date).format("MM/DD/YYYY"), { path: "/" });
          this.setState({ orderDate: date }, () => {
            this.getAvailableTime();
          });
        }
    };

    getAvailableTime() {
        var timeSlotList = this.state.time_slotlist;
        var cateringDate = this.state.orderDate
          ? this.state.orderDate
          : cookie.load(
              "order_date"
            ); /*
        var errorHtml = "";
        this.setState({'time_errorHtml': errorHtml});*/
        var breakTimeHtml = "";
        this.setState({ break_timeHtml: breakTimeHtml });
    
        /*&& this.state.selected_outletid !== ''*/
        if (
          timeSlotList &&
          Object.keys(timeSlotList).length > 0 &&
          cateringDate !== ""
        ) {
          var timeSlotArr = this.getdayBasedTimeList();
          if (Object.keys(timeSlotArr).length > 0) {
            timeSlotArr.map(
              (timeLt, index) =>
                (breakTimeHtml +=
                  "<option value='" + timeLt + "'>" + timeLt + "</option>")
            );
          }
    
          var breakTimeDropDown = Parser(breakTimeHtml);
          this.setState({ break_timeHtml: breakTimeDropDown });
          /*this.props.sateValChangefun('break_timeHtml', breakTimeDropDown);*/
    
          var time_flagkey = this.state.time_flag_key;
          var intervalTime = this.state.interval_time_sat;
    
          var nextTime = "";
          var prevTime = "";
          var commonTimeList = this.state.common_timelist
            ? this.state.common_timelist
            : "";
          if (
            commonTimeList !== "undefined" &&
            Object.keys(commonTimeList).length > 0
          ) {
            nextTime = commonTimeList.next_time;
            prevTime = commonTimeList.prev_time;
          }
          // var postObject = {
          //   time_slotdata: timeSlotArr,
          //   interval_time: intervalTime,
          //   time_flagkey: time_flagkey,
          //   next_time: nextTime,
          //   prev_time: prevTime
          // };
          this.props.getCarttimedata({
            time_slotdata: timeSlotArr,
            interval_time: intervalTime,
            time_flagkey: time_flagkey,
            next_time: nextTime,
            prev_time: prevTime
          });
        }
    }

    getdayBasedTimeList() {
        var timeSltList = this.state.time_slotlist;
        var caterngDate = cookie.load("order_date");
        var selectedDate = new Date(caterngDate);
        var dayintt = selectedDate.getDay();
        var currentslotData = [];
        if (dayintt === 0) {
          currentslotData = timeSltList.sun;
        }
        if (dayintt === 1) {
          currentslotData = timeSltList.mon;
        }
        if (dayintt === 2) {
          currentslotData = timeSltList.tue;
        }
        if (dayintt === 3) {
          currentslotData = timeSltList.wed;
        }
        if (dayintt === 4) {
          currentslotData = timeSltList.thu;
        }
        if (dayintt === 5) {
          currentslotData = timeSltList.fri;
        }
        if (dayintt === 6) {
          currentslotData = timeSltList.sat;
        }
        return currentslotData;
    }

    handleUpdateChange(e) {
        if (e.target.name === "start_time") {
          if (e.target.value === "") {
            this.setState(
              { start_validate: false },
              function() {
                this.showSuccess();
              }.bind(this)
            );
            $(".start_time_error").show();
          } else {
            cookie.save("orderTime", e.target.value, { path: "/" })
            this.setState(
              { start_validate: true, event_start: e.target.value },
              function() {
                this.showSuccess();
              }.bind(this)
            );
            $(".start_time_error").hide();
          }
        }
    
        if (e.target.name === "end_time") {
          if (e.target.value === "") {
            this.setState(
              { end_validate: false },
              function() {
                this.showSuccess();
              }.bind(this)
            );
            $(".end_time_error").show();
          } else {
            cookie.save("orderTimeEnd", e.target.value, { path: "/" })
            this.setState(
              { end_validate: true, event_end: e.target.value },
              function() {
                this.showSuccess();
              }.bind(this)
            );
            $(".end_time_error").hide();
          }
        }
        var itemid = e.target.attributes.getNamedItem("data-itemid");
        if (itemid !== "" && itemid !== undefined && itemid !== null) {
          itemid = e.target.attributes.getNamedItem("data-itemid").value;
          var rootid = e.target.attributes.getNamedItem("data-rootid").value;
          var CartItemList = itemid.split(",");
          var start_time = $("#" + rootid + " .start_time").val();
          var end_time = $("#" + rootid + " .end_time").val();
          if (start_time < end_time) {
            var currents = this;
            CartItemList.map(function(item) {
              if (item !== "") {
                currents.updateBreakTime(item, start_time, end_time);
              }
            });
          }
        }
    }

    getbreaktimelist(selcted, currentSelection) {
        var breakTimeHtml = "";
        let existSelectedTimes = [];
        if (
          this.state.cart_brktime_list !== "" &&
          typeof this.state.cart_brktime_list !== "undefined"
        ) {
          this.state.cart_brktime_list.map(function(cart, index) {
            existSelectedTimes.push(
              cart.cart_detaile.break_time_start +
                "-" +
                cart.cart_detaile.break_time_end
            );
          });
        }
    
        if (this.state.timeslot_arr) {
          breakTimeHtml += "<option value=''>Select</option>";
    
          this.state.timeslot_arr.map((option, index) => {
            if (option.isclose === "No") {
            //   breakTimeHtml +=
            //     "<option value='" +
            //     option.timetext +
            //     "' disabled>" +
            //     option.timetext +
            //     "</option>";
            // } else {
              if (selcted !== "" && selcted === option.timetext) {
                breakTimeHtml +=
                  "<option value='" +
                  option.timetext +
                  "' selected='selected'>" +
                  option.timetext +
                  "</option>";
              } else {
                let diableTime = this.timevalidataion(
                  option.timetext,
                  existSelectedTimes,
                  currentSelection
                );
                diableTime = (diableTime !== undefined) ? diableTime : "";
                breakTimeHtml +=
                  "<option value='" +
                  option.timetext +
                  "' " +
                  diableTime +
                  ">" +
                  option.timetext +
                  "</option>";
              }
            }
          });
        }
        return breakTimeHtml;
    }

    timevalidataion(timetext, existSelectedTimes, currentSelection) {
      var TodayDate = new Date();
      var currentTime = timetext.split(":");
      TodayDate.setHours(currentTime[0]);
      TodayDate.setMinutes(currentTime[1]);
      if (currentSelection !== "" && typeof currentSelection !== "undefined") {
        const index = existSelectedTimes.indexOf(currentSelection);
        if (index > -1) {
          existSelectedTimes.splice(index, 1);
        }
      }
      var disabled = "";
      existSelectedTimes.map(function(cartTime) {
        var cartTimesplit = cartTime.split("-");
        var fromTime = cartTimesplit[0].split(":");
        var toTime = cartTimesplit[1].split(":");
  
        var ExistDateFrom = new Date();
        ExistDateFrom.setHours(fromTime[0]);
        ExistDateFrom.setMinutes(fromTime[1]);
  
        var ExistDateTo = new Date();
        ExistDateTo.setHours(toTime[0]);
        ExistDateTo.setMinutes(toTime[1]);
        if (currentSelection !== "" && typeof currentSelection !== "undefined") {
        }
        if (TodayDate >= ExistDateFrom && TodayDate <= ExistDateTo) {
          disabled = 1;
        }
      });
      if (disabled === 1) {
        return "disabled";
      }
    }

    showSuccess() {
      if (this.state.start_validate && this.state.end_validate) {
        var startTime = this.state.event_start;
        var endTime = this.state.event_end;
        if (
          startTime !== "" &&
          endTime !== "" &&
          startTime !== undefined &&
          endTime !== undefined
        ) {
          if (startTime >= endTime) {
            swal("Error!", "Invalid start & end time!", "error");
            //$("#event_start_timer").prop("selectedIndex", "");
            //$("#event_end_timer").prop("selectedIndex", "");
            this.setState({ start_validate: false, end_validate: false });
          } else {
            this.setState({ event_start: startTime, event_end: endTime });
            swal(
              "Success!",
              "All Set! Please click Order Now to continue.",
              "success"
            );
          }
        }
      }
    }
	
    render() {

        return (
            <div className="venueTopDiv-block">
              <div className="venueTopDiv-inner">
                <div className="form-group datetime-wrap catering_event">
                    <h3>Event Date</h3>
                    <div className="custom_select">
                        <DatePicker
                            selected={
                                cookie.load("order_date")
                                ? new Date(cookie.load("order_date"))
                                : this.state.orderDate
                            }
                            onChange={this.handleChange}
                            className="form-control order_date"
                            placeholder="Event Date"
                            excludeDates={this.state.disable_dates}
                            minDate={this.state.mindate}
                            //  maxDate={this.state.maxdate}
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <span
                        className="error order_date_error"
                        style={{ display: "none" }}
                    >
                        Please select order date!
                    </span>
                </div>
                <div className="event_timing">
                  <h3>Event Start Time</h3>
                  <div className="custom_select">
                      <select
                      selected={
                        cookie.load("orderTime")
                        ? cookie.load("orderTime")
                        : ""
                      }
                      className="form-control"
                      id="event_start_timer"
                      name="start_time"
                      onChange={this.handleUpdateChange.bind(this)}
                      >
                      {Parser(this.getbreaktimelist(""))}
                      </select>
                      <span
                      className="error start_time_error"
                      style={{ display: "none" }}
                      >
                      This Field is required!
                      </span>
                  </div>
             
                  {/*<span
                  data-tip={this.state.delivery_blocks}
                  data-html={true}
                  data-class="default_tooltip order_tooltip"
                  className="tooltip_link"
                  >
                  <img src={warning} alt="warning" />
                  </span>*/}
            
                  <div className="custom_time">
                    <h3>Event End Time</h3>
                    <div className="custom_select">
                        <select
                          selected={
                            cookie.load("orderTimeEnd")
                            ? cookie.load("orderTimeEnd")
                            : ""
                          }
                          className="form-control" name="end_time" id="event_end_timer"
                          onChange={this.handleUpdateChange.bind(this)}
                          >
                          {Parser(this.getbreaktimelist(""))}
                        </select>
                        <span className="error end_time_error" style={{ display: "none" }}>This Field is required!</span>
                    </div>
                  
                    { /*<span
                      data-tip={this.state.delivery_blocks}
                      data-html={true}
                      data-class="default_tooltip order_tooltip"
                      className="tooltip_link"
                      >
                      <img src={warning} alt="warning" />
                    </span>*/}
                  </div>
                </div>
            </div>

            <div className="plistbtn_sec">
              <a href="javascript:void(0)" onClick={this.setPropActiveTab.bind(this, 3)} className="button ordernw-but" title="Continue">Continue</a>
            </div>
          </div>
        );
     }
}

const mapStateTopProps = (state) => {

    var cartdate, carttime = Array();

    if (Object.keys(state.availabiledate).length > 0) {
        if ( state.availabiledate[0].status === "success" && Object.keys(state.availabiledate[0].result_set).length > 0 ) {
          cartdate = state.availabiledate[0];
        }
    }

    if (Object.keys(state.availabiletime).length > 0) {
        if ( state.availabiletime[0].status === "success" && Object.keys(state.availabiletime[0].result_set).length > 0 ) {
          carttime = state.availabiletime[0];
        }
    }
  
    return {
        cartdate: cartdate,
        carttime: carttime
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCartdatedata: obj => {
        dispatch({ type: GET_AVAILABLE_DATE, avlDateFlg: obj });
    },
    getCarttimedata: obj => {
        dispatch({ type: GET_AVAILABLE_TIME, avlDateFlg: obj });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(Eventdatetime);