/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import cookie from "react-cookies";
import {
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showAlert,
} from "../Helpers/SettingHelper";
import { tagImageUrl,dineinId } from "../Helpers/Config";
import noimage from "../../common/images/no-img-product.png";
import shopping_bag from "../../common/images/shopping-bag.svg";
import { GET_PRODUCT_LIST } from "../../actions";
import Cafeproductdetail from "./Cafeproductdetail";
var settingsGallery = {
  infinite: false,
  dots: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

var settingsParedPro = {
  infinite: false,
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1191,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
var Parser = require("html-react-parser");
class ProductList extends Component {
  constructor(props) {
    super(props);
    var outletType = this.props.match.path;
    if (outletType === "/cafe" || outletType === "/cafe/:slugType/:slugValue") {
      outletType = "cafe";
    } else if (
      outletType === "/pantry" ||
      outletType === "/pantry/:slugType/:slugValue"
    ) {
      outletType = "pantry";
    }

    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      loading: true,
      productCommon: [],
      productDetails: [],
      filter_Tag: [],
      filter_Brand_Tag: "",
      cartItemsLength: 0,
      catSlug: "",
      subcatSlug: "",
      sort_By_Price: "",
      sort_By_PriceName: "Sort By",
      sort_By_category: "",
      sort_By_categoryName: "Shop By",
      outletType: outletType,
      showShortByPrice: false,
      showShortByCategory: false,
      searchKeyWord: "",
      emptysearchKey: 0,
      productDetailsHtml: "",
      viewProductSlug: "",
      startProDetails: 0,
      scrollProduct: "",
    };
  }
  componentDidMount() {
    var currentthis = this;
    $(document).click(function (e) {
      if (!$(e.target).is(".filters-lhs, .filters-lhs * ")) {
        currentthis.setState({ showShortByPrice: false });
      }
      if (!$(e.target).is(".filters-rhs, .filters-rhs * ")) {
        currentthis.setState({ showShortByCategory: false });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    if (this.state.searchKeyWord !== nextProps.productState.searchKeyWord) {
      if (nextProps.productState.searchKeyWord !== "") {
        this.setState(
          { searchKeyWord: nextProps.productState.searchKeyWord },
          function () {
            showLoader("productlist-main-div", "class");
            this.productsubcatlist();
          }
        );
      }
    }
    if (this.state.emptysearchKey !== nextProps.productState.emptysearchKey) {
      this.setState(
        {
          emptysearchKey: nextProps.productState.emptysearchKey,
          searchKeyWord: "",
        },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("emptysearchKey", 0);
          this.setState({ emptysearchKey: 0 });
        }
      );
    }

    if (nextProps.productState.stopFilterPrice === "Yes") {
      this.sortByPrice(nextProps.productState.sort_By_Price);
      this.props.sateValChange("stopFilterPrice", "No");
    }
    if (nextProps.productState.startFilter === "Yes") {
      this.setState(
        {
          showShortByPrice: false,
          showShortByCategory: false,
          sort_By_Price: "",
          sort_By_PriceName: "Sort By",
          sort_By_category: "",
          sort_By_categoryName: "Shop By",
        },
        function () {
          this.props.sateValChange("startFilter", "No");
        }
      );
    }
    if (selectedNavigation !== this.state.selectedNavigation) {
      
      if(this.state.outletType === "pantry") {
        $('html, body').animate({scrollTop: $(".productlist-main-div").offset().top - 30 }, 2000);
      }
      
      var catSlug = "";
      var subcatSlug = "";
      if (selectedNavigation !== "") {
        catSlug = selectedNavigation;
        subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        this.props.getProductList(catSlug, subcatSlug, "");
        $(".addcart_done_maindiv").hide();
        $(".smiple_product_lk").show();
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedslugType: slugType,
        catSlug: catSlug,
        subcatSlug: subcatSlug,
      });
    }
    if (nextProps.productState.setFiltetTag === "Yes") {
      this.setState(
        { filter_Tag: nextProps.productState.filterTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetTag", "No");
        }
      );
    }
    if (nextProps.productState.setFiltetBrandTag === "Yes") {
      this.setState(
        { filter_Brand_Tag: nextProps.productState.filterBrandTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetBrandTag", "No");
        }
      );
    }

    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist, loading: false },
        function () {
          this.productsubcatlist();
        }
      );
    }
    if (this.state.cartItemsLength !== nextProps.productState.cartItemsLength) {
		this.setState({ cartItemsLength: nextProps.productState.cartItemsLength },
			function () {
			  this.productsubcatlist();
			}
		);
    }
  }

  productsubcatlist() {
    var subcatlistArr = this.state.productSubcatlist;
    if (Object.keys(subcatlistArr).length > 0) {
      $("#no_product_error").hide();
      setTimeout(function () {}, 100);
      setInterval(function () {
        if ($(".productlist-main-div.loader-main-cls").length > 0) {
          hideLoader("productlist-main-div", "class");
        }
      }, 1000);
      const productDetails = subcatlistArr.map((categories, categoryIndex) => {
        if (categories.products.length > 0) {
          return (
            <div className="innerproduct" key={categoryIndex}>
              <h3 id={categories.pro_subcate_slug} className="sub_cate_title">
                {stripslashes(categories.pro_subcate_name)}
              </h3>
              {this.state.outletType === "cafe" && (
                <p className="sub_cate_description">
                  {categories.pro_subcate_description !== ""
                    ? Parser(categories.pro_subcate_description)
                    : categories.pro_cate_short_description !== ""
                    ? Parser(categories.pro_cate_short_description)
                    : ""}
                </p>
              )}
              <ul className="products-list-ulmain">
                {this.productList(
                  categories.products,
                  categories.pro_cate_slug,
                  categories.pro_subcate_slug
                )}
              </ul>
            </div>
          );
        }
      });
      console.log(productDetails, "productDetails");
      this.setState({ productDetails: productDetails }, function () {
        /* if (this.state.outletType === "cafe") { */
        var totalProducts = 0;
        if ($(".innerproduct").length > 0) {
          $(".innerproduct").map(function () {
            if ($(this).find(".products-list-ulmain li").length > 0) {
              totalProducts++;
              $(this).find(".sub_cate_title").show();
              $(this).find(".sub_cate_description").show();
            } else {
              $(this).find(".sub_cate_title").hide();
              $(this).find(".sub_cate_description").hide();
            }
          });
          console.log(totalProducts, "totalproducts");
          if (totalProducts > 0) {
            $("#no_product_error").hide();
          } else {
            $("#no_product_error").show();
          }
        }
        /* } else {
          if ($(".sub_cate_title:visible").length > 0) {
            $("#no_product_error").hide();
          } else {
            $("#no_product_error").show();
          }
        } */
        if (this.state.scrollProduct !== "") {
          if ($("#prolistproIndex-" + this.state.scrollProduct).length > 0) {
            $("html, body").animate(
              {
                scrollTop:
                  $("#prolistproIndex-" + this.state.scrollProduct).offset()
                    .top - 100,
              },
              500
            );
            this.setState({ scrollProduct: "" });
          }
        }
      });
      return productDetails;
    } else {
      if (this.state.loading === false) {
        setInterval(function () {
          if ($(".productlist-main-div.loader-main-cls").length > 0) {
            hideLoader("productlist-main-div", "class");
          }
        }, 1000);
        //$("#no_product_error").show();
      }

      return "";
    }
  }

  chkProStockCls(proSlug, Stock) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }
    return returnText;
  }

  /* Products List */
  productList(list, pro_cate_slug, pro_subcate_slug) {
    var imageSource = this.props.productCommon.product_image_source;
    var productgalleryimagesource =
      this.props.productCommon.product_gallery_image_source;
    var totalProduct = 0;
    const listProduct = list.map((productDetail, index) => {
      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      var productName =
        productDetail.product_alias !== ""
          ? stripslashes(productDetail.product_alias)
          : stripslashes(productDetail.product_name);
      let filter = this.checkFilterTag(productDetail.product_tag);
      var existCart = this.checkExistCart(productDetail.product_id);
      console.log(productName, "productName");
      let checkSeach = this.checkSeach(
        productName,
        productDetail.product_tag,
        productDetail.product_brand_tag
      );

      console.log(checkSeach, "checkSeach")

      if(this.props.productCommon.main_category_slug !== "") {
        this.props.sateValChange('selectedMainCat', this.props.productCommon.main_category_slug);
        this.props.sateValChange('selectedMainCatId', this.props.productCommon.main_category_id);
      }

      var cart_label = "Order Now";  

      if (checkSeach === 1) {
        totalProduct++;
        return (
          <li
            className={this.chkProStockCls(
              productDetail.product_slug,
              productDetail.product_stock
            )}
            id={prodivId}
            key={index}
          >
            <div id={"prolist" + prodivId} className="product_list_sec">
              {productDetail.product_tag_info !== "" &&
                productDetail.product_tag_info !== null && (
                  <div className="ribbon">
                    <span>{productDetail.product_tag_info}</span>
                  </div>
                )}

              <div className="products-image-div">
                {this.state.outletType === "cafe" ? (
                  productDetail.image_gallery.length > 0 ? (
                    productDetail.image_gallery.length === 1 ? (
                      <img
                        src={
                          productgalleryimagesource +
                          "/" +
                          productDetail.image_gallery[0].pro_gallery_image
                        }
                      />
                    ) : (
                      <Slider {...settingsGallery}>
                        {productDetail.image_gallery.map((item, imgindex) => {
                          return (
                            <div key={imgindex}>
                              <img
                                src={
                                  productgalleryimagesource +
                                  "/" +
                                  item.pro_gallery_image
                                }
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    )
                  ) : productDetail.product_thumbnail !== "" ? (
                    <img
                      src={imageSource + "/" + productDetail.product_thumbnail}
                    />
                  ) : (
                    <img src={noimage} />
                  )
                ) : productDetail.product_thumbnail !== "" ? (
                  <img
                    src={imageSource + "/" + productDetail.product_thumbnail}
                  />
                ) : (
                  <img src={noimage} />
                )}

              { (productDetail.rating_reviews.rating > 0) && (<>
                {this.state.outletType === "cafe" && ( <fieldset className="rating">
                    <input type="radio" id="star5" name="rating" value="5" /><label className={productDetail.rating_reviews.rating >= 5 ? "full custom_checked": "full" } for="star5" title="5 stars"></label>
                    <input type="radio" id="star4" name="rating" value="4" /><label className={productDetail.rating_reviews.rating >= 4 ? "full custom_checked": "full" } for="star4" title="4 stars"></label>
                    <input type="radio" id="star3" name="rating" value="3" /><label className={productDetail.rating_reviews.rating >= 3 ? "full custom_checked": "full" } for="star3" title="3 stars"></label>
                    <input type="radio" id="star2" name="rating" value="2" /><label className={productDetail.rating_reviews.rating >= 2 ? "full custom_checked": "full" } for="star2" title="2 stars"></label>
                    <input type="radio" id="star1" name="rating" value="1" /> <label className={productDetail.rating_reviews.rating >= 1 ? "full custom_checked": "full" } for="star1" title="1 star"></label>  
                </fieldset>)}
              </>)}

              { (productDetail.rating_reviews.rating > 0) && (<>
                {this.state.outletType === "pantry" && ( <fieldset className="pantry_rating">
                    <input type="radio" id="star5" name="rating" value="5" /><label className={productDetail.rating_reviews.rating >= 5 ? "full custom_checked": "full" } for="star5" title="5 stars"></label>
                    <input type="radio" id="star4" name="rating" value="4" /><label className={productDetail.rating_reviews.rating >= 4 ? "full custom_checked": "full" } for="star4" title="4 stars"></label>
                    <input type="radio" id="star3" name="rating" value="3" /><label className={productDetail.rating_reviews.rating >= 3 ? "full custom_checked": "full" } for="star3" title="3 stars"></label>
                    <input type="radio" id="star2" name="rating" value="2" /><label className={productDetail.rating_reviews.rating >= 2 ? "full custom_checked": "full" } for="star2" title="2 stars"></label>
                    <input type="radio" id="star1" name="rating" value="1" /> <label className={productDetail.rating_reviews.rating >= 1 ? "full custom_checked": "full" } for="star1" title="1 star"></label>  
                </fieldset>)}
              </>)}

              </div>

              <div className="product-info-div">
                <div className="product-title-maindiv">
                  <div className="product-title">
                    <h3>
                      {productDetail.product_alias !== ""
                        ? stripslashes(productDetail.product_alias)
                        : stripslashes(productDetail.product_name)}
                    </h3>
                    {this.state.outletType === "cafe" &&
                      productDetail.product_short_description !== "" &&
                      productDetail.product_short_description !== null && (
                        <div className="cafe-product-description">
                          {Parser(productDetail.product_short_description)}
                        </div>
                      )}
                  </div>
                </div>
                    
                {this.state.outletType === "cafe" &&
                  productDetail.product_tag.length > 0 && (
                    <div className="product-tags-list">
                      <ul>{this.showProductTags(productDetail.product_tag)}</ul>
                    </div>
                  )}
                <div className="products-ordernow-action">
                  <div className="product-price">
                    {productDetail.product_cost > 0 && (
                      <h4 className="original-price">
                        {showPriceValue(productDetail.product_cost)}
                      </h4>
                    )}
                    <h3>{showPriceValue(productDetail.product_price)}</h3>
                  </div>
                  {productDetail.product_stock > 0 ||
                  productDetail.product_stock === null ? (
                    productDetail.product_type === "1" ? (
                      <a
                        className={
                          existCart === true
                            ? "button order_nowdiv smiple_product_lk disbl_href_action exist-cart"
                            : "button order_nowdiv smiple_product_lk disbl_href_action"
                        }
                        href={
                          "/pantry/" +
                          pro_cate_slug +
                          "/" +
                          pro_subcate_slug +
                          "/" +
                          productDetail.product_slug
                        }
                        onClick={(e) => {
                          if (this.state.outletType === "cafe") {
                            event.preventDefault();
                            this.viewProDetail(
                              productDetail,
                              pro_cate_slug,
                              pro_subcate_slug
                            );
                          } else {
                            showLoader(comboProId);
                          }
                        }}
                        id={comboProId}
                      >
                        {existCart === true ? (
                          <img
                            className="aftercart"
                            src={shopping_bag}
                            alt="Order Now"
                          />
                        ) : (
                          <>{ cart_label }</>
                        )}
                      </a>
                    ) : (
                      <a
                        href={
                          "/pantry/" +
                          pro_cate_slug +
                          "/" +
                          pro_subcate_slug +
                          "/" +
                          productDetail.product_slug
                        }
                        onClick={(e) => {
                          if (this.state.outletType === "cafe") {
                            event.preventDefault();
                            this.viewProDetail(
                              productDetail,
                              pro_cate_slug,
                              pro_subcate_slug
                            );
                          } else {
                            showLoader(comboProId);
                          }
                        }}
                        /*  onClick={this.viewProDetail.bind(
                          this,
                          productDetail,
                          pro_cate_slug,
                          pro_subcate_slug
                        )} */
                        title="Product Details"
                        id={comboProId}
                        className={
                          existCart === true
                            ? "button order_nowdiv compo_product_lk exist-cart"
                            : "button order_nowdiv compo_product_lk"
                        }
                      >
                        {existCart === true ? (
                          <img
                            className="aftercart"
                            src={shopping_bag}
                            alt="Order Now"
                          />
                        ) : (
                          <>{ cart_label }</>
                        )}
                      </a>
                    )
                  ) : (
                    <a
                      className="button order_nowdiv disabled disbl_href_action"
                      href="/"
                    >
                      Sold Out
                    </a>
                  )}
                </div>
              </div>
                                  
            </div>
            {this.state.viewProductSlug === productDetail.product_slug &&
              this.state.productDetailsHtml}
          </li>
        );
      }
    });
    if (totalProduct === 0) {
      $("#" + pro_cate_slug).hide();
    } else {
      $("#" + pro_cate_slug).show();
    }
    return listProduct;
  }

  showProductTags(tags) {
    return tags.map((item, index) => (
      <li key={index}>
        {item.pro_tag_image !== "" && (
          <img src={tagImageUrl + item.pro_tag_image} />
        )}
        {stripslashes(item.pro_tag_name)}
      </li>
    ));
  }

  checkExistCart(productID) {
    if (this.props.productState.cartList.length > 0) {
      var proExist = 0;
      this.props.productState.cartList.map((item) => {
        if (item.cart_item_product_id === productID) {
          proExist++;
        }
      });
      if (proExist > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkFilterTag(productFilter) {
    var current = this;
    if (
      this.state.filter_Tag !== "" &&
      typeof this.state.filter_Tag !== undefined &&
      typeof this.state.filter_Tag !== "undefined" &&
      this.state.filter_Tag.length > 0
    ) {
      if (Object.keys(productFilter).length > 0) {
        let tagAvil = 0;
        productFilter.map(function (item) {
          if (current.state.filter_Tag.indexOf(item.tag_id) >= 0) {
            tagAvil++;
          }
        });
        if (tagAvil > 0) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 1;
    }
  }
  checkSeach(productName, tagList, brandTagList) {
    var searchKeyWord = this.state.searchKeyWord;
    var filter_Tag = this.state.filter_Tag;
    var filter_Brand_Tag = this.state.filter_Brand_Tag;
    var returnPro = 0;
    if (searchKeyWord !== "") {
      var proExistN = 0;
      if (productName.toLowerCase().indexOf(searchKeyWord.toLowerCase()) >= 0) {
        proExistN = 1;
      }
      if (proExistN == 0) {
        if (tagList !== "" && tagList !== null && tagList.length > 0) {
          tagList.map((item) => {
            if (
              item.pro_tag_name
                .toLowerCase()
                .indexOf(searchKeyWord.toLowerCase()) >= 0
            ) {
              proExistN++;
            }
          });
        }
      }
      if (proExistN == 1) {
        returnPro = 1;
      } else {
        returnPro = 0;
      }
    } else {
      returnPro = 1;
    }

    if (returnPro === 1 && filter_Tag.length > 0) {
      let tagAvil = 0;

      tagList.map(function (item) {
        if (filter_Tag.indexOf(item.tag_id) >= 0) {
          tagAvil++;
        }
      });
      if (tagAvil > 0) {
        returnPro = 1;
      } else {
        returnPro = 0;
      }
    }
    if (returnPro === 1 && filter_Brand_Tag.length > 0) {
      let tagAvil = 0;
      brandTagList.map(function (item) {
        if (filter_Brand_Tag.indexOf(item.pro_brand_tag_id) >= 0) {
          tagAvil++;
        }
      });
      if (tagAvil > 0) {
        returnPro = 1;
      } else {
        returnPro = 0;
      }
    }
    return returnPro;
  }

  viewProDetail(productDetail, pro_cate_slug, pro_subcate_slug, event) {
    if (
      event !== "" &&
      typeof event !== "undefined" &&
      typeof event !== undefined
    ) {
      event.preventDefault();
    }

    var productSlug = productDetail.product_slug;

    if (this.state.outletType === "cafe") {
      showLoader("comboPro-" + productDetail.product_slug, "Idtext");
      this.setState(
        { viewProductSlug: productSlug, proclose: true },
        function () {
          var productDetailsHtml = (
            <div>
              <Cafeproductdetail
                productState={this.state}
                sateValChange={this.sateValChange}
                productProps={this.props}
              />
            </div>
          );
          this.setState(
            { productDetailsHtml: productDetailsHtml },
            function () {
              this.productsubcatlist();
              if (this.state.startProDetails === 0) {
                this.setState({ startProDetails: 1 });
                this.viewProDetail(
                  productDetail,
                  pro_cate_slug,
                  pro_subcate_slug
                );
              } else {
                this.setState({ startProDetails: 0 });
              }
            }
          );
        }
      );
    } else {
      if (productSlug !== "") {
        this.props.history.push(
          "/" +
            this.props.productState.outletType +
            "/" +
            pro_cate_slug +
            "/" +
            pro_subcate_slug +
            "/" +
            productSlug
        );
      }
      return false;
    }
  }
  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.props.sateValChange("cartflg", value);
    }
    if (field === "closeprodetails" && value === "yes") {
      this.setState(
        { productDetailsHtml: "", viewProductSlug: "", proclose: false },
        function () {
          this.productsubcatlist();
        }
      );
    }
    if (field === "changenavslug" && value !== "") {
      var changeSlug = value.split("_");
      this.setState({ scrollProduct: changeSlug[1] });
      this.props.history.push("/cafe/category/" + changeSlug[0]);
    }
  };

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }
  sortByPrice(sort, event) {
    if (
      event !== "" &&
      typeof event !== undefined &&
      typeof event !== "undefined"
    ) {
      event.preventDefault();
    }

    var sort_By_PriceName = "";
    if (sort === "ASC") {
      sort_By_PriceName = "Price Low to High";
    } else {
      sort_By_PriceName = "Price High to Low";
    }
    this.setState({
      sort_By_Price: sort,
      sort_By_PriceName: sort_By_PriceName,
    });
    showLoader("productlist-main-div", "class");
    this.props.getProductList(
      this.state.catSlug,
      this.state.subcatSlug,
      "",
      "product_price",
      sort
    );
  }
  sortByCategories(categorySlug, categoryName, event) {
    event.preventDefault();
    if(this.state.outletType) {
      var link = "/"+this.state.outletType+"/category/"+categorySlug;
      this.props.history.push(link);
    }
    
    showLoader("productlist-main-div", "class");
    this.setState(
      {
        sort_By_category: categorySlug,
        sort_By_categoryName: categoryName,
      },
      function () {
        this.props.sateValChange("categorySlug", categorySlug);
      }
    );
    var sortKey = "";
    var sortValue = "";
    if (this.state.sort_By_Price !== "") {
      sortKey = "product_price";
      sortValue = this.state.sort_By_Price;
    }
    this.props.getProductList(categorySlug, "", "", sortKey, sortValue);
  }

  showSortBy(type) {
    if (type === "price") {
      if (this.state.showShortByPrice === true) {
        this.setState({ showShortByPrice: false });
      } else {
        this.setState({ showShortByPrice: true, showShortByCategory: false });
      }
    } else if (type === "category") {
      if (this.state.showShortByCategory === true) {
        this.setState({ showShortByCategory: false });
      } else {
        this.setState({ showShortByCategory: true, showShortByPrice: false });
      }
    }
  }
  searchProKeyPress = (event) => {
    this.setState({ searchKeyWord: event.target.value }, function () {
      this.productsubcatlist();
    });
    if (event.target.value === "") {
      this.setState({ emptysearchKey: 1 });
    }
  };

  render() {
    return (
      <div className="productlist-main-div">
        {this.state.outletType === "pantry" && (
          <>
            <div className="mobile-pantry-search">
              <div className="input-sec">
                <input
                  type="text"
                  name="searchkey"
                  className="productsearch"
                  placeholder="Search..."
                  onKeyUp={this.searchProKeyPress}
                />
                <a
                  href="/"
                  id="clearSearch"
                  className="search_text_clear disbl_href_action"
                >
                  X
                </a>
              </div>
            </div>
            <div className="product-filters">
              <div className="filters-lhs">
                <span>Sort By Price</span>
                <span
                  className="filter-act"
                  onClick={this.showSortBy.bind(this, "price")}
                >
                  {this.state.sort_By_PriceName}
                </span>
                <ul
                  className={
                    this.state.showShortByPrice === true ? "active" : ""
                  }
                >
                  <li
                    className={
                      this.state.sort_By_Price === "ASC" ? "active" : ""
                    }
                  >
                    <a href="#" onClick={this.sortByPrice.bind(this, "ASC")}>
                      Price Low to High
                    </a>
                  </li>
                  <li
                    className={
                      this.state.sort_By_Price === "DESC" ? "active" : ""
                    }
                  >
                    <a href="#" onClick={this.sortByPrice.bind(this, "DESC")}>
                      Price High to Low
                    </a>
                  </li>
                </ul>
              </div>

              <div className="filters-rhs">
                <span>Shop By Category</span>
                <span
                  className="filter-act"
                  onClick={this.showSortBy.bind(this, "category")}
                >
                  {this.state.sort_By_categoryName}
                </span>
                {this.props.navigateMenu.length > 0 && (
                  <ul
                    className={
                      this.state.showShortByCategory === true ? "active" : ""
                    }
                  >
                    {this.props.navigateMenu.map((item, index) => {
                      if (
                        this.props.productState.selectedMainCatId ===
                        item.pro_cate_main_category_id
                      ) {
                        return (
                          <li
                            key={index}
                            className={
                              this.state.sort_By_category === item.pro_cate_slug
                                ? "active"
                                : ""
                            }
                          >
                            <a
                              href="#"
                              onClick={this.sortByCategories.bind(
                                this,
                                item.pro_cate_slug,
                                item.menu_custom_title
                              )}
                            >
                              {item.menu_custom_title}
                            </a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                )}
              </div>
            </div>
          </>
        )}
        <div className="innerproduct_row">{this.state.productDetails}</div>
        <div
          className="no-product"
          id="no_product_error"
          style={{ display: "none" }}
        >
          <div className="container">
            <h2>No product found</h2>
            <p>
              Sorry, we can't find the type of products you are looking for. Try
              widening your search to see what’s available.
            </p>
          </div>
        </div>
        {/* <div className="product-load-more">
          <a href="#" className="">
            <span>LOAD</span> MORE
          </a>
        </div> */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (
      catslug,
      subcatslug,
      outletid,
      sortByField,
      sortByValue
    ) => {
      dispatch({
        type: GET_PRODUCT_LIST,
        catslug,
        subcatslug,
        outletid,
        sortByField,
        sortByValue,
      });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
