/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, setHours, getDay, format } from "date-fns";

import { cnvrtStr, stripslashes, hideLoader ,showLoader, showAlert } from "../Helpers/SettingHelper";
import { appId } from "../Helpers/Config";

import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";

import outletslider from "../../common/images/outletslider.jpg";
import outletslider1 from "../../common/images/outletslider1.jpg";
import event from "../../common/images/event.jpg";

import whatsappimg from "../../common/images/green-whatsapp.png";



import { GET_EVENTS, GET_EVENTDATA, GET_PICKUP_OUTLETS, } from "../../actions";

var dateFormat = require("dateformat");
var Parser = require('html-react-parser');

const settingsGallery = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  boolean: true,
};

const settingsevent = {
  items: 1,
  autoplay: false,
  loop: true,
  dots: true,
  responsive: {
    0: {
      items: 1,
    },
    680: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      eventDropDown: "",
      selectedEvent: "",
      eventDetails: "",
      eventImgPath: "",
      outletDropDown: "",
      fields: {
        name: "",
        mobile: "",
        subject: "",
        email: "",
        type: "",
        outlet: "",
        oultet_name: "",
        menu: "",
        menu_name: "",   
        message: "",
        date: "",
        time: "",
        terms: ""
      },
    };
  }

  async componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".dvLoadrCls").hide();
    }, 2000);
    showLoader('event-single-menu', 'class');

    await this.props.getEvents();

    await this.props.getPickupOutlets();
  }

  componentWillReceiveProps(nextProps) {

    if(this.props.events !== nextProps.events) {    

      if(nextProps.events.status === "ok") {    
        var Eventoptions = "<option value=''> Choose Event Menu</option>";    
        var eventsArr = Array();
        var events = Array();
        var first_event_id = "";
        if(Object.keys(nextProps.events.result_set).length > 0) {
          eventsArr = nextProps.events.result_set;
          
          eventsArr.map((data, index) =>  {
            Eventoptions += "<option value='"+data.wh_id+"' data-name='"+ data.wh_name +"' >"+stripslashes(data.wh_name)+"</option>";
            events[data.wh_id] = data;
            if(index === 0) {
              first_event_id = data.wh_id;
            }
          });
        }

        var eventDropDown = Parser(Eventoptions);
        this.setState({events: events, eventDropDown: eventDropDown, eventImgPath: nextProps.events.common.image_source}, () => {
          showLoader('event-single-menu', 'class');
          this.getEventDetails(first_event_id);
        });
      } else {
        hideLoader('event-single-menu', 'class');
        var Eventoptions = "<option value=''> No Events Found</option>";
        var eventDropDown = Parser(Eventoptions);
        this.setState({ eventDropDown: eventDropDown});
      }
    }

    if (this.props.pickupOulets !== nextProps.pickupOulets) {
      var outletOptions = '<option value="">Choose Outlet</option>'
      if(Object.keys(nextProps.pickupOulets).length > 0) {
        var resultArr = nextProps.pickupOulets;
        
        resultArr.map((data, index) =>  {
          if(data.outlet_name !== "Pantry") {
            outletOptions += "<option value='"+data.outlet_id+"' data-name='"+ data.outlet_name +"' >"+stripslashes(data.outlet_name)+"</option>";
          }
        });
      }

      var outletDropDown = Parser(outletOptions);
      this.setState({outletDropDown: outletDropDown});
    }

    if (nextProps.eventdata !== this.props.eventdata) {

      if(Object.keys(nextProps.eventdata).length > 0) {
        if (nextProps.eventdata.status === "ok") {
          this.handleShowAlertFun("success", nextProps.eventdata.message);
  
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          hideLoader("signup_submit", "class");
          this.handleShowAlertFun("success", nextProps.eventdata.message);
        }
      }
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  handleEventChange(e) {
    showLoader('event-single-menu', 'class');
    if(e.target.value !== "") {
      var selectedEvent = e.target.value;
      this.getEventDetails(selectedEvent);
    } else {
      this.setState({ selectedEvent: "" })
    }
  }

  getEventDetails(event_id) {
    var events = this.state.events;
    const current = this;
    this.setState({ selectedEvent: event_id }, () => {
      current.setState({ eventDetails: events[event_id] }, () => {
        hideLoader('event-single-menu', 'class');
      });
    });
  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleFormSubmit = () => {

    showLoader("signup_submit", "class");

    const formPayload = this.state.fields;

    var outlet_name = "";
    if(formPayload.type === "Instore") {
      outlet_name = formPayload.outlet_name;
    }

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      first_name: formPayload.name,
      email: formPayload.email,
      mobile: formPayload.mobile,
      event_type: formPayload.type,
      event_menu: formPayload.menu_name,
      outlet_name: outlet_name,
      eventdate: formPayload.date,
      event_time: formPayload.time,
      remarks: formPayload.message,
      terms: formPayload.terms,
    };

    this.props.submitEventData(qs.stringify(postObject));
  };

  sateValChange = (field, value) => {};

  render() {

    var eventGallery = (this.state.eventDetails && this.state.eventDetails.gallery_image)?this.state.eventDetails.gallery_image:[];

    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <Slider {...settingsGallery}>
          <div>
            <img src={outletslider} alt="" />
          </div>
          <div>
            <img src={outletslider1} alt="" />
          </div>
        </Slider>

        <div className="event-page">
          <h3>Events</h3>
          <div className="container-one cms-content">
            <div className="container cms-content event-content">
              <div className="event-container">
                <div className="event-menu-left">
                  <div className="event-top-heading">
                    <h3>Events Menu</h3>
                  </div>
                  <form className="components_selctbox_cls">
                    <select id="menus" name="menus" value={this.state.selectedEvent} onChange={this.handleEventChange.bind(this)}>
                      { this.state.eventDropDown }
                    </select>
                  </form>

                  <div className="event-single-menu"> 
                    { (this.state.eventDetails ) && (<div className="event-product-item">
                      <div className="event-products-image-div">
                        <OwlCarousel options={settingsevent}>
                         
                          { eventGallery.map((item, index) => {
                            if (item.gallery_image !== "") {
                              return (
                                <div key={index}>
                                  <img
                                    src={ this.state.eventImgPath + item.gallery_image }
                                  />
                                </div>
                              );
                            }
                          }) }

                        </OwlCarousel>
                      </div>
                      <div className="product-info-div">
                        <div className="product-title-maindiv">
                          <div className="product-title">
                        <h5>{ this.state.eventDetails.wh_name }</h5>
                          </div>
                          { this.state.eventDetails.wh_description && (<div className="product-tag-list">
                          { Parser(this.state.eventDetails.wh_description) }
                          </div>)}
                        </div>

                        <div className="products-ordernow-action">
                          <a
                            href={ this.state.eventImgPath + this.state.eventDetails.wh_menu_pdf }
                            title="Product Details"
                            id="comboPro-raffles-grain-bowl"
                            className="button order_nowdiv compo_product_lk"
                            target="_blank"
                          >
                            View Menu
                          </a>
                        </div>

                        <p className="events-contact">Please reach out to us - <a href="https://api.whatsapp.com/send/?phone=6591314606&text&app_absent=0" target="_blank">9131 4606 
                          <img src={whatsappimg} alt="WhatsApp" className="whats-app-img" /></a> </p>

                      </div>
                    </div> )}
                  </div>
                </div>

                <div className="event-menu-right ">
                  <div className="event-top-heading">
                    <h3>Enquiry Form</h3>
                  </div>

                  <Form
                    fields={this.state.fields}
                    onChange={this.fieldChange}
                    onValid={this.handleFormSubmit}
                    eventListData={this.state.eventDropDown}
                    outletListData={this.state.outletDropDown}
                    onInvalid={() => console.log("Form invalid!")}
                  />
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const phonenumberPattern = /^[0-9]{6,14}$/;

const isMobile = (mobile) => mobile.match(phonenumberPattern) ? null : "please enter valid Phone number.";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

const isEmail = (email) => validator.isEmail(email) ? null : "This is not a valid email.";

const outletCheck = (type, outlet) => type == "Instore" && outlet === "" ? "This field is required." : null;

const isChecked = (terms) => terms ? null : "Please accept terms and conditions";

function validationConfig(props) {
  const { name, mobile, email, date, type, outlet, menu, message, terms, time } = props.fields;

  return {
    fields: ["name", "mobile", "email", "date", "time", "type", "outlet", "menu", "message", "terms" ],

    validations: {
      name: [[isEmpty, name]],
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
      ],
      email: [
        [isEmpty, email],
        [isEmail, email],
      ],
      date: [[isEmpty, date]],
      time: [[isEmpty, time]],
      type: [[isEmpty, type]],
      outlet: [[outletCheck, type, outlet]],
      menu: [[isEmpty, menu]],
      message: [[isEmpty, message]],
      terms: [[isChecked, terms, 1]],
    },
  };
}

class Form extends React.Component {

  state = {
    pageTitle: "",
    pageDesc: "",
    minDate: new Date(),
    // startDate: new Date(),
    startDate: "",
    // startTime: setHours(setMinutes(new Date(), 0), 17)
    startTime: ""
  };

  async handleChangeOutlet(e) {
    await this.props.onChange("outlet", e.target.value);
    await this.props.onChange("outlet_name", $("#event_outlet").find(':selected').attr('data-name'));
  }

  async handleChangeMenu(e) {
    await this.props.onChange("menu", e.target.value);
    await this.props.onChange("menu_name", $("#event_menu").find(':selected').attr('data-name'));
  }

  handleChangeDate(datevalue) {
    this.setState( { startDate: datevalue }, function () {
      this.props.onChange("date", format(datevalue, "yyyy-MM-dd"));
    }.bind(this));
  }

  handleChangeTime(timevalue) {
    this.setState({ startTime: timevalue }, function () {
      var Hours = timevalue.getHours();
      var Minutes = timevalue.getMinutes();
      var Seconds = timevalue.getSeconds();
      var time = cnvrtStr(Hours)+":"+cnvrtStr(Minutes)+":"+cnvrtStr(Seconds);
      this.props.onChange("time", time);
    }.bind(this));
    
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props;
    let errMsgFirstName, errMsgEmail, errMsgMobile, errMsgDate, errMsgType, errMsgOutlet, errMsgMenu, errMsgMessage, errMsgTerms;

    let contactcontent = "";

    if (this.props.contactContent != undefined) {
      contactcontent = Parser(
        this.props.contactContent.result_set[0].staticblocks_description
      );
    }

    if ($validation.name.error.reason !== undefined) {
      document.getElementsByClassName("name").className = "error";
      errMsgFirstName = $validation.name.show && (
        <span className="error">{$validation.name.error.reason}</span>
      );
    }

    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }

    if ($validation.date.error.reason !== undefined) {
      errMsgDate = $validation.date.show && (
        <span className="error">Please choose Date & Time</span>
      );
    }

    if ($validation.time.error.reason !== undefined) {
      errMsgDate = $validation.time.show && (
        <span className="error">Please choose Date & Time</span>
      );
    }

    if ($validation.type.error.reason !== undefined) {
      errMsgType = $validation.type.show && (
        <span className="error">{$validation.type.error.reason}</span>
      );
    }

    if ($validation.outlet.error.reason !== undefined) {
      errMsgOutlet = $validation.outlet.show && (
        <span className="error">{$validation.outlet.error.reason}</span>
      );
    }

    if ($validation.menu.error.reason !== undefined) {
      errMsgMenu = $validation.menu.show && (
        <span className="error">{$validation.menu.error.reason}</span>
      );
    }

    if ($validation.message.error.reason !== undefined) {
      errMsgMessage = $validation.message.show && (
        <span className="error">{$validation.message.error.reason}</span>
      );
    }

    if ( $validation.terms.error.reason !== undefined) {
      errMsgTerms = $validation.terms.show && ( <span className="error event-error">{$validation.terms.error.reason}</span> );
    }

    return (
      <form className="event-form-right">
        <div className="form-group">
          <div className="focus-out">
            <label>Name</label>
            <input
              type="text"
              name="name"
              className="form-control input-focus"
              value={fields.name}
              maxLength="80"
              {...$field("name", (e) => onChange("name", e.target.value))}
            />
            {errMsgFirstName}
          </div>
        </div>

        <div className="form-group">
          <div className="focus-out">
            <label>Enter Mobile Number</label>
            <input
              type="text"
              name="mobile_number"
              className="form-control input-focus"
              value={fields.mobile}
              maxLength="11"
              {...$field("mobile", (e) =>
                onChange("mobile", e.target.value)
              )}
            />
            {errMsgMobile}
          </div>
        </div>

        <div className="form-group">
          <div className="focus-out">
            <label>Enter Email Address</label>
            <input
              type="text"
              name="email_address"
              className="form-control input-focus"
              value={fields.email}
              maxLength="85"
              {...$field("email", (e) =>
                onChange("email", e.target.value)
              )}
            />
            {errMsgEmail}
          </div>
        </div>

        <div className="form-group">
          <div className="focus-out focus-event-date">
            {/* <label>Event Date & Time</label> */}
            <DatePicker
              className="form-control ordr-datetime-cls"
              selected={this.state.startDate}
              minDate={this.state.minDate}
              dateFormat="dd/MM/yyyy"
              onChange={this.handleChangeDate.bind(this)}
              placeholderText= "Choose Date"
              // filterDate={this.isWeekday}
            />

            <DatePicker
              className="form-control ordr-datetime-cls"
              selected={this.state.startTime}
              onChange={this.handleChangeTime.bind(this)}
              showTimeSelect
              showTimeSelectOnly
              timeFormat="hh:mm a"
              dateFormat="hh:mm a"
              timeIntervals={60}
              // includeTimes={this.state.includeTimesList}
              timeCaption="Time"
              placeholderText= "Time"
            />
            
            {errMsgDate}
          </div>
        </div>
        <div className="form-group">
          <div className="select-group-box">
            <select id="menus" name="event_type" {...$field("type", (e) =>
                        onChange("type", e.target.value)
                      )}>
              {/* { this.props.eventListData } */}
              <option value=""> Choose Event Type</option>
              <option value="Instore"> Instore</option>
              <option value="Offsite"> Offsite</option>
            </select>
            {errMsgType}
          </div>
        </div>

        { ( this.props.fields.type === "Instore" ) && (<div className="form-group">
          <div className="select-group-box">
            <select id="event_outlet" name="event_outlet" onChange={this.handleChangeOutlet.bind(this)} >
              { this.props.outletListData }
            </select>
            {errMsgOutlet}
          </div>
        </div>)}


        <div className="form-group">
          <div className="select-group-box">
            <select id="event_menu" name="event_menu" onChange={this.handleChangeMenu.bind(this)}>
              { this.props.eventListData }
            </select>
            {errMsgMenu}
          </div>
        </div>

        <div className="text-box">
          <textarea
            placeholder="Special Message..."
            name="special_notes"
            id="special_notes"
            maxLength="30"
            {...$field("message", (e) =>
              onChange("message", e.target.value)
            )}
          ></textarea>
          {errMsgMessage}
        </div>

        {/* <div className="form-group form-terms">
          <div className="custom_checkbox custom_checkbox_content">
            <input type="checkbox"  checked={fields.terms} {...$field("terms", (e) => onChange("terms", e.target.value), null, false )} /> <span></span>
            <label> I agree with <strong>FoodBarn's</strong>, <a href="/terms-and-conditions"  target="_blank" className="home-newletter-para-link"> Terms &amp; Conditions </a> </label>
            {errMsgTerms}
          </div>
        </div> */}

        <div className="form-group form-terms">
          <div className="custom_checkbox custom_checkbox_content">
            <input type="checkbox"  checked={fields.terms} {...$field("terms", (e) => onChange("terms", e.target.value), null, false )} /> 
            <span></span>
             <div className="sign_reg">
              <p>
                I agree with <strong>FoodBarn's</strong>, <a className="sign_reg_uline" href="/terms-and-conditions"  target="_blank">Terms &amp; Conditions </a> 
              </p> 
            </div> 
          </div>
        </div>
        {errMsgTerms}

        <div className="form-group">
          <div className="login_pop_sub">
            <button
              type="button"
              className="btn btn_black btn_minwid signup_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {

  var events = Array();
  var eventdata = Array();
  if (Object.keys(state.events).length > 0) {
    if(state.events[1].type === "SET_EVENTS") {
      events = state.events[0];
    }

    if(state.events[1].type === "SET_EVENTDATA") {
      eventdata = state.events[0];
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  return { 
    events: events,
    eventdata: eventdata,
    pickupOulets: outletsArr
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEvents: () => {
      dispatch({ type: GET_EVENTS });
    },
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    submitEventData: (postObject) => {
      dispatch({ type: GET_EVENTDATA, postObject });
    },
  };
};

Events.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));
