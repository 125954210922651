import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
import $ from "jquery";
import swal from "sweetalert";
import moment from "moment";
import noimage from "../../../common/images/no-img-product.png";
import Slider from "react-slick";
import search from "../../../common/images/search.png";
import pdf from "../../../common/images/pdf.png";
import docx from "../../../common/images/docx.png";
import { appId, apiUrlCtrng, cateringId } from "../../Helpers/Config";
import close_light from "../../../common/images/close-light.png";
import close from "../../../common/images/close.png";
import {
  stripslashes,
  getReferenceID,
  showPriceValue
} from "../../Helpers/SettingHelper";
var Parser = require("html-react-parser");
var qs = require("qs");
const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500
};

class Productdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPopup: false,
      subcategory_id: "",
      selected_pakageIds: [],
      productsdetail: [],
      productfeilsdetail: [],
      product_addons: [],
      product_setup: [],
      product_modifiers: [],
      product_modifiers_price: 0,
      excludepricemultiplypax_flag:0,
      productGrandTotal: "",
      selectpaxcount: 1,
      event_start: "",
      event_end: "",
      temp_mod_price: 0,
      product_min_pax: 0,
      single_product_count: 1,
      single_product_selected: 0,
      selected_value: [],
      menu_comp_id: 0,
      loading: true,
      individualModPrice: 0,
      custom_logo_banner: [],
      changeArr: [],
      custom_banner_text: [],
      fileuploadStatus: [],
      settupType: "",
      product_setup_selfcollecdction: [],
      equipmentSetup: [],
      specialInstruction:'',
      productTotalPrice:0,
      newproductTotalPrice:0,
      newproductUnitPrice:0,
      
    };
    this.togglePopup = this.togglePopup.bind(this);
    this.closeToggle = this.closeToggle.bind(this);
  }

  componentWillMount() {
    /*step buttons*/
    $(document).on("click", ".step-buttons ul li a", function(e) {
      e.preventDefault();
      var aid = $(this).attr("href");

      if ($(window).width() <= 991) {
        $(".modal").animate(
          {
            scrollTop:
              $(aid).position().top -
              $(".package_details_right_inner").outerHeight() -
              5
          },
          300
        );
      } else {
        $(".modal").animate(
          {
            scrollTop: $(aid).position().top + 35
          },
          300
        );
      }
    });

    /*responsive remove accordion body*/
    $(document).on("click", ".popup_link", function() {
      if ($(window).width() <= 991) {
        $(".accordion_title").removeClass("active");
        $(".accordion_title").addClass("test");
      }
    });

    /*step buttons*/
    $(document).on("click", ".step-buttons ul li a", function(e) {
      if ($(window).width() <= 991) {
        var aid = $(this).attr("href");
        $(aid)
          .find(".accordion_body")
          .slideDown();
        $(aid)
          .find(".accordion_title")
          .addClass("active");

        /*if($(aid).find(".accordion_body").is(":visible")){
            $(aid).find(".accordion_body").slideUp();
          }else{
            $(".accordion_body").slideUp();
            $(aid).find(".accordion_body").slideDown();
          }

          if($(aid).find(".accordion_title").hasClass("active")){
            $(aid).find(".accordion_title").removeClass("active")
          }else{
            $(".accordion_title").removeClass("active");
            $(aid).find(".accordion_title").addClass("active")
          }*/
      }
    });

    /*sticky product*/
    $(document).on("click", ".popup_link", function() {
      setTimeout(() => {
        if ($(".popup_product_details").length) {
          var change = document.getElementsByClassName("modal");
          change[0].addEventListener("scroll", function() {
            let productHeight = $(".package_details_right_inner").outerHeight();
            
              let winTop = $(".modal").scrollTop();
              let csvalue = ( $(window).width() - 1610 ) / 2 + 31;
              let product;
              if ($(window).width() <= 991) {
                 product = $(".package_details_right").position().top + 5;
              }
              else{
                 product = $(".package_details_right").position().top + 120;
              }

              if (winTop > product) {
                $(".package_details_right").addClass("mobileSticky");
                $(".package_details_right").css("height", productHeight);
                $(".package_details_right_inner").css({'right': csvalue + 'px', 'left' : csvalue + 'px'});
                $(".package_details_right_inner .pdr_left p").slideUp(50);
              } else {
                $(".package_details_right").removeClass("mobileSticky");
                $(".package_details_right").css("height", "inherit");
                $(".package_details_right_inner .pdr_left p").slideDown(50);
              }
            
          });
        }
      }, 500);
    });
   /*  $(document).on("click", ".mdf-done", function(e) {
      var clas = $(this).attr("data-id");
      if ($("." + clas).hasClass("package-error")) {
      } else {

        $(this).parent().parent().find(".package_checkitem").hide();
        $(this).parent().hide();

        $(this)
          .parent()
          .parent()
          .next(".det-wrap")
          .show();
        $("." + clas).addClass("selected");
        $("." + clas)
          .parent()
          .find(".prd_close")
          .show();
      }
    }); */

    $(document).on("click", ".show_details", function(e) {
      $(".pkgerror_txt").hide();
      var parent = $(this)
        .parent()
        .parent()
        .parent()
        .parent();
      parent.find(".custom_text_logo").show();
      var modifier_apply = $(this).attr("data-modifierapply");

      if (parseInt(modifier_apply) === 0) {
        parent.find(".prd_close").show();
      }

      $(this)
        .parent()
        .parent()
        .find(".package_checkitem")
        .show();

        $(this)
        .parent()
        .parent()
        .find(".modifier-btn-grp")
        .show();

      $(this).hide();
    });

    /* $(document).on("click",".prd_close",function(e){
         
        })*/
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.categoryState.productsdetail !== nextProps.categoryState.productsdetail) {
      if (
        nextProps.categoryState.productsdetail.result_set &&
        nextProps.categoryState.productsdetail.status === "ok"
      ) {
        var selectpaxcount = 1;
        if(Object.keys(nextProps.categoryState.productsdetail.result_set).length > 0 && nextProps.categoryState.productsdetail.result_set[0].product_min_pax && nextProps.categoryState.productsdetail.result_set[0].product_min_pax !=="") {
          selectpaxcount = nextProps.categoryState.productsdetail.result_set[0].product_min_pax;
        }
        this.setState({ productsdetail: nextProps.categoryState.productsdetail, selectpaxcount: selectpaxcount });
      }
    }

    if (this.props.uploadDataImage !== nextProps.uploadDataImage) {
      if (
        nextProps.uploadDataImage &&
        nextProps.uploadDataImage.data.status === "success"
      ) {
        $(
          ".imagename-" + this.state.imageLoadId + "-" + this.state.imageCompId
        ).text(this.state.imageName);
        $(
          ".delete_image-" +
            this.state.imageLoadId +
            "-" +
            this.state.imageCompId
        ).show();
        swal("Success!", "Succesfully uploaded!", "success");
      } else {
        $(
          ".imagename-" + this.state.imageLoadId + "-" + this.state.imageCompId
        ).text("");
        $(
          ".delete_image-" +
            this.state.imageLoadId +
            "-" +
            this.state.imageCompId
        ).hide();
        swal("Error!", "Try Again!", "error");
      }

      this.setState({ fileuploadStatus: nextProps.uploadDataImage.data.file });
    }

    if (this.props.deleteDataImage !== nextProps.deleteDataImage) {
      if (
        nextProps.deleteDataImage &&
        nextProps.deleteDataImage.data.status === "success"
      ) {
        $(
          ".imagename-" + this.state.imageLoadId + "-" + this.state.imageCompId
        ).text("");
        $(
          ".delete_image-" +
            this.state.imageLoadId +
            "-" +
            this.state.imageCompId
        ).hide();
        swal("Success!", "Succesfully deleted!", "success");
      } else {
        $(
          ".imagename-" + this.state.imageLoadId + "-" + this.state.imageCompId
        ).text(nextProps.uploadDataImage.data.file.file_name);
        $(
          ".delete_image-" +
            this.state.imageLoadId +
            "-" +
            this.state.imageCompId
        ).hide();
        swal("Error!", "Try Again!", "error");
      }
    }
  }

  togglePopup(product_id, subcategory_id, index, selectpaxcount, menuComp) {


    $(".det_inner_content .package_checkitem").hide();
    var valid = true;
    var order_card = $(".order_card");
    var postal_code = $("#postal_code").val();
    
    if (postal_code === "" || postal_code.length <= 5) {
      $(".product_orders").fadeIn();
      valid = false;
      $(".postal_code_error").show();
      $("body, html").animate(
        {
          scrollTop: $("#postal_code").offset().top - 80
        },
        300
      );
      return false;
    } else {
      valid = true;
    }
    if (this.props.cartCheck === 0 && $(".dark").css("display") === "none") {
      valid = false;
      $(".add-breaktime").trigger("click");
    } else {
      valid = true;
    }
    var length = $(".order_card").length;
    for (var i = 0; i <= length; i++) {
      order_card.eq(i).attr("data-index", i);
    }
    if (cookie.load("order_date")) {
      $(".order_date_error").hide();
      valid = true;
    } else {
      $(".product_orders").fadeIn();
      $(".order_date_error").show();
      $("body, html").animate(
        {
          scrollTop: $(".order_date").offset().top - 80
        },
        300
      );
      valid = false;
      return false;
    }
    
    if (
      $(".dark")
        .find("#event_start_timer")
        .val() === "" ||
      $(".dark")
        .find("#event_start_timer")
        .val() === null
    ) {
      $(".start_time_error").show();
      valid = false;

      $("body, html").animate(
        {
          scrollTop: $(".addition-breaktime").offset().top - 80
        },
        300
      );
    } else {
      $(".start_time_error").hide();
    }
    if (
      $(".dark")
        .find("#event_end_timer")
        .val() === "" ||
      $(".dark")
        .find("#event_end_timer")
        .val() === null
    ) {
      $(".end_time_error").show();
      $(".product_orders").fadeIn();
      $("body, html").animate(
        {
          scrollTop: $(".addition-breaktime").offset().top - 80
        },
        300
      );
      valid = false;
    } else {
      $(".end_time_error").hide();
    }

    if (valid) {
      $(".dark")
        .find(".required")
        .removeClass("required");
      this.setState(prevState => ({
        modalPopup: !prevState.modalPopup,
        subcategory_id: subcategory_id,
        product_addons: [],
        settupType: ""
      }));

      const parameter = "&product_id=" + product_id + "&product_is_combo='Yes'";
      this.props.getProduct(parameter);
      var selectedPakageIds = this.state.selected_pakageIds;
      var kyIndx = "Index_" + index;
      selectedPakageIds[kyIndx] = product_id;
      this.setState({
        selected_pakageIds: selectedPakageIds,
        event_start: $(".dark")
          .find("#event_start_timer")
          .val(),
        event_end: $(".dark")
          .find("#event_end_timer")
          .val(),
        selectpaxcount: selectpaxcount,
        menu_comp_id: menuComp
      });
    }
  }

  proMdfmainData(modifiersData) {
    var mdfName = modifiersData.pro_modifier_alias_name
      ? modifiersData.pro_modifier_alias_name
      : modifiersData.pro_modifier_name;
    return modifiersData.pro_modifier_id + "~~" + stripslashes(mdfName);
  }

  calculatedOverAllAmount(indFlg) {
    var proFieldArr = [];
    var proDetailArr = [];
    var AddonsArr = [];
    var SetupArr = [];
    var proMdfPrice = 0;
    var stateProDet = this.state.productsdetail;
    var stateProMdfPrice = this.state.product_modifiers_price;

    var stateindividualModPrice =  parseFloat(this.state.individualModPrice);
    let excludepricemultiplypax_flag =  parseInt(this.state.excludepricemultiplypax_flag);

    //console.log(stateProMdfPrice,stateindividualModPrice,excludepricemultiplypax_flag,'stateProMdfPrice,stateindividualModPrice,excludepricemultiplypax_flag');

    var stateProFldDet = this.state.productfeilsdetail;
    var productAddons = this.state.product_addons;
    var productSetup = this.state.product_setup;
    var selfCollection = this.state.product_setup_selfcollecdction;
    var equipmentSetup = this.state.equipmentSetup;
    var indKey = "Index_" + indFlg;
    var imageSource = "";
    var productImage = "";

    if (Object.keys(stateProDet).length > 0) {
      //var temArr = (!(indKey in stateProDet)) ? [] : stateProDet[indKey];

      // if(Object.keys(temArr).length > 0) {
      proDetailArr = stateProDet["result_set"];
      // imageSource = (Object.keys(temArr['commonurl']).length > 0) ? temArr['commonurl'].image_source:'';
      // }
    }

    if (Object.keys(stateProFldDet).length > 0) {
      proFieldArr = !(indKey in stateProFldDet) ? [] : stateProFldDet[indKey];
    }

    if (Object.keys(productAddons).length > 0) {
      AddonsArr = !(indKey in productAddons) ? [] : productAddons[indKey];
    }

    if (Object.keys(productSetup).length > 0) {
      SetupArr = !(indKey in productSetup) ? [] : productSetup[indKey];
    }

    if (stateProMdfPrice !== undefined && stateProMdfPrice !== null) {
      proMdfPrice = parseFloat(stateProMdfPrice);
    }

   // proMdfPrice +=stateindividualModPrice;

    var minpaxcount = 0;
    var minPaxAmount = 0;
    var selectpaxcount = 0;
    var productPrice = 0;
    var productSubTotal = 0;
    var productAddonsAmount = 0;
    var productSetupAmount = 0;
    var productGrandTotal = 0;
    var productEquipmentAmount = 0;
    if (Object.keys(proDetailArr).length > 0) {
      productImage =
        proDetailArr.product_thumbnail === ""
          ? noimage
          : imageSource + "/" + proDetailArr.product_thumbnail;

      minpaxcount = proDetailArr.product_min_pax;
      minPaxAmount = proDetailArr.product_min_amount;

      selectpaxcount = !("selectpaxcount" in proFieldArr)
        ? proDetailArr.product_min_pax
        : proFieldArr["selectpaxcount"];
      
      productPrice = parseFloat(proDetailArr[0].product_price) + parseFloat(proMdfPrice);

      productSubTotal = parseFloat(this.state.selectpaxcount) * parseFloat(productPrice);

      for (var key in AddonsArr) {
        var AddonsValue = AddonsArr[key];
        for (var key2 in AddonsValue) {
          var ExpVarbl = AddonsValue[key2];
          var mytemparr = ExpVarbl.split("~~");
          if (parseInt(mytemparr[7]) > 0) {
            productAddonsAmount +=
              parseInt(mytemparr[7]) * parseFloat(mytemparr[6]);
          }
        }
      }
      for (var key in SetupArr) {
        var SetupValue = SetupArr[key];
        if (Object.keys(SetupValue).length > 0) {
          for (var key2 in SetupValue) {
            var ExpVarbl = SetupValue[key2];
            var mytemparr = ExpVarbl.split("~~");
            if (parseInt(mytemparr[4]) > 0) {
              productSetupAmount +=
                parseInt(mytemparr[4]) * parseFloat(mytemparr[3]);
            }
          }
        }
      }

      if (
        selfCollection !== "" &&
        typeof selfCollection !== undefined &&
        selfCollection.length > 0
      ) {
        selfCollection.forEach(function(collection) {
          var collectionValue = collection.split("~~");
          if (parseInt(collectionValue[4]) > 0) {
            productSetupAmount +=
              parseInt(collectionValue[4]) * parseFloat(collectionValue[3]);
          }
        });
      }

      if (
        equipmentSetup !== "" &&
        typeof equipmentSetup !== undefined &&
        equipmentSetup.length > 0
      ) {
        equipmentSetup.forEach(function(equipment) {
          var equipmentValue = equipment.split("~~");
          if (parseInt(equipmentValue[6]) > 0) {
            productEquipmentAmount +=
              parseInt(equipmentValue[6]) * parseFloat(equipmentValue[3]);
          }
        });
      }

//parseFloat(this.state.individualModPrice) +

      productGrandTotal =
        parseFloat(productSubTotal) +
        parseFloat(this.state.individualModPrice) +
        parseFloat(productAddonsAmount) +
        parseFloat(productSetupAmount) +
        parseFloat(productEquipmentAmount);
      proFieldArr["product_id"] = proDetailArr.product_id;
      proFieldArr["product_name"] = stripslashes(proDetailArr.product_name);
      proFieldArr["product_sku"] = stripslashes(proDetailArr.product_sku);
      proFieldArr["product_slug"] = proDetailArr.product_slug;
      proFieldArr["product_disable_cart"] = proDetailArr.product_disable_cart;
      proFieldArr["productImage"] = productImage;
      proFieldArr["minpaxcount"] = minpaxcount;
      proFieldArr["minPaxAmount"] = minPaxAmount;
      proFieldArr["selectpaxcount"] = selectpaxcount;
      proFieldArr["productPrice"] = productPrice;
      proFieldArr["productSubTotal"] = productSubTotal;
      proFieldArr["productAddonsAmount"] = productAddonsAmount;
      proFieldArr["productSetupAmount"] = productSetupAmount;
      proFieldArr["productEquipmentAmount"] = productEquipmentAmount;
      proFieldArr["productGrandTotal"] = productGrandTotal;
      stateProFldDet[indKey] = proFieldArr;

      this.setState({
        productfeilsdetail: stateProFldDet,
        productGrandTotal: productGrandTotal,
        productTotalPrice: productSubTotal,
        newproductTotalPrice: parseFloat(productSubTotal) + parseFloat(this.state.individualModPrice),
		newproductUnitPrice:productSubTotal,
      });
    }
  }

  checkMinValfun($_this) {
    var combo_qtycount = 0,
      min_val_error = 0;
    var min_selectcombo =
      $_this.attr("data-packageminlimit") != ""
        ? $_this.attr("data-packageminlimit")
        : "0";
    $_this.find(".combolst_qty_value").each(function() {
      var qtyval = $(this).attr("data-qtyval");
      combo_qtycount += parseInt(qtyval);
    });
    if (parseInt(min_selectcombo) > parseInt(combo_qtycount)) {
      min_val_error = 1;
    }
    return min_val_error;
  }

  setPropActiveTab(tagvalue) {
		var tabflag = this.props.categoryState.active_tabflag;
		if(parseInt(tabflag) !== parseInt(tagvalue)) {
			this.props.setActiveTab(parseInt(tagvalue));
		} else {
			this.props.sateValChangefun('active_tabflag', 0);
		}
	}

  /* add to cart */
  addToCartCombo(compoProdDetail, actionFlg, indFlg) {

    var indKey = "Index_" + indFlg;
    var errorFlg = "No";

    $(".product_acc_grp").find(".main_combo_div").each(function() {
        var checked_count = 0;
        var modDivId = $(this).attr("data-id");
        var modMin = $(this).attr("data-packageminlimit");
        var modMax = $(this).attr("data-packagemaxlimit");
        var checked_count = $("#" + modDivId).attr("data-selected");
        var modDiv = $(this).attr("id");
        
        if (parseInt(modMin) > parseInt(checked_count)) {
          $(this).prev().addClass("active");
          $(this).show();
          if (errorFlg === "No") {
            // $(".modal").animate(
            //   {
            //     scrollTop:
            //       $("#" + modDiv).position().top -
            //       $(".package_details_right_inner").outerHeight() +
            //       parseInt(200)
            //   },
            //   300
            // );
          }
          errorFlg = "Yes";
          var errorTxt = "Please select minimum " + modMin + " item(s).";
          $(this).find(".min_product_err").html(errorTxt);
          $(this).addClass("pkgerror_out");
          $(this).find(".min_product_err").show();
        }

        if (parseInt(modMax) < parseInt(checked_count)) {
          errorFlg = "Yes";
          var errorTxt =
            "Sorry!. maximum of selected item is " + modMax + " only.";
          $(this).find(".pkgerror_txt").html(errorTxt);
          $(this).addClass("pkgerror_out");
          $(this).find(".pkgerror_txt").show();
          // $(".modal").animate(
          //   {
          //     scrollTop:
          //       $("#" + modDiv).position().top -
          //       $(".package_details_right_inner").outerHeight() -
          //       5
          //   },
          //   300
          // );
        }
        if (errorFlg === "No") {
          $(this).find(".pkgerror_txt").hide();
        }
      });
    if (errorFlg === "Yes") {
      return false;
    }

    var InvalidMdfrCompo = "No";
    $(".main_combo_div").each(function() {
      if ($(this).find(".mdfr_list_divlcs").attr("data-mismatchpro") === "1") {
        InvalidMdfrCompo = "Yes";
      }
    });

    if ($("input[name=settup_details]").is(":checked")) {
      let setupName = $("input[name=settup_details]:checked").val();
      let rootsettup = "";
      let errordiv = "";
      if (setupName == "delivery") {
        rootsettup = "additionals_deliverylist";
        errordiv = "deliverySetupError";
      } else if (setupName == "buffet") {
        rootsettup = "buffet_listing_div";
        errordiv = "buffetSetupError";
      }
      $(".deliverySetupError, .buffetSetupError").hide();
      if ($("." + rootsettup + " .setup_listing_value:checked").length === 0) {
        $("." + errordiv).show();
        if (errorFlg === "No") {
          $(".modal").animate(
            {
              scrollTop: $(".additionals_setuplist").position().top - 70
            },
            300
          );
          return false;
        }
        errorFlg = "Yes";
      }
    }

    if (InvalidMdfrCompo === "No" && errorFlg === "No") {
     $(".add_to_cart").addClass("loading-btn");
      if (actionFlg === "initial") {
        $(".compo_add_div").hide();
        $(".compo_update_div").show();
        return false;
      } else {
        var $_react_this = this;
        var menuSet = [];
        var productDetailsMain = [];
        var modifier = [];
        var CompoType = $("#set_menu_component_type").val();
        CompoType = parseInt(CompoType);
        var compo_errors = "0";
        CompoType = 1;
        if (CompoType === 1) {
          $(".main_combo_div").each(function() {
            var combodata_txt = $(this)
              .find(".individual_combo_pro")
              .attr("data-combodata");
            var menu_component = combodata_txt.split("~");
            var productDetails = $_react_this.getComboproData($(this), 0);

            productDetailsMain.push({
              menu_component_id: menu_component[0],
              menu_component_name: menu_component[1],
              product_details: productDetails,
              min_max_flag: menu_component[2]
            });
          });
        } else {
          $(".main_combo_div").each(function() {
            var combodata_txt = $(this).attr("data-combodata");
            var menu_component = combodata_txt.split("~");
            var productDetails = $_react_this.getComboproData(
              $(this),
              CompoType
            );

            productDetailsMain.push({
              menu_component_id: menu_component[0],
              menu_component_name: menu_component[1],
              product_details: productDetails,
              min_max_flag: menu_component[2]
            });
          });
        }
        menuSet = productDetailsMain;
      //console.log("menuSetmenuSet", menuSet);

        var postSetup = [];
        var productSetup = this.state.product_setup;
        //console.log("selecteStMain", productSetup);
        // return false;
        if (Object.keys(productSetup).length > 0) {
          var selecteStMain = !(indKey in productSetup)
            ? Array()
            : productSetup[indKey];

          if (Object.keys(selecteStMain).length > 0) {
            var deliveryList = selecteStMain.delivery;

            if (Object.keys(deliveryList).length > 0) {
              for (var key in deliveryList) {
                var ExpVarbl = deliveryList[key];
                var mytemparr = ExpVarbl.split("~~");

                postSetup.push({
                  setupid: mytemparr[0],
                  setupname: mytemparr[1],
                  setupdescription: mytemparr[2],
                  setupprice: mytemparr[3],
                  setupqty: mytemparr[4],
                  setuptype: mytemparr[5]
                });
              }
            }
            var buffetList = selecteStMain.buffet;
            if (Object.keys(buffetList).length > 0) {
              for (var key in buffetList) {
                var ExpVarbl = buffetList[key];
                var mytemparr = ExpVarbl.split("~~");

                postSetup.push({
                  setupid: mytemparr[0],
                  setupname: mytemparr[1],
                  setupdescription: mytemparr[2],
                  setupprice: mytemparr[3],
                  setupqty: mytemparr[4],
                  setuptype: mytemparr[5]
                });
              }
            }

            var selfeCollcetionList = this.state.product_setup_selfcollecdction;
            if (selfeCollcetionList.length > 0) {
              for (var key in selfeCollcetionList) {
                var ExpVarbl = selfeCollcetionList[key];
                var mytemparr = ExpVarbl.split("~~");
                postSetup.push({
                  setupid: mytemparr[0],
                  setupname: mytemparr[1],
                  setupdescription: mytemparr[2],
                  setupprice: mytemparr[3],
                  setupqty: mytemparr[4],
                  setuptype: mytemparr[5]
                });
              }
            }
          }
        }

        let equipmentSetups = [];
        var equipmentList = this.state.equipmentSetup;
        if (equipmentList.length > 0) {
          for (var key in equipmentList) {
            var ExpVarbl = equipmentList[key];
            var mytemparr = ExpVarbl.split("~~");
            equipmentSetups.push({
              equipmentid: mytemparr[0],
              equipmentname: mytemparr[1],
              equipmentdescription: mytemparr[2],
              equipmentprice: mytemparr[3],
              equipmentquantity: mytemparr[6]
            });
          }
        }

        var postAddons = [];
        var productAddons = this.state.product_addons;
      
        if (Object.keys(productAddons).length > 0) {
          var selecteAdfMain = !(indKey in productAddons)
            ? Array()
            : productAddons[indKey];
          if (Object.keys(selecteAdfMain).length > 0) {
            for (var key in selecteAdfMain) {
              var Addons = selecteAdfMain[key];
              var AddonsValArr = [];
              var addon_setup_id = "";
              var addon_setup_title = "";
              var addon_setup_selection = "";
              for (var key1 in Addons) {
                var ExpVarbl = Addons[key1];
                //console.log("ExpVarbl", ExpVarbl);
                var mytemparr = ExpVarbl.split("~~");

                addon_setup_id = mytemparr[0];
                addon_setup_title = mytemparr[1];
                addon_setup_selection = mytemparr[2];

                if (parseInt(mytemparr[7]) > 0) {
                  AddonsValArr.push({
                    addon_setup_val_id: mytemparr[3],
                    addon_setup_val_title: mytemparr[4],
                    addon_setup_val_desc: mytemparr[5],
                    addon_setup_val_price: mytemparr[6],
                    addon_setup_val_qty: mytemparr[7]
                  });
                }
              }

              if (Object.keys(AddonsValArr).length > 0) {
                postAddons.push({
                  addon_setup_id: addon_setup_id,
                  addon_setup_title: addon_setup_title,
                  addon_setup_selection: addon_setup_selection,
                  addons_setup_values: AddonsValArr
                });
              }
            }
          }
        }
        //return false;
        /*&& Object.keys(menuSet).length > 0*/
        if (compo_errors === "0") {
          var prCommon = this.props.categoryState.productsdetail.common;
          var productId = compoProdDetail[0].product_id;
          var productName =
            compoProdDetail[0].product_alias !== ""
              ? stripslashes(compoProdDetail[0].product_alias)
              : stripslashes(compoProdDetail[0].product_name);
          var productImage =
            compoProdDetail[0].product_thumbnail !== ""
              ? prCommon.image_source +
                "/" +
                compoProdDetail[0].product_thumbnail
              : "";
          var productSku = compoProdDetail[0].product_sku;
          var productSlug = compoProdDetail[0].product_slug;

          // var productRemarks  = this.state.product_remarks;
          var productRemarks = "";

          /*var incrCompoPrice = $("#incr_compo_price").val();*/

          var incrCompoPrice = this.state.newproductTotalPrice;

        //  console.log(incrCompoPrice,this.state.productTotalPrice,this.state.newproductTotalPrice,'incrCompoPrice,this.state.productTotalPrice,newproductTotalPrice');
          /* parseInt(this.state.productTotalPrice) > 0
                                ? parseInt(this.state.productTotalPrice)
                                : parseInt(productDetail.product_price)
          */

          var prodcutQty = this.state.selectpaxcount;
          var totalCompoPrice = incrCompoPrice !== "" ? parseFloat(incrCompoPrice) : 0;
        //   console.log(totalCompoPrice, "totalCompoPrice")
         var unitProductPrice = parseFloat(parseFloat(totalCompoPrice) / parseFloat(prodcutQty));
          unitProductPrice = unitProductPrice.toFixed(2);
         

          // var unitProductPrice = this.state.newproductUnitPrice;
          
          var customerId =
            typeof cookie.load("UserId") === "undefined"
              ? ""
              : cookie.load("UserId");
          var breaktime_indexflag = $(".dark").attr("data-index");
          var product_min_qty = $(".product-min-pax").attr(
            "data-productminpax"
          );
          if (
            typeof cookie.load("cateringOutletId") !== "undefined" ||
            cookie.load("cateringOutletId") !== ""
          ) {
            var outlet_id = cookie.load("cateringOutletId");
          }
          if (
            typeof cookie.load("order_date") !== "undefined" ||
            cookie.load("order_date") !== ""
          ) {
            var catering_order_date = moment(cookie.load("order_date")).format(
              "YYYY/MM/DD"
            );
          }
			//&& parseFloat(unitProductPrice) > 0
          if ( parseFloat(totalCompoPrice) > 0 ) {
            var postObject = {};
            postObject = {
              app_id: appId,
              product_id: productId,
              product_qty: prodcutQty,
              outlet_id: outlet_id,
              product_min_qty: product_min_qty,
              availability_id: cateringId,
              product_name: productName,
              product_total_price: totalCompoPrice,
              product_unit_price: unitProductPrice,
              product_remarks: productRemarks,
              product_image: productImage,
              product_sku: productSku,
              product_slug: productSlug,
              modifiers: JSON.stringify(modifier),
              menu_set_component: JSON.stringify(menuSet),
              individual: "yes",
              customer_id: customerId,
              reference_id: customerId === "" ? getReferenceID() : "",
              product_edit_enable: "No",
              breaktime_enable: "Yes",
              breaktime_count: 1,
              breaktime_list:
                this.state.event_start + "-" + this.state.event_end,
              breaktime_indexflag: breaktime_indexflag,
              breaktime_started: cookie.load("orderTime"), // this.state.event_start
              breaktime_ended: cookie.load("orderTimeEnd"), // this.state.event_end
              product_special_notes: this.state.specialInstruction,
              cart_catering_order_date: catering_order_date,
              setup: JSON.stringify(postSetup),
              equipment: JSON.stringify(equipmentSetups),
              addons_setup: JSON.stringify(postAddons)
            };

            axios
              .post(apiUrlCtrng + "cart/insert", qs.stringify(postObject))
              .then(res => {
                if (res.data.status === "ok") {
                  $(".add-breaktime").show();
                  $(".add_to_cart").removeClass("loading-btn");
                  $(".close").trigger("click");
                  $(".addition-breaktime").hide();
                  cookie.save("cartTotalItems", res.data.contents.cart_details.cart_total_items, { path: "/" });                  
                  swal("Success!", res.data.message, "success");
                  this.props.loadcartitems(true);
                  this.setState({ productGrandTotal: 0 }, () => {
                    this.setPropActiveTab(4)
                  });
                  
                } else if (res.data.status === "error") {
                  swal("Error!", res.data.message, "error");
                }
              });
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      $(".compocart_error_msg").html("Sorry!. Invalid product combination.");
      $(".compocart_error_msg").show();
      $(".compocart_error_msg")
        .delay(6000)
        .fadeOut();
      return false;
    }
  }
  getComboproData($_this, CompoType) {
    var custom_banner_text = this.state.custom_banner_text;
    var comboproSet = [],
      comboproMdf = [];
    $_this.find(".individual_combo_pro").each(function() {
      var extraqtyy = $(this).attr("data-extraqty");
      var extraproductprices = $(this).attr("data-extraproductprice");
      var custom_logo,
        custom_text = "";
      if ($(this).hasClass("selected")) {
        var modifiers_apply = $(this).attr("data-modifierapply");
        var extra_amount = parseInt($(this).attr("data-extraprice"));
        if (parseInt(modifiers_apply) === 0) {
          var componentsProDet = $(this).attr("data-proddet");
          var compoProDet = componentsProDet.split("~~");

          var compoProductDet = $(this).attr("data-combodata");
          var compoProDetails = compoProductDet.split("~");

          var combolstQtyValue = $(this)
            .find(".combo_qtycount")
            .attr("data-qtyval");
          if ($(this).find(".combo_qtycount").length <= 0) {
            combolstQtyValue = 1;
          }
          var comboProInvPrice;
          if (extra_amount === 1) {
            comboProInvPrice = $(this).attr("data-invcomboprice");
          } else {
            comboProInvPrice = 0;
          }

          if (custom_banner_text && custom_banner_text[compoProDetails[0]]) {
            if (custom_banner_text[compoProDetails[0]][compoProDet[0]]) {
              custom_text =
                custom_banner_text[compoProDetails[0]][compoProDet[0]][
                  "custom_banner_text"
                ];
              custom_logo =
                custom_banner_text[compoProDetails[0]][compoProDet[0]][
                  "custom_logo_banner"
                ];
            }
          }

          comboproSet.push({
            product_id: compoProDet[0],
            product_name: compoProDet[1],
            product_sku: compoProDet[2],
            product_price: comboProInvPrice,
            product_qty: combolstQtyValue,
            modifiers: comboproMdf,
            cart_menu_component_product_extra_qty: parseInt(extraqtyy),
            cart_menu_component_product_extra_price: parseFloat(
              extraproductprices
            ),
            custom_logo: custom_logo,
            custom_text: custom_text
          });

        } else {

          var comboMdfSet = [];
          var comboMdfValueSet;
          var modifierCombosets_txt = $(this).find(".package_checkitem").attr("data-mdfmain");
          var mdfSetDet = (modifierCombosets_txt !== "" && modifierCombosets_txt !== undefined) ? modifierCombosets_txt.split("~~"): [];
          var mdfcombopro_id = $(this).find(".modifiers_valuecheck").attr("data-mdfcombopro");
          var componentid = $(this).find(".modifiers_valuecheck").attr("data-componentid");

          $_this.find(".package_checkitem").each(function() {
            comboMdfValueSet = [];
            $(this).find(".compo_mdf_" + mdfcombopro_id + "_" + componentid).each(function() {

                if ($(this).is(":checked")) {

                  var combopro_mdf_txt = $(this).attr("data-mdfcombopro");

                  if (combopro_mdf_txt !== "") {

                    var combopro = $(this).parent().attr("data-mdfsub");
                    var compoProDetail = combopro.split("~~");

                    if (Object.keys(mdfSetDet).length > 0) {
                      var amount_modval = extra_amount === 1 ? compoProDetail[3] : 0;
                      comboMdfValueSet.push({
                        modifier_value_name: compoProDetail[1],
                        modifier_value_id: compoProDetail[0],
                        modifier_value_price: amount_modval,
                        modifier_value_qty: 1
                      });
                    }
                  }
                }
          });

          if (comboMdfValueSet !== null && comboMdfValueSet !== undefined) {
            comboMdfSet.push({
              modifier_name: mdfSetDet[1],
              modifier_id: mdfSetDet[0],
              modifiers_values: comboMdfValueSet
            });
          }

        });

          var componentsProDet = $(this).attr("data-proddet");

          var compoProDet = componentsProDet.split("~~");
          if (extra_amount === 1) {
            var comboPrice = parseFloat(compoProDet[3]) > 0 ? compoProDet[3] : 0;
          } else {
            var comboPrice = 0;
          }

          var combolstQtyValue = $(this).find(".combo_qtycount").attr("data-qtyval");

          if ($(this).find(".combo_qtycount").length <= 0) {
            combolstQtyValue = 1;
          }

          if (custom_banner_text && custom_banner_text[componentid]) {
            if (custom_banner_text[componentid][compoProDet[0]]) {
              custom_text = custom_banner_text[componentid][compoProDet[0]]["custom_banner_text"];
              custom_logo = custom_banner_text[componentid][compoProDet[0]]["custom_logo_banner"];
            }
          }
          comboproSet.push({
            product_id: compoProDet[0],
            product_name: compoProDet[1],
            product_sku: compoProDet[2],
            product_price: comboPrice,
            product_qty: combolstQtyValue,
            modifiers: comboMdfSet,
            cart_menu_component_product_extra_qty: parseInt(extraqtyy),
            cart_menu_component_product_extra_price: parseFloat( extraproductprices),
            custom_logo: custom_logo,
            custom_text: custom_text
          });
        }
      }
    });

    return comboproSet;
  }

  closeToggle() {
    this.setState({
      modalPopup: false,
      productGrandTotal: 0,
      product_modifiers_price: 0,
      specialInstruction: ""
    });
    const parameter =
      "&subcategory_id=" +
      this.state.subcategory_id +
      "&product_is_combo='Yes'";
    this.props.getProduct(parameter);
  }

  updateProductPax(indFlg, type, selectpaxcount, minpaxcount) {
    minpaxcount = parseInt(minpaxcount) > 0 ? parseInt(minpaxcount) : 5;
    selectpaxcount = parseInt(selectpaxcount);

    let pax_increase = 1;
    
    if (type === "minus") {
      var tempVl = selectpaxcount > minpaxcount ? selectpaxcount - pax_increase : 0;
      if (tempVl >= pax_increase) {
        selectpaxcount = selectpaxcount - pax_increase;
      }
    } else {
      selectpaxcount = selectpaxcount + pax_increase;
    }

    var indKey = "Index_" + indFlg;
    var proFieldArr = [];
    var stateProFldDet = this.state.productfeilsdetail;
    if (Object.keys(stateProFldDet).length > 0) {
      proFieldArr = !(indKey in stateProFldDet) ? [] : stateProFldDet[indKey];
    }
    stateProFldDet[indKey] = proFieldArr;
    this.setState(
      { productfeilsdetail: stateProFldDet, selectpaxcount: selectpaxcount },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  checkMinSelect(menuCmpId, proId, type, productId) {

   let $_cur_ths = $("#done-indvcombo-" + menuCmpId + "-" + proId);

    var errorFlg = "No";
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);

    $_this.find(".mdfr_list_divlcs").each(function() {
      var checked_count = 0;
      var modDivId = $(this).attr("id");
      var modMin = $(this).attr("data-mdfmin");
      var modMax = $(this).attr("data-mdfmax");
      $(this)
        .find(".modifiers_valuecheck")
        .each(function() {
          if ($(this).is(":checked")) {
            checked_count += 1;
          }
        });

      if (parseInt(modMin) > parseInt(checked_count)) {
        errorFlg = "Yes";
        var errorTxt = "Please select minimum " + modMin + " item(s).";
        $(this)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $_this.addClass("package-error");
        $(this)
          .find(".pkgerror_txt")
          .show();
        return false;
      }

      if (errorFlg === "Yes") {
        $(this)
          .find(".pkgerror_txt")
          .hide();
      }
    });

    if (errorFlg === "No") {

      $_this.addClass("selected");
      $_this.parent().find(".prd_close").show();

      $_cur_ths.parent().parent().find(".package_checkitem").hide();
      $_cur_ths.parent().hide();
      $_cur_ths.parent().parent().next(".det-wrap").show();

      this.incComboQty(menuCmpId, proId, type, productId, "", "", 1);

    }
  }

  singleProductcount(
    menuCmpId,
    proId,
    type,
    productId,
    modId,
    product_type,
    countt,
    max_pax
  ) {
    if (countt === "" || countt === null) {
      countt = 1;
    }
    if (max_pax === "") {
      max_pax = 100;
    }
    var product_state_count = $(".indvcombo-" + menuCmpId + "-" + proId)
      .find(".combo_qtycount")
      .attr("data-qtyval");
    if (type === "single_minus") {
      var tempVl = parseInt(
        product_state_count > 0 ? product_state_count - parseInt(countt) : 0
      );

      if (tempVl >= 1 && tempVl <= parseInt(max_pax)) {
        product_state_count = product_state_count - parseInt(countt);
        $(".indvcombo-" + menuCmpId + "-" + proId).addClass("selected");
        $("." + productId + "_count_exist_error").hide();
      }
      if (tempVl <= 0) {
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .find(".custom_text_logo")
          .hide();
        $(".indvcombo-" + menuCmpId + "-" + proId).removeClass("selected");
        $(".indvcombo" + "-" + menuCmpId + "-" + proId)
          .find(".custom_logo_banner")
          .hide();
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .parent()
          .find(".prd_close")
          .hide();
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .find(".custom_checkbox")
          .show();
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .find(".show_details")
          .show();
        product_state_count = 1;
      }
    } else {
      var diff_in_qty = parseInt(countt) - parseInt(product_state_count);
      if (diff_in_qty > 0) {
        product_state_count =
          parseInt(product_state_count) + parseInt(diff_in_qty);
      } else if (
        max_pax !== "" &&
        parseInt(product_state_count) < parseInt(max_pax)
      ) {
        var dif_qty = parseInt(max_pax) - parseInt(product_state_count);
        if (parseInt(dif_qty) < parseInt(countt)) {
          product_state_count =
            parseInt(product_state_count) + parseInt(dif_qty);
        } else {
          product_state_count =
            parseInt(product_state_count) + parseInt(countt);
        }
        $(".indvcombo-" + menuCmpId + "-" + proId).addClass("selected");
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .parent()
          .find(".prd_close")
          .show();
        $(".indvcombo-" + menuCmpId + "-" + proId)
          .find(".custom_checkbox")
          .show();
      }
      if (parseInt(product_state_count) >= parseInt(max_pax)) {
        $("." + productId + "_count_exist_error").show();
      }
    }
    var dqty = diff_in_qty > 0 ? diff_in_qty : countt;
    $(".indvcombo-" + menuCmpId + "-" + proId)
      .find(".combo_qtycount")
      .attr("data-qtyval", product_state_count);
    $(".indvcombo-" + menuCmpId + "-" + proId)
      .find(".qty_val")
      .text(product_state_count);

    this.setState(
      {
        single_product_selected: product_state_count,
        single_product_count: product_state_count
      },
      function() {
        this.incComboQty(
          menuCmpId,
          proId,
          type,
          productId,
          modId,
          product_type,
          dqty
        );
      }.bind(this)
    );


  }

  singleProductcountForModifier( menuCmpId,proId, type,productId,modId,product_type, countt, max_pax) {

    //console.log('inside log modifier');

    if (countt === "" || countt === null) {
      countt = 1;
    }
    if (max_pax === "") {
      max_pax = 100;
    }

    var product_state_count = $(".indvcombo-" + menuCmpId + "-" + proId)
      .find(".combo_qtycount")
      .attr("data-qtyval");
    if (type === "single_minus") {
      var tempVl = parseInt(
        product_state_count > 0 ? product_state_count - parseInt(countt) : 1
      );

      if (tempVl >= 1 && tempVl <= parseInt(max_pax)) {
        product_state_count = product_state_count - parseInt(countt);

        $("." + productId + "_count_exist_error").hide();

      }

    } else {
      var diff_in_qty = parseInt(countt) - parseInt(product_state_count);
      if (diff_in_qty > 0) {
        product_state_count = parseInt(product_state_count) + parseInt(diff_in_qty);
      } else if ( max_pax !== "" && parseInt(product_state_count) < parseInt(max_pax)) {
        var dif_qty = parseInt(max_pax) - parseInt(product_state_count);
        if (parseInt(dif_qty) < parseInt(countt)) {
          product_state_count = parseInt(product_state_count) + parseInt(dif_qty);
        } else {
          product_state_count = parseInt(product_state_count) + parseInt(countt);
        }  
      }
      if (parseInt(product_state_count) >= parseInt(max_pax)) {
        $("." + productId + "_count_exist_error").show();
      }
    }

    var dqty = diff_in_qty > 0 ? diff_in_qty : countt;
    $(".indvcombo-" + menuCmpId + "-" + proId).find(".combo_qtycount").attr("data-qtyval", product_state_count);
    $(".indvcombo-" + menuCmpId + "-" + proId).find(".qty_val").text(product_state_count);

    this.setState({["mulcompoInner~~"+menuCmpId+"~~"+proId]: [product_state_count] });

    //console.log(product_state_count,'product_state_count');

  }

  checkMinMaxMod(
    menuCmpId,
    proId,
    type,
    productId,
    modId,
    custom_logo,
    countt
  ) {
    $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".modifier-btn-grp")
        .show();
    var errorFlg = "No";
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    $_this.find(".mdfr_list_divlcs").each(function() {
      var checked_count = 0;
      var modDivId = $(this).attr("id");
      var modMin = $(this).attr("data-mdfmin");
      var modMax = $(this).attr("data-mdfmax");
      $(this)
        .find(".modifiers_valuecheck")
        .each(function() {
          if ($(this).is(":checked")) {
            $_this.removeClass("package-error");
            checked_count += 1;
          }
        });
      if (parseInt(modMin) > parseInt(checked_count)) {
        errorFlg = "Yes";
        var errorTxt = "Please select minimum " + modMin + " item(s).";
        $(this)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $_this.addClass("package-error");
        $(this)
          .find(".pkgerror_txt")
          .show();
        return false;
      }

      if (parseInt(modMax) < parseInt(checked_count)) {
        errorFlg = "Yes";
        var errorTxt =
          "Sorry!. maximum of selected item is " + modMax + " only.";
        $(this)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $_this.removeClass("package-error");
        $(this).addClass("pkgerror_out");
        $(this)
          .find(".pkgerror_txt")
          .show();
        $(this)
          .find(".mod_" + modId)
          .prop("checked", false);
        return false;
      } else {
        $(this)
          .find(".pkgerror_txt")
          .hide();
      }
    });
  }

  incComboQty(menuCmpId, proId, type, productId, modId, custom_logo, countt) {

    //console.log(type,'type');

    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    if (type != "cancel") {
      if (type === "simple") {
        $(".indvcombo" + "-" + menuCmpId + "-" + proId).addClass("selected");
      }
      if (type === "simple" || type === "extraModifier") {
        var custom_logo_banner = $(".indvcombo" + "-" + menuCmpId + "-" + proId).attr("data-customlogobanner");
        if (custom_logo_banner) {
          $(".indvcombo" + "-" + menuCmpId + "-" + proId).find(".custom_logo_banner").show();
        } else {
          $(".indvcombo" + "-" + menuCmpId + "-" + proId).find(".custom_logo_banner").hide();
        }
      }
    }

    var intValInc = 1;
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo = $_this.closest(".main_combo_div").attr("data-packageminlimit") != "" ? $_this.closest(".main_combo_div").attr("data-packageminlimit") : "0";
    var maxselectcombo =  $_this.closest(".main_combo_div").attr("data-packagemaxlimit") != "" ? $_this.closest(".main_combo_div").attr("data-packagemaxlimit") : "0";

    let componentapplyprice_flag = $_this.closest(".main_combo_div").attr("data-componentapplyprice");

    //console.log(componentapplyprice_flag,'componentapplyprice_flag');

    var invCompoQty = this.getInvCompoQty($_this);
    var chk_val = 0;
    if (!isNaN(intValInc) && parseInt(invCompoQty) < parseInt(maxselectcombo)) {
      intValInc = parseInt(intValInc + 1);
      chk_val = 1;
    }

    var comboProPrice = $_this.attr("data-invcomboprice");

    var invCompoQty = this.getInvCompoQty($_this);
    if (invCompoQty > parseInt(minselectcombo) && chk_val === 1) {
      comboProPrice = parseFloat(comboProPrice);
    }

    if (parseInt(minselectcombo) === 0) {
      $_this.find(".combo_pro_price").show();
    }
    $_this.attr("data-invcomboprice", comboProPrice);
    var length = 0;
    var qty_incVal = 0;
 
    if (type === "cancel") {
      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .parent()
        .find(".prd_close")
        .hide();
      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".package_checkitem")
        .hide();

        $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".modifier-btn-grp")
        .hide();
       
      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".custom_text_logo")
        .hide();
      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".custom_checkbox")
        .show();
      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".popup_price_show")
        .hide();
      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".show_details")
        .show();
      $(".indvcombo" + "-" + menuCmpId + "-" + proId).removeClass("selected");

      $(".indvcombo" + "-" + menuCmpId + "-" + proId)
        .find(".custom_logo_banner")
        .hide();
    }

    $("#product_" + menuCmpId) .find(".individual_combo_pro").each(function() {
        if ($(this).hasClass("selected")) {
          qty_incVal = $(this).find(".combo_qtycount").attr("data-qtyval");
          if (qty_incVal) {
            length += parseInt(qty_incVal);
          } else {
            length += 1;
          }
        }
      });

    var total_length = length;
    var individualModPrice = this.state.individualModPrice;
    let tempIndividualModPrice = individualModPrice;

    $("#" + menuCmpId).attr("data-selected", total_length);
    comboProPrice = this.state.product_modifiers_price;

    let excludepricemultiplypax_flag;

    $(".indvcombo" + "-" + menuCmpId + "-" + proId).each(function() {

      var modifierapply = $(this).attr("data-modifierapply");
      excludepricemultiplypax_flag = $(this).attr("data-excludepricemultiplypax");
      var mdfcombopro = $(this).find(".modifiers_valuecheck").attr("data-mdfcombopro");
      var componentid = $(this).find(".modifiers_valuecheck").attr("data-componentid");
      var extraqty = parseInt($(this).attr("data-extraqty"));
      var extraamount = parseFloat($(this).attr("data-invcomboprice"));

      //console.log(excludepricemultiplypax_flag,modifierapply,'excludepricemultiplypax_flag,modifierapply');

      if (parseInt(modifierapply) === 1) {
        $_this.find(".mdfr_list_divlcs").each(function() {

          $(this).find(".modifiers_valuecheck").each(function() {

              if ($(this).is(":checked")) {
                var modprice = parseFloat($(this).attr("data-modprice"));
                extraamount += parseFloat(modprice);
              }

            });
        });
      }
     // console.log(extraamount,'extraamount');

      if (total_length > minselectcombo) {

       // console.log(total_length,minselectcombo,'total_length > minselectcombo');

        $(this).find(".det-wrap").show();
        $(this).find(".popup_price").show();
        $(this).attr("data-extraprice", 1);
        var orgQty,appendAmnt = "";

        if (type === "single_plus" || type === "simple" || type === "modifier") {

          orgQty = parseInt(extraqty + parseInt(countt));
          $(this).attr("data-extraqty", orgQty);
          appendAmnt = parseFloat(orgQty * extraamount);
          $(this).attr("data-extraproductprice", appendAmnt);

        } else if (parseInt(modifierapply) === 1 && type === "extraModifier") {
          $(this).attr("data-extraqty", 0);
          $(this).attr("data-extraproductprice", 0);

        } else {
          orgQty = parseInt(extraqty - parseInt(countt));
          appendAmnt = parseFloat(orgQty * extraamount);
          $(this).attr("data-extraqty", orgQty);
          $(this).attr("data-extraproductprice", appendAmnt);
        }
      } else {

       // console.log(total_length,minselectcombo,'Else total_length > minselectcombo');

        $("#product_" + menuCmpId).find(".popup_price").hide();

        if (type === "cancel" || type === "single_minus") {
          $("#product_" + menuCmpId).find(".individual_combo_pro").each(function() {
              $("#product_" + menuCmpId).find(".individual_combo_pro").attr("data-extraprice", 0);
              $(this).attr("data-extraqty", 0);
          });
        }
      }

      var prodPrice = $(this).attr("data-invcomboprice");

      if (total_length >= minselectcombo) {

        $("#step_" + menuCmpId).addClass("completed");

       // console.log(total_length,minselectcombo,'total_length >= minselectcombo');

        if ( (type === "simple" || type === "single_plus" || type === "modifier") && total_length > minselectcombo) {

          comboProPrice += parseInt(countt) * parseFloat(prodPrice);

        } else if (type === "cancel" || type === "single_minus") {

          comboProPrice = parseFloat(comboProPrice) - parseInt(countt) * parseFloat(prodPrice);
          
          if (minselectcombo === 0) {
            $("#step_" + menuCmpId).removeClass("completed");
          }

        }
        if (parseInt(modifierapply) === 1) {

          $(this).find(".compo_mdf_" + mdfcombopro + "_" + componentid).each(function() {

              if ($(this).is(":checked")) {
                var modprice = $(this).attr("data-modprice");
                var isindividual = $(this).attr("data-isindividual");
                if (parseInt(modprice) > 0) {
                  if (type === "cancel") {
                    if ( isindividual === "Yes" && parseFloat(individualModPrice) > 0) {
                      individualModPrice = parseFloat(individualModPrice) - parseFloat(modprice);
                    } else {
                      comboProPrice = parseFloat(comboProPrice) - parseFloat(modprice);
                    }
                    $(".indvcombo" + "-" + menuCmpId + "-" + proId).find(".modifiers_valuecheck").prop("checked", false);
                  
                  } else if (total_length > minselectcombo) {
                    if (isindividual === "Yes") {
                      individualModPrice += parseFloat(modprice);
                    } else {
                      comboProPrice += parseFloat(modprice);
                    }
                  } else if(componentapplyprice_flag){
                    if (isindividual === "Yes") {
                      individualModPrice += parseFloat(modprice);
                    } else {
                      comboProPrice += parseFloat(modprice);
                    }
                  } else {}
                }
              } else {
              }
            });
        }
      } else {
        $("#step_" + menuCmpId).removeClass("completed");
       // console.log(total_length,minselectcombo,'before minselectcombo');

        /*Before we reach the minimum select we apply price */
        if (parseInt(modifierapply) === 1) {

          $(this).find(".compo_mdf_" + mdfcombopro + "_" + componentid).each(function() {

              if ($(this).is(":checked")) {
                var modprice = $(this).attr("data-modprice");
                var isindividual = $(this).attr("data-isindividual");
                if (parseInt(modprice) > 0) {

                //   console.log(type,individualModPrice,isindividual,comboProPrice,modprice,'type,individualModPrice,isindividual,comboProPrice,modprice');

                  if (type === "cancel") {

                    if ( isindividual === "Yes" && parseFloat(individualModPrice) > 0) {
                      individualModPrice = parseFloat(individualModPrice) - parseFloat(modprice);
                    } else {
                      comboProPrice = parseFloat(comboProPrice) - parseFloat(modprice);
                    }

                    $(".indvcombo" + "-" + menuCmpId + "-" + proId).find(".modifiers_valuecheck").prop("checked", false);

                  } else if(componentapplyprice_flag){
                    if (isindividual === "Yes") {
                      individualModPrice += parseFloat(modprice);
                    } else {
                      comboProPrice += parseFloat(modprice);
                    }
                  } else {}
                }
              } else {}
            });
        }

        /*if(parseInt(extraqty) > 0){
            comboProPrice =parseFloat(comboProPrice) - ( parseInt(extraqty) * parseFloat(prodPrice));
           }*/
      }

      let perpax_price = $(this).attr("data-extraproductprice");

      tempIndividualModPrice = individualModPrice - tempIndividualModPrice;

      if(Number(perpax_price)) {
       // console.log(individualModPrice,tempIndividualModPrice,'individualModPrice,tempIndividualModPrice');
        if(tempIndividualModPrice) {
          perpax_price = `$${parseFloat(perpax_price).toFixed(2)}`;
        }else {
          perpax_price = `$${parseFloat(perpax_price).toFixed(2)} / pax`;
        }
      }

      if(parseFloat(perpax_price) == 0) {
        perpax_price = '';
      }

      // $_this.find(".popup_price").html(perpax_price);

    });

   // console.log(prodPrice,extraamount,comboProPrice,countt,'prodPrice , extraamount,comboProPrice,countt');

   // console.log(comboProPrice,individualModPrice,'comboProPrice,individualModPrice');

    if (!isNaN(comboProPrice) && comboProPrice !== undefined) {
      this.setState(
        {
          product_modifiers_price: comboProPrice,
          total_length: total_length,
          individualModPrice: individualModPrice,
          excludepricemultiplypax_flag:excludepricemultiplypax_flag
        },
        function() {
          this.calculatedOverAllAmount();
        }.bind(this)
      );
    }
  }

  updateProductPricefun(amount) {
    var qty_txt =
      $(".products_quantity").html() != ""
        ? parseInt($(".products_quantity").html())
        : 0;
    var pro_price_val =
      $("#product_unitprice").val() != ""
        ? parseFloat($("#product_unitprice").val())
        : 0;

    var exc_price = parseFloat(pro_price_val) + parseFloat(amount);
    exc_price = parseInt(qty_txt) * parseFloat(exc_price);
    this.setState({ productGrandTotal: exc_price });
  }

  getInvCompoQty($_this) {
    // $_this.closest('.main_combo_div').find('.error_combo_div').hide();
    var combolst_qty = 0;
    $_this
      .closest(".main_combo_div")
      .find(".combolst_qty_value")
      .each(function() {
        combolst_qty += parseInt($(this).attr("data-qtyval"));
      });
    return combolst_qty;
  }

  handleSpecialInstruction(event) {
    var remarks = event.target.value;
    this.setState({ specialInstruction: remarks });
  }

  handlecustomLogoChange(product_id, compId, event) {
    var changeArr = this.state.changeArr;
    this.state.custom_logo_banner[compId] = [];
    this.state.custom_logo_banner[compId][product_id] = [];

    if (changeArr[product_id] === undefined) {
      changeArr[product_id] = [];
    }
    if (event.target.name === "custom_logo_banner") {
      var file = event.target.files[0];
      var file_name = file.name ? file.name : "";
      var postObject = {};
      postObject = {
        app_id: appId,
        product_image: file.name,
        image: file
      };

      let formData = new FormData();
      for (let k in postObject) {
        formData.append(k, postObject[k]);
      }
      this.props.uploadImage(formData);
      changeArr[product_id]["custom_logo_banner"] = file.name;
    } else if (event.target.name === "custom_banner_text") {
      changeArr[product_id]["custom_banner_text"] = event.target.value;
    }
    this.state.custom_logo_banner[compId] = changeArr;
    this.setState({
      custom_banner_text: this.state.custom_logo_banner,
      imageLoadId: product_id,
      imageCompId: compId,
      imageName: file_name
    });
  }

  handleunlinkImage() {
    let formData = new FormData();
    var postObject = {};
    postObject = {
      app_id: appId,
      imageName: this.state.imageName
    };
    for (let k in postObject) {
      formData.append(k, postObject[k]);
    }
    this.props.deleteImage(formData);
  }

  getDeliverysetup(index, delivery) {
    const Deliveryhtml = delivery.map((DelryValue, index4) => (
      <li
        key={index4}
        className="setup_li"
        data-setupdet={
          DelryValue.catering_delivery_setup_id +
          "~~" +
          DelryValue.catering_delivery_title +
          "~~" +
          DelryValue.catering_delivery_description +
          "~~" +
          DelryValue.catering_delivery_price +
          "~~1~~Delivery"
        }
      >
        <div className="custom_checkbox">
          <input
            type="checkbox"
            className="setup_listing_value"
            value={DelryValue.catering_delivery_price}
            onChange={this.handleUpdateSetup.bind(
              this,
              index,
              index4,
              "delivery"
            )}
          />
          <span>
            {DelryValue.catering_delivery_title +
              " ( " +
              showPriceValue(DelryValue.catering_delivery_price) +
              " ) : " +
              DelryValue.catering_delivery_description}
          </span>
        </div>
      </li>
    ));
    return Deliveryhtml;
  }

  /* Buffet List */
  getBuffetsetup(index, buffet) {
    const Buffethtml = buffet.map((buffetValue, index4) => (
      <li
        key={index4}
        className="setup_li"
        data-setupdet={
          buffetValue.catering_buffet_setup_id +
          "~~" +
          buffetValue.catering_buffet_title +
          "~~" +
          buffetValue.catering_buffet_description +
          "~~" +
          buffetValue.catering_buffet_price +
          "~~1~~Buffet"
        }
      >
        <div className="custom_checkbox">
          <input
            type="checkbox"
            className="setup_listing_value"
            value={buffetValue.catering_buffet_price}
            onChange={this.handleUpdateSetup.bind(
              this,
              index,
              index4,
              "buffet"
            )}
          />
          <span>
            {buffetValue.catering_buffet_title +
              " ( " +
              showPriceValue(buffetValue.catering_buffet_price) +
              " ) : " +
              buffetValue.catering_buffet_description}
          </span>
        </div>
      </li>
    ));
    return Buffethtml;
  }

  /* Selef Collection List */
  getSelfCollectionsetup(index, selfcollection) {
    const Selfcollectionhtml = selfcollection.map(
      (selfCollectionValue, index4) => (
        <li
          key={index4}
          className="setup_li"
          data-setupdet={
            selfCollectionValue.catering_self_collection_setup_id +
            "~~" +
            selfCollectionValue.catering_self_collection_title +
            "~~" +
            selfCollectionValue.catering_self_collection_description +
            "~~" +
            selfCollectionValue.catering_self_collection_price +
            "~~1~~Self Collection"
          }
        >
          <div className="custom_checkbox">
            <input
              type="checkbox"
              className="setup_listing_value"
              value={selfCollectionValue.catering_self_collection_price}
              onChange={this.handleUpdateOtherSetup.bind(
                this,
                index,
                index4,
                "self_collection"
              )}
            />
            <span>
              {selfCollectionValue.catering_self_collection_title +
                " ( " +
                showPriceValue(
                  selfCollectionValue.catering_self_collection_price
                ) +
                " ) : " +
                selfCollectionValue.catering_self_collection_description}
            </span>
          </div>
        </li>
      )
    );
    return Selfcollectionhtml;
  }

  /* Equipment List */
  getEquipmentsetup(index, equipment) {
    const Equipmenthtml = equipment.map((item, index4) => (
      <li
        key={index4}
        id={"equip_" + item.catering_equipment_setup_id}
        className="setup_li"
        data-setupdet={
          item.catering_equipment_setup_id +
          "~~" +
          item.catering_equipment_title +
          "~~" +
          item.catering_equipment_description +
          "~~" +
          item.catering_equipment_price +
          "~~1~~Equipment"
        }
      >
        <span className="counter">
          <button
            onClick={this.equipmentcount.bind(
              this,
              index,
              item.catering_equipment_setup_id,
              "minus"
            )}
          >
            -
          </button>
          <span className="qty_val">0</span>
          <button
            onClick={this.equipmentcount.bind(
              this,
              index,
              item.catering_equipment_setup_id,
              "plus"
            )}
          >
            +
          </button>
        </span>
        <p>
          {item.catering_equipment_description +
            " ( " +
            showPriceValue(item.catering_equipment_price) +
            " )"}
        </p>
      </li>
    ));
    return Equipmenthtml;
  }

  equipmentcount(indFlg, equipmentID, type) {
    let eqdetails = $("#equip_" + equipmentID);
    let existValue = eqdetails.find(".qty_val").text();
    let newVAlue = "";
    if (type === "plus") {
      newVAlue = parseInt(existValue) + 1;
    } else if (type === "minus") {
      newVAlue = parseInt(existValue) - 1;
    }
    eqdetails.find(".qty_val").html(newVAlue);

    var equipmentArr = Array();
    var incvl = 0;
    $("#equipmentsetup_" + indFlg)
      .find(".equipment_listing_div")
      .find(".setup_li")
      .each(function() {
        if (
          $(this)
            .find(".qty_val")
            .text() > 0
        ) {
          equipmentArr[incvl] =
            $(this).attr("data-setupdet") +
            "~~" +
            $(this)
              .find(".qty_val")
              .text();
          incvl++;
        }
      });
    var sateProductSetup = this.state.equipmentSetup;
    sateProductSetup = equipmentArr;
    this.setState(
      { equipmentSetup: sateProductSetup },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  productAddonsSetup(indFlg, addonsSetup) {
    const addonshtml = addonsSetup.map((proAddons, index3) => (
      <li
        key={index3}
        id={"addonsLi-" + indFlg + "-" + index3}
        onChange={this.openAddonsPopup.bind(this, indFlg, index3, proAddons)}
        className={this.CheckAddonsValues(indFlg, index3, "class")}
        data-addonmin={proAddons.cat_addon_min_select}
        data-addonmax={proAddons.cat_addon_max_select}
        data-addontype={proAddons.cat_addon_selection}
      >
        <div className="custom_checkbox custom-addon-checkbox">
          <input
            type="checkbox"
            onChange={this.handlerTestFun.bind(this)}
            className="addonCheck"
            checked={this.CheckAddonsValues(indFlg, index3, "chkbox")}
          />
          <span> {proAddons.cat_addon_title} </span>
          <div className="pkgerror_txt" style={{ display: "none" }}>
            Please select 1 more item!
          </div>
        </div>
        <div
          id={"addonsDet-" + indFlg + "-" + index3}
          className="white-popup mfp-hide popup_sec addon_popup"
        >
          <div className="pouup_in">
            <div className="addon_poptop">
              <div className="addon_poptop_header">
                <h3>
                  {proAddons.cat_addon_title}

                  {parseInt(proAddons.cat_addon_min_select) === 0 &&
                  parseInt(proAddons.cat_addon_max_select) === 0 ? (
                    ""
                  ) : (
                    <small>
                      (Select{" "}
                      {proAddons.cat_addon_min_select +
                        " - Max " +
                        proAddons.cat_addon_max_select}{" "}
                      selections)
                    </small>
                  )}
                </h3>
              </div>

              <div className="addon_listsec">
                {proAddons.cat_addon_selection === "single"
                  ? this.singleAddonPopupList(indFlg, index3, proAddons)
                  : this.multipleAddonPopupList(indFlg, index3, proAddons)}
              </div>
            </div>
            <div className="btn_sec">
              <div className="two-button-row">
                <div className="go_second">
                  <a
                    href="javascript:void(0)"
                    className="button button-left common-mfd-closefun"
                    title="Go Back"
                  >
                    Go Back
                  </a>
                </div>
                <div className="con_first delivery_submit_cls">
                  <a
                    href="javascript:void(0)"
                    className="button button-right common-mfd-closefun"
                    title="Continue"
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    ));
    return addonshtml;
  }

  openAddonsPopup(indFlg, index3, DelryValue) {
    var productAddons = this.state.product_addons;
    var selecteAddonsMain = Array();
    var selecteAddonSub = Array();
    if (Object.keys(productAddons).length > 0) {
      var indKey = "Index_" + indFlg;
      selecteAddonsMain = !(indKey in productAddons)
        ? Array()
        : productAddons[indKey];
      if (Object.keys(selecteAddonsMain).length > 0) {
        var subKey = "addon_" + index3;
        selecteAddonSub = !(subKey in selecteAddonsMain)
          ? Array()
          : selecteAddonsMain[subKey];
      }
    }

    var liIdtxt = "addonsLi-" + indFlg + "-" + index3;
    $("#" + liIdtxt).removeClass("active");
    if (Object.keys(selecteAddonSub).length > 0) {
      $("#" + liIdtxt).addClass("active");
      $("#" + liIdtxt)
        .find(".addonCheck")
        .prop("checked", true);
    } else {
      $("#" + liIdtxt)
        .find(".addonCheck")
        .prop("checked", false);

      if (DelryValue.cat_addon_selection === "single") {
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".addon_list_single")
          .prop("checked", false);
      } else if (DelryValue.cat_addon_selection === "multiple") {
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".addon_qty_input")
          .val("0");
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".plue_muns_div")
          .hide();
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".qty_count_sp, .delete-act-icon")
          .hide();
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".add_tocart_div")
          .show();

        /*
				$('#addonsDet-'+indFlg+'-'+index3).find('.plue_muns_div').each(function(qtyIn){
					var addonminpax = $(this).attr('data-addonminpax');
					    addonminpax = (addonminpax !== '') ? parseInt(addonminpax) : 0;
				    $(this).find('.addon_qty_input').val(addonminpax);
						
				});
				*/
      }
    }

    var idText = "#addonsDet-" + indFlg + "-" + index3;
    window.$.magnificPopup.open({
      items: {
        src: idText
      },
      type: "inline"
    });
  }

  multipleAddonPopupList(indFlg, index3, proAddons) {
    var proAddonsValues = proAddons.addons_setup_values;

    if (Object.keys(proAddonsValues).length > 0) {
      const AddonsValuehtml = proAddonsValues.map((AddonsValue, index6) => (
        <div
          className="addon_listrw addon_list_main"
          id={"addon_main-" + indFlg + "-" + index3 + "-" + index6}
          key={index6}
        >
          <div className="addon_linfo">
            <h4>{AddonsValue.cat_addonvalues_title}</h4>
            {AddonsValue.cat_addonvalues_description !== "" ? (
              <p>{AddonsValue.cat_addonvalues_description}</p>
            ) : (
              ""
            )}
          </div>
          <div className="addon_lright text-right add_tocart_div">
            <span className="cross_multi qty_count_sp">
              {parseInt(AddonsValue.cat_addonvalues_min_pax) > 0
                ? "x" + AddonsValue.cat_addonvalues_min_pax
                : "x1"}
            </span>
            <div className="act-remove-topdiv">
              <span className="addon_price">
                {showPriceValue(AddonsValue.cat_addonvalues_price)}
              </span>
              <a
                href="javascript:void(0)"
                onClick={this.addonPlusMinusCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6,
                  "remove",
                  AddonsValue.cat_addonvalues_min_pax
                )}
                className="delete-act-icon"
                title="Remove"
                style={{ display: "none" }}
              >
                <i className="fa fa-trash-o" data-unicode="f014"></i>
              </a>
            </div>
            <a
              href="javascript:void(0)"
              onClick={this.addonSubAddtoCart.bind(
                this,
                indFlg,
                index3,
                index6
              )}
              className="btn btn_green"
              title="Add to cart"
            >
              Add to cart
            </a>
          </div>
          <div
            data-addonbind={
              proAddons.product_catering_addons_id +
              "~~" +
              proAddons.cat_addon_title +
              "~~" +
              proAddons.cat_addon_selection +
              "~~" +
              AddonsValue.cat_addonvalues_id +
              "~~" +
              AddonsValue.cat_addonvalues_title +
              "~~" +
              AddonsValue.cat_addonvalues_description +
              "~~" +
              AddonsValue.cat_addonvalues_price
            }
            className="addon_lright text-right plue_muns_div"
            data-addonminpax={AddonsValue.cat_addonvalues_min_pax}
          >
            <span className="cross_multi qty_count_sp">
              {parseInt(AddonsValue.cat_addonvalues_min_pax) > 0
                ? "x" + AddonsValue.cat_addonvalues_min_pax
                : "x1"}
            </span>

            <span className="addon_price">
              {showPriceValue(AddonsValue.cat_addonvalues_price)}
            </span>

            <span className="qty_bx counter">
              {/* <button
                className="qty_minus"
                onClick={this.addonPlusMinusCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6,
                  "minus",
                  AddonsValue.cat_addonvalues_min_pax
                )}
              > */}
              <span
                className="qty_minus"
                onClick={this.addonPlusMinusCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6,
                  "minus",
                  AddonsValue.cat_addonvalues_min_pax
                )}
              >
                -
              </span>
              <label className="addon_qty_lable combo-input">1</label>
              {/*<span className="addon_qty_lable">1</span>*/}
              <input
                type="hidden"
                className="addon_qty_input"
                value={this.getQtyValue(
                  indFlg,
                  index3,
                  index6,
                  AddonsValue.cat_addonvalues_min_pax
                )}
              />
              <span
                className="qty_plus"
                onClick={this.addonPlusMinusCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6,
                  "plus",
                  AddonsValue.cat_addonvalues_min_pax
                )}
              >
                +
              </span>
            </span>
            <a
              href="javascript:void(0)"
              onClick={this.addonDoneCart.bind(this, indFlg, index3, index6)}
              className="btn btn_green"
              title="Add to cart"
            >
              Done
            </a>
          </div>
        </div>
      ));
      return AddonsValuehtml;
    }
  }

  singleAddonPopupList(indFlg, index3, proAddons) {
    var proAddonsValues = proAddons.addons_setup_values;

    if (Object.keys(proAddonsValues).length > 0) {
      const AddonsValuehtml = proAddonsValues.map((AddonsValue, index6) => (
        <div
          className="addon_listrw addon_list_main"
          id={"addon_main-" + indFlg + "-" + index3 + "-" + index6}
          key={index6}
        >
          <div className="addon_linfo">
            <div className="custom_checkbox">
              <input
                type="checkbox"
                className="addon_list_single"
                onClick={this.addonSingleCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6
                )}
              />
              <span>
                <h4>{AddonsValue.cat_addonvalues_title}</h4>
                {AddonsValue.cat_addonvalues_description !== "" ? (
                  <p>{AddonsValue.cat_addonvalues_description}</p>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <div
            data-addonbind={
              proAddons.product_catering_addons_id +
              "~~" +
              proAddons.cat_addon_title +
              "~~" +
              proAddons.cat_addon_selection +
              "~~" +
              AddonsValue.cat_addonvalues_id +
              "~~" +
              AddonsValue.cat_addonvalues_title +
              "~~" +
              AddonsValue.cat_addonvalues_description +
              "~~" +
              AddonsValue.cat_addonvalues_price
            }
            className="addon_lright text-right add_tocart_div"
          >
            {/* <span
              className="cross_multi qty_count_sp">
              x1
            </span> */}
            <span className="addon_price">
              {showPriceValue(AddonsValue.cat_addonvalues_price)}
            </span>
          </div>
        </div>
      ));
      return AddonsValuehtml;
    }
  }

  CheckAddonsValues(indFlg, index3, type) {
    var productAddons = this.state.product_addons;
    var selecteAddonsMain = Array();
    var selecteAddonSub = Array();
    if (Object.keys(productAddons).length > 0) {
      var indKey = "Index_" + indFlg;
      selecteAddonsMain = !(indKey in productAddons)
        ? Array()
        : productAddons[indKey];
      if (Object.keys(selecteAddonsMain).length > 0) {
        var subKey = "addon_" + index3;
        selecteAddonSub = !(subKey in selecteAddonsMain)
          ? Array()
          : selecteAddonsMain[subKey];
      }
    }

    var classTxt = "";
    var chkBox = false;
    if (Object.keys(selecteAddonSub).length > 0) {
      classTxt = "active";
      chkBox = true;
    }

    return type === "class" ? classTxt : chkBox;
  }
  handlerTestFun() {}

  addonSingleCart(indFlg, index3, index6) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    var indKey = "Index_" + indFlg;
    var subKey = "addon_" + index3;
    var liIdtxt = "addonsLi-" + indFlg + "-" + index3;

    $("#" + liIdtxt)
      .find(".pkgerror_txt")
      .hide();
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_poptop_header")
      .find("small")
      .removeClass("apply-red");
    var addonMin = $("#" + liIdtxt).attr("data-addonmin");
    var addonMax = $("#" + liIdtxt).attr("data-addonmax");
    var minmaxVld = "Yes";
    if (parseInt(addonMin) === 0 && parseInt(addonMax) === 0) {
      minmaxVld = "No";
    }

    var tempArr = Array();
    var allValEmpty = "yes";
    var incvl = 0;
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_list_single")
      .each(function(newInt) {
        if ($(this).is(":checked")) {
          var alltmFl = $(this)
            .closest(".addon_list_main")
            .find(".add_tocart_div")
            .attr("data-addonbind");
          var temarFl = alltmFl + "~~1";
          tempArr[incvl] = temarFl;
          allValEmpty = "No";
          incvl++;
        }
      });

    if (minmaxVld === "Yes") {
      if (parseInt(addonMax) < parseInt(incvl) && parseInt(incvl) > 0) {
        var errorTxt = "Maximum of selected item is " + addonMax + " only.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }

      if (parseInt(addonMin) > parseInt(incvl) && parseInt(incvl) > 0) {
        var errorTxt = "Please select minimum " + addonMin + " item.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }
    }

    var productAddons = this.state.product_addons;
    // console.log("addonSingleCart", productAddons);

    if (productAddons[indKey] === "" || productAddons[indKey] === undefined) {
      productAddons[indKey] = [];
    }
    if (allValEmpty == "No") {
      productAddons[indKey][subKey] = tempArr;
    } else {
      productAddons[indKey][subKey] = Array();
    }

    this.setState(
      { product_addons: productAddons },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }
  addonPlusMinusCart(indFlg, index3, index6, type, minPax) {
    // console.log("indFlgindFlg", indFlg, index3, index6, type, minPax);
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    var qty_input = $("#" + mainDivId)
      .find(".addon_qty_input")
      .val();
    var minPaxVal = minPax !== "" ? parseInt(minPax) : 0;
    if (type === "remove") {
      $("#" + mainDivId)
        .find(".addon_qty_input")
        .val(0);
      qty_input = 1;
      this.addonDoneCart(indFlg, index3, index6);
    } else if (type === "minus") {
      qty_input = parseInt(qty_input);
      if (qty_input <= minPaxVal) {
        $("#" + mainDivId)
          .find(".addon_qty_input")
          .val(0);
        this.addonDoneCart(indFlg, index3, index6);
      } else if (qty_input > 0) {
        qty_input = qty_input - 1;
        $("#" + mainDivId)
          .find(".addon_qty_input")
          .val(qty_input);
      }
    } else {
      qty_input =
        parseInt(qty_input) < minPaxVal ? minPaxVal : parseInt(qty_input) + 1;

      $("#" + mainDivId)
        .find(".addon_qty_input")
        .val(qty_input);
    }
    var count_sp_text = qty_input;
    $("#" + mainDivId)
      .find(".addon_qty_lable")
      .html(count_sp_text);
    var count_sp_text_x = "X" + qty_input;
    $("#" + mainDivId)
      .find(".qty_count_sp")
      .html(count_sp_text_x);
  }
  addonSubAddtoCart(indFlg, index3, index6) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    $("#" + mainDivId)
      .find(".add_tocart_div")
      .hide();
      $("#" + mainDivId)
        .find(".addon_qty_input")
        .val(1);
    $("#" + mainDivId)
      .find(".plue_muns_div")
      .show();
  }

  getQtyValue(indFlg, index3, index6, minPax) {
    var productAddons = this.state.product_addons;
    var selecteAddonsMain = Array();
    var selecteAddonSub = Array();
    /*var qtyValue = (minPax !== '') ? parseInt(minPax) : 0;*/
    var qtyValue = 0;
    if (Object.keys(productAddons).length > 0) {
      var indKey = "Index_" + indFlg;
      selecteAddonsMain = !(indKey in productAddons)
        ? Array()
        : productAddons[indKey];
      if (Object.keys(selecteAddonsMain).length > 0) {
        var subKey = "addon_" + index3;
        selecteAddonSub = !(subKey in selecteAddonsMain)
          ? Array()
          : selecteAddonsMain[subKey];
      }
    }

    if (Object.keys(selecteAddonSub).length > 0) {
      var ExpVarbl = selecteAddonSub[index6];
      var mytemparr = ExpVarbl.split("~~");
      qtyValue = mytemparr[7];
    }

    return qtyValue;
  }

  addonDoneCart(indFlg, index3, index6) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    var indKey = "Index_" + indFlg;
    var subKey = "addon_" + index3;

    var liIdtxt = "addonsLi-" + indFlg + "-" + index3;

    $("#" + liIdtxt)
      .find(".pkgerror_txt")
      .hide();
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_poptop_header")
      .find("small")
      .removeClass("apply-red");
    var addonMin = $("#" + liIdtxt).attr("data-addonmin");
    var addonMax = $("#" + liIdtxt).attr("data-addonmax");
    var minmaxVld = "Yes";
    if (parseInt(addonMin) === 0 && parseInt(addonMax) === 0) {
      minmaxVld = "No";
    }

    var tempArr = Array();
    var allValEmpty = "yes";
    var itmCount = 0;
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_qty_input")
      .each(function(newInt) {
        var alltmFl = $(this)
          .closest(".plue_muns_div")
          .attr("data-addonbind");
        var qtyInt = $(this).val();
        var temarFl = alltmFl + "~~" + qtyInt;
        tempArr[newInt] = temarFl;
        if (parseInt(qtyInt) > 0) {
          allValEmpty = "No";
          itmCount++;
        }
      });

    if (minmaxVld === "Yes") {
      if (parseInt(addonMax) < parseInt(itmCount) && parseInt(itmCount) > 0) {
        var errorTxt = "Maximum of selected item is " + addonMax + " only.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }

      if (parseInt(addonMin) > parseInt(itmCount) && parseInt(itmCount) > 0) {
        var errorTxt = "Please select minimum " + addonMin + " item.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }
    }
    // console.log("tempArrtempArr", tempArr);
    var productAddons = this.state.product_addons;
    // console.log("addonDoneCart", indKey, subKey, productAddons);
    if (productAddons[indKey] === "" || productAddons[indKey] === undefined) {
      productAddons[indKey] = [];
    }

    if (allValEmpty == "No") {
      productAddons[indKey][subKey] = tempArr;
    } else {
      productAddons[indKey][subKey] = Array();
    } 

    this.setState(
      { product_addons: productAddons },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );

    var qty_input = $("#" + mainDivId)
      .find(".addon_qty_input")
      .val();

    $("#" + mainDivId)
      .find(".plue_muns_div")
      .hide();
    if (parseInt(qty_input) > 0) {
      $("#" + mainDivId)
        .find(".add_tocart_div")
        .find(".qty_count_sp, .delete-act-icon")
        .show();
    } else {
      $("#" + mainDivId)
        .find(".add_tocart_div")
        .find(".qty_count_sp, .delete-act-icon")
        .hide();
    }
    $("#" + mainDivId)
      .find(".add_tocart_div")
      .show();
  }
  handleUpdateSetup(indFlg, indexInr, type) {
    var deliveryArr = Array();
    var buffetArr = Array();
    $("#setup_" + indFlg)
      .find(".delivery_setup_error")
      .hide();
    $("#setup_" + indFlg)
      .find(".buffet_setup_error")
      .hide();
    if (type === "delivery") {
      var incvl = 0;
      $("#setup_" + indFlg)
        .find(".additionals_deliverylist")
        .find(".setup_listing_value")
        .each(function() {
          if ($(this).is(":checked")) {
            deliveryArr[incvl] = $(this)
              .closest(".setup_li")
              .attr("data-setupdet");
            incvl++;
          }
        });
    } else if (type === "buffet") {
      var incvl = 0;
      $("#bufsetup_" + indFlg)
        .find(".buffet_listing_div")
        .find(".setup_listing_value")
        .each(function(dlvy) {
          if ($(this).is(":checked")) {
            buffetArr[incvl] = $(this)
              .closest(".setup_li")
              .attr("data-setupdet");
            incvl++;
          }
        });
    }
    var indKey = "Index_" + indFlg;
    var subKey1 = "delivery";
    var subKey2 = "buffet";
    var sateProductSetup = this.state.product_setup;
    sateProductSetup[indKey] = [];
    sateProductSetup[indKey][subKey1] = deliveryArr;
    sateProductSetup[indKey][subKey2] = buffetArr;

    this.setState(
      { product_setup: sateProductSetup },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  handleUpdateOtherSetup(indFlg, indexInr, type) {
    $("#setup_" + indFlg)
      .find(".delivery_setup_error")
      .hide();
    $("#setup_" + indFlg)
      .find(".buffet_setup_error")
      .hide();
    if (type === "self_collection") {
      var selfcollectionArr = Array();
      var incvl = 0;
      $("#selfcollectionsetup_" + indFlg)
        .find(".self_collection_listing_div")
        .find(".setup_listing_value")
        .each(function() {
          if ($(this).is(":checked")) {
            selfcollectionArr[incvl] = $(this)
              .closest(".setup_li")
              .attr("data-setupdet");
            incvl++;
          }
        });
      var sateProductSetup = this.state.product_setup_selfcollecdction;
      sateProductSetup = selfcollectionArr;

      this.setState(
        { product_setup_selfcollecdction: sateProductSetup },
        function() {
          this.calculatedOverAllAmount(indFlg);
        }.bind(this)
      );
    }
  }

  handleUpdateCurrentSetup(settupType) {
    this.setState({ settupType: settupType }, function() {
      $(".buffetSetupError, .deliverySetupError").hide();
    });
  }

  /*Modifier value list*/
  modfierValuelistMain(index,cindex,mindex,product_details ,component,modifier_value_image_source) {

    let qty_val = $(".indvcombo-" + component.menu_set_component_id + "-" + product_details.product_primary_id).find(".combo_qtycount").attr("data-qtyval");

  //  console.log(qty_val,'qty_val');
    qty_val = (qty_val == undefined || qty_val == '')?1:qty_val;

		   let qtyarr = [];
		   for(let i=1;i<=qty_val;i++) {
				qtyarr.push(i);
       }

    const html = qtyarr.map((qtyval, qtyindex) => 
          this.modfierValuelistMainLoop(index,cindex,mindex,qtyindex,product_details ,component,modifier_value_image_source)
        );
 
    return html;

  }

  modfierValuelistMainLoop(index,cindex,mindex,qtyindex,product_details ,component,modifier_value_image_source) {

      let display_val = 'block';

      let display_cur_val =  $(".indvcombo-" + component.menu_set_component_id + "-" + product_details.product_primary_id).find(".show_details").css('display');

      if(display_cur_val !== 'none'&& display_cur_val !=  undefined) {
        display_val = 'none';
      }

        const html = product_details.modifiers.map((modifiers, rindex) => {
            return (<div
              className="form-group package_checkitem mdfr_list_divlcs"
              key={`${index}${cindex}${rindex}`}
              data-invcomboprice={product_details.product_price}
              id={"mdf-" + cindex + "-" + mindex + qtyindex}
              data-mdfmin={modifiers.pro_modifier_min_select}
              data-mdfmax={modifiers.pro_modifier_max_select}
              data-mdfmain={this.proMdfmainData(modifiers)}
              style={{ display: display_val }}
            >
              <p>
                <i>{stripslashes(modifiers.pro_modifier_name)}</i>
                <br />
              (Choice of{" "}{modifiers.pro_modifier_min_select}{" "} -{" "}{modifiers.pro_modifier_max_select}{" "}  of the followings)
              </p>
              <div className="pkgerror_txt" style={{ display: "none" }}> Please select 1 more item(s)!</div>

              {this.modfierValuelistSub(modifiers, index, cindex, product_details, component, modifier_value_image_source)}

            </div>
          );
        });
    

    return html;
    
  }

  modfierValuelistSub(modifiers,index,cindex,product_details ,component,modifier_value_image_source) {

      const html =   modifiers.modifiers_values.map( (modifiers_values,mvindex) => 
            {
              return (
                <div
                  className="custom_checkbox"
                  key={`${index}${cindex}${mvindex}`}
                  id={
                    "mdfvalue-" +
                    cindex +
                    "-" +
                    index +
                    "-" +
                    mvindex
                  }
                  data-productid={
                    product_details.product_id
                  }
                  data-mdfsub={
                    modifiers_values.pro_modifier_value_id +
                    "~~" +
                    stripslashes(
                      modifiers_values.pro_modifier_value_name
                    ) +
                    "~~1~~" +
                    modifiers_values.pro_modifier_value_price +
                    "~~" +
                    modifiers.pro_modifier_id +
                    "~~" +
                    modifiers.pro_modifier_name
                  }
                >
                  <input
                    type="checkbox"
                    className={
                      "modifiers_valuecheck mod_" +
                      modifiers_values.pro_modifier_value_id +
                      " " +
                      "compo_mdf_" +
                      product_details.product_id +
                      "_" +
                      component.menu_set_component_id
                    }
                    data-prodprice={
                      product_details.product_price
                    }
                    data-mdfcombopro={
                      product_details.product_id
                    }
                    data-modifiervalueid={
                      modifiers_values.pro_modifier_value_id
                    }
                    data-componentid={
                      component.menu_set_component_id
                    }
                    data-modprice={
                      modifiers_values.pro_modifier_value_price
                    }
                    data-isindividual={
                      modifiers_values.pro_modifier_individual
                    }
                    onChange={this.checkMinMaxMod.bind(
                      this,
                      component.menu_set_component_id,
                      product_details.product_primary_id,
                      "modifier",
                      product_details.product_id,
                      modifiers_values.pro_modifier_value_id,
                      "",
                      1
                    )}
                  />{" "}
                  <span>
                    {modifiers_values.pro_modifier_value_image ? (
                      <img
                        src={
                          modifier_value_image_source +
                          "/" +
                          modifiers_values.pro_modifier_value_image
                        }
                        alt=""
                      />
                    ) : (
                      ""
                    )}
        
                    {
                      stripslashes(modifiers_values.pro_modifier_value_name)
                    }
                    <p>
                      {parseInt(
                        modifiers_values.pro_modifier_value_price
                      ) > 0
                        ? "(" +
                          "$" +
                          modifiers_values.pro_modifier_value_price +
                          ")"
                        : ""}
                    </p>
                  </span>
                </div>
              );
            }
          )
        return  html;
  }

  qtyoptionContent(product_details,component,tag_image_source) {
    return (  <div className="custom_text_logo" style={{display:'none'}}>
      <div className="text_center">
        {(
          product_details.product_tag ||
          []
        ).map((tag, index) => {
          return (
            <img
              src={
                tag_image_source +
                tag.pro_tag_image
              }
              alt={tag.pro_tag_name}
              key={index}
            />
          );
        })}
        <p className="text_center">
          <b
            className="popup_price_show"
            style={{
              display: "none"
            }}
          >
            $
            {parseInt(
              product_details.product_price
            ) > 0
              ? product_details.product_price
              : ""}
          </b>
        </p>
        <span
          className="counter combo_qtycount"
          data-qtyval="1"
        >
          <button
            onClick={this.singleProductcount.bind(
              this,
              component.menu_set_component_id,
              product_details.product_primary_id,
              "single_minus",
              product_details.product_id,
              "",
              "single_product",
              product_details.increase_quantity,
              product_details.product_max_pax
            )}
          >
            -
          </button>
          <span className="qty_val">
            1
          </span>
          <button
            onClick={this.singleProductcount.bind(
              this,
              component.menu_set_component_id,
              product_details.product_primary_id,
              "single_plus",
              product_details.product_id,
              "",
              "single_product",
              product_details.increase_quantity,
              product_details.product_max_pax
            )}
          >
            +
          </button>
        </span>
        <span
          className={
            "error " +
            product_details.product_id +
            "_" +
            "count_exist_error"
          }
          style={{
            display: "none"
          }}
        >
          Sorry!. maximum of
          selected item is{" "}
          {
            product_details.product_max_pax
          }{" "}
          only.
        </span>
      </div>
    </div>
  );
  }

  qtyoptionContentForModifier(product_details,component) {
    return ( 
     <div className="custom_text_logo" style={{display:'none'}}>
      <div className="text_center">
        <p className="text_center">
          <b className="popup_price_show" style={{display: "none"}}>
            ${parseInt( product_details.product_price) > 0 ? product_details.product_price: ""}
          </b>
        </p>
        <span className="counter combo_qtycount" data-qtyval="1">
          <button
              onClick={this.singleProductcountForModifier.bind(
                this,
                component.menu_set_component_id,
                product_details.product_primary_id,
                "single_minus",
                product_details.product_id,
                "",
                "single_product",
                product_details.increase_quantity,
                product_details.product_max_pax
              )}
            >
              -
            </button>
            <span className="qty_val"> 1 </span>
            <button
              onClick={this.singleProductcountForModifier.bind(
                this,
                component.menu_set_component_id,
                product_details.product_primary_id,
                "single_plus",
                product_details.product_id,
                "",
                "single_product",
                product_details.increase_quantity,
                product_details.product_max_pax
              )}
            >
              +
            </button>
        </span>

        <span className={ "error " + product_details.product_id + "_" + "count_exist_error" } style={{ display: "none" }}>
          Sorry!. maximum of selected item is{" "}{ product_details.product_max_pax }{" "}only.
        </span>
      </div>
    </div>
  );
  }

  render() {
    let productData,
      staticblockData = [];
    let tag_image_source,
      modifier_value_image_source = "";
    let product_gallery_image_source,
      image_source,
      product_pdf_source = "";
    let flavour = [];
    let temp = "";
    let productTag = "";
    if (this.props.categoryState.productsdetail && this.props.categoryState.productsdetail.status === "ok") {
      $(".no-recrds-found").hide();
      productData = this.props.categoryState.productsdetail.result_set;
      tag_image_source = this.props.categoryState.productsdetail.common.tag_image_source;
      product_gallery_image_source = this.props.categoryState.productsdetail.common
        .product_gallery_image_source;
      product_pdf_source = this.props.categoryState.productsdetail.common.pdf_source;
      image_source = this.props.categoryState.productsdetail.common.image_source;
      modifier_value_image_source = this.props.categoryState.productsdetail.common
        .modifier_value_image_source;
      var minMaxSelect = parseInt(
        this.props.categoryState.productsdetail.result_set[0].product_apply_minmax_select
      );
      var compoTotalPrice = this.props.categoryState.productsdetail.result_set[0].product_price;
    }
    if (this.props.staticblockData) {
      staticblockData = this.props.staticblockData.result_set;
    }

    return (
      <div className="products_list">        
          <div className="product_details_popup">
            {(productData || []).map((productDetail, index) => {
              var filename = productDetail.product_pdf;
              if (filename && filename !== undefined) {
                filename = filename.split(".");
                filename = filename.slice(-1).pop();
                var image_name = filename === "pdf" ? pdf : docx;
              }

              return (
                <div key={index} className="main_product_index">
                  <div className="package_details">
                    <div className="package_details_left">
                      <Slider {...settings}>
                        {productDetail.image_gallery.length > 0 ? (
                          (productDetail.image_gallery || []).map(
                            (image, index) => {
                              return (
                                <div key={index}>
                                  <img
                                    src={
                                      product_gallery_image_source +
                                      "/" +
                                      image.pro_gallery_image
                                    }
                                    alt="product"
                                  />
                                  <h4>{ stripslashes(productDetail.product_name) }</h4>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <img src={noimage} alt="" />
                        )}
                      </Slider>
                    </div>

                    <div className="package_details_right">
                      <div className="package_details_right_inner">
                        <div className="pdr_left">
                          <h4 key={index}>{stripslashes(productDetail.product_name)}</h4>
                          <i className="product-min-pax" data-productminpax={productDetail.product_min_pax} style={{display:(productDetail.hide_min_pax_label == 1)?'none':'block'}} >
                            min pax : {productDetail.product_min_pax}
                          </i>
                          <input
                            type="hidden"
                            id="incr_compo_price"
                            name="incr_compo_price"
                            value={
                              parseInt(this.state.productTotalPrice) > 0
                                ? parseInt(this.state.productTotalPrice)
                                : parseInt(productDetail.product_price)
                            }
                          />
                          <p>
                            {/* {productDetail.product_long_description != ""
                              ? Parser(
                                  stripslashes(
                                    productDetail.product_long_description
                                  )
                                )
                              : Parser(
                                  stripslashes(
                                    productDetail.product_short_description
                                  )
                                )} */}

                            {productDetail.product_long_description !== null && productDetail.product_long_description != ""
                             && Parser(
                                  stripslashes(
                                    productDetail.product_long_description
                                  )
                                )
                              }

                            {productDetail.product_long_description === "" && productDetail.product_short_description != ""
                             && Parser(
                                  stripslashes(
                                    productDetail.product_short_description
                                  )
                                )
                              }

                          </p>

                          {productDetail.product_pdf !== "" &&
                          productDetail.product_pdf !== null ? (
                            <div>
                              <div className="package_pdfico">
                                <img src={image_name} alt="file" />
                                <a
                                  href={
                                    product_pdf_source +
                                    "/" +
                                    productDetail.product_pdf
                                  }
                                  target="_blank"
                                >
                                  Download File
                                </a>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="pdr_right">
                          {productDetail.hide_min_pax_label != 1 &&
                          <h5>Total Price</h5>
                        } 
                          <h4 id="show_total_proice">
                            {parseInt(this.state.productGrandTotal) > 0
                              ? showPriceValue(this.state.productGrandTotal)
                              : showPriceValue(
                                  productDetail.product_min_pax *
                                    productDetail.product_price
                                )}
                          </h4>
                           <div className="qty_bx counter">
                            <span className="qty_minus" onClick={this.updateProductPax.bind(
                                this,
                                index,
                                "minus",
                                this.state.selectpaxcount <
                                  productDetail.product_min_pax
                                  ? productDetail.product_min_pax
                                  : this.state.selectpaxcount,
                                productDetail.product_min_pax
                              )}
                            >
                              -
                            </span>

                            <label className="combo-input">
                              {this.state.selectpaxcount <
                              productDetail.product_min_pax
                                ? productDetail.product_min_pax
                                : this.state.selectpaxcount}
                            </label>
                            <span
                              className="qty_plus"
                              onClick={this.updateProductPax.bind(
                                this,
                                index,
                                "plus",
                                this.state.selectpaxcount <
                                  productDetail.product_min_pax
                                  ? productDetail.product_min_pax
                                  : this.state.selectpaxcount,
                                productDetail.product_min_pax
                              )}
                            >
                              +
                            </span>
                          </div>
                          
                          {(typeof productDetail.product_tag !== 'undefined' && productDetail.product_tag.length > 0) &&
                          <div className="flavours">
                            {(productDetail.product_tag || []).map(
                              (tag, index) => {
                                return (
                                  <img
                                    src={tag_image_source + tag.pro_tag_image}
                                    alt={tag.pro_tag_name}
                                    key={index}
                                  />
                                );
                              }
                            )}
                          </div>
                         }
                        </div>
                        <div
                          className="step-buttons"
                          style={{ display: "none" }}
                        >
                          <span></span>
                          <ul className="clear_ul">
                            {(productDetail.set_menu_component || []).map(
                              (comp, index) => {
                                var serial_no = index + 1;
                                var selected_values_array = 0;
                                if (comp.menu_component_min_select > 0) {
                                  var classes = "required";
                                }
                                return (
                                  <li
                                    key={index}
                                    id={comp.menu_set_component_id}
                                    data-selected={selected_values_array}
                                  >
                                    <a
                                      href={"#" + serial_no + "s"}
                                      className={"stepbutton_status " + classes}
                                      id={"step_" + comp.menu_set_component_id}
                                    >
                                      {serial_no}
                                    </a>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="product_acc_grp">
                    {(productDetail.set_menu_component || []).map(
                      (component, cindex) => {
                        var menuCmpId = component.menu_component_id;
                        return (
                          <div
                            className="accordion"
                            key={cindex}
                            id={`${cindex + 1}s`}
                          >
                            <div className="accordion_title active">
                              {" "}
                              {component.menu_set_component_name}{" "}
                              {component.menu_component_min_select > 0 && (
                                <span>
                                  Minimum Select{" "}
                                  {component.menu_component_min_select}{" "}
                                </span>
                              )}
                            </div>
                            <div
                              className="accordion_body main_combo_div"
                              data-packageminlimit={
                                component.menu_component_min_select
                              }
                              data-packagemaxlimit={
                                component.menu_component_max_select
                              }

                              data-componentapplyprice={
                                component.menu_component_apply_price
                              }

                              id={"product_" + component.menu_set_component_id}
                              data-id={component.menu_set_component_id}
                            >
                              <div className="accordion_body_inner">
                                <div className="pkgerror_txt min_product_err" style={{ display: "none" }}>
                                  Please select 1 more item(s)!
                                </div>
                                <div className="product_card_desc">
                                  <div className="desc_left">
                                    {component.menu_set_description !== null && component.menu_set_description !== "" ? Parser(stripslashes(component.menu_set_description)) : ""}
                                  </div>
                                  <div className="desc_right">
                                    <ul className="clear_ul">
                                      {(component.product_details || []).map(
                                        (product_details, index) => {
                                          return (
                                            product_details.product_tag || []
                                          ).map((product_tag, index) => {
                                            const tags =
                                              flavour.indexOf(
                                                product_tag.pro_tag_image
                                              ) === -1
                                                ? flavour.push(
                                                    product_tag.pro_tag_image
                                                  )
                                                : "";
                                          });
                                        }
                                      )}
                                      {(flavour || []).map((fla, index) => {
                                        return (
                                          <li key={index}>
                                            {" "}
                                            <img
                                              src={tag_image_source + fla}
                                              alt=""
                                              key={index}
                                            />{" "}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                                <div className="product_det_cards">
                                  {(component.product_details || []).map(
                                    (product_details, mindex) => {
                                      return (
                                        <div className="det_card  compo_minmax_maindiv" key={mindex} data-mismatchpro="" >
                                          <a  href="javascript:void(0)"  className="prd_close" style={{ display: "none" }}>
                                            <img
                                              src={close_light}
                                              alt="close"
                                              onClick={this.incComboQty.bind(
                                                this,
                                                component.menu_set_component_id,
                                                product_details.product_primary_id,
                                                "cancel",
                                                product_details.product_id,
                                                "",
                                                "",
                                                1
                                              )}
                                            />
                                          </a>
                                          <div
                                            className={
                                              "det_inner individual_combo_pro indvcombo-" +
                                              menuCmpId +
                                              "-" +
                                              product_details.product_primary_id
                                            }
                                            data-customlogobanner={
                                              parseInt(
                                                product_details.custom_text_logo
                                              ) === 1
                                                ? 1
                                                : 0
                                            }

                                            data-excludepricemultiplypax={product_details.exclude_price_multiply_pax} 
                                            data-modifierapply={
                                              product_details.modifiers.length >
                                              0
                                                ? 1
                                                : 0
                                            } 

                                            data-combodata={
                                              component.menu_component_id +
                                              "~" +
                                              component.menu_component_name +
                                              "~0"
                                            }
                                            data-invcomboprice={
                                              product_details.product_price
                                            }
                                            data-extraprice=""
                                            data-extraqty="0"
                                            data-extraproductprice="0"
                                            data-proddet={
                                              product_details.product_id +
                                              "~~" +
                                              product_details.product_name +
                                              "~~" +
                                              product_details.product_sku +
                                              "~~" +
                                              product_details.product_price
                                            }
                                          >
                                            <div className="det_inner_image">
                                              {product_details.product_thumbnail !==
                                              "" ? (
                                                <img
                                                  src={
                                                    image_source +
                                                    "/" +
                                                    product_details.product_thumbnail
                                                  }
                                                  alt=""
                                                />
                                              ) : (
                                                <img src={noimage} alt="" />
                                              )}
                                            </div>
                                            <div className="det_inner_content">
                                              <h5 className="text_center">
                                                {stripslashes(product_details.product_name)}
                                              </h5>
                                              <p className="product_desc text_center">
                                                {product_details.product_short_description !== "" ? Parser(stripslashes( product_details.product_short_description)): ""}
                                              </p>

                                              {product_details.modifiers.length > 0 &&

                                                this.modfierValuelistMain(index,cindex,mindex,product_details ,component,modifier_value_image_source)

                                              }

                                              {(product_details.product_quantity_inc == 1 && product_details.modifiers.length > 0) &&
                                                       this.qtyoptionContentForModifier(product_details,component)
                                               }
                                                {(product_details.product_quantity_inc == 1 && product_details.modifiers.length == 0) &&
                                                       this.qtyoptionContent(product_details,component,tag_image_source)
                                               }

                                                {product_details.modifiers.length > 0 &&

                                                       <div className="text_center modifier-btn-grp" style={{display:'none'}}>
                                                         <a
                                                           href="javascript:void(0)"
                                                           className="btn btn_green btn_sm mdf-done"
                                                           data-id={"indvcombo-" +menuCmpId +"-" +product_details.product_primary_id} 
                                                           id={"done-indvcombo-" + menuCmpId +"-" +product_details.product_primary_id } 

                                                           onClick={this.checkMinSelect.bind(
                                                             this,
                                                             menuCmpId,
                                                             product_details.product_primary_id,
                                                             "modifier",
                                                             product_details.product_id
                                                           )}
                                                         >
                                                           Done
                                                         </a>
                                                         <a
                                                           href="javascript:void(0)"
                                                           className="btn btn_brown btn_sm mdf-cancel"
                                                           onClick={this.incComboQty.bind(
                                                             this,
                                                             menuCmpId,
                                                             product_details.product_primary_id,
                                                             "cancel",
                                                             product_details.product_id,
                                                             "",
                                                             "",
                                                             1
                                                           )}
                                                         >
                                                           cancel
                                                         </a>
                                                       </div>
                                                } 

                                              {parseInt(
                                                product_details.custom_text_logo
                                              ) === 1 ? (
                                                <div
                                                  className="custom_logo_banner"
                                                  style={{ display: "none" }}
                                                >
                                                  <div className="form-group">
                                                    <div className="input_field">
                                                      <textarea
                                                        className="form-control"
                                                        placeholder="Enter your custom title here..."
                                                        maxLength="15"
                                                        name="custom_banner_text"
                                                        id="custom_banner_text"
                                                        onChange={this.handlecustomLogoChange.bind(
                                                          this,
                                                          product_details.product_id,
                                                          menuCmpId
                                                        )}
                                                      ></textarea>
                                                      <span className="input_desc">
                                                        Maximum 15 characters
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="input-file-sec">
                                                    <input
                                                      type="file"
                                                      name="custom_logo_banner"
                                                      className=""
                                                      id="upload-file"
                                                      onChange={this.handlecustomLogoChange.bind(
                                                        this,
                                                        product_details.product_id,
                                                        menuCmpId
                                                      )}
                                                    />
                                                    <span className="file-name">
                                                      Your Logo
                                                    </span>
                                                    <a
                                                      href="javascript:void(0);"
                                                      className="btn btn_dark upload-btn"
                                                    >
                                                      Browse Logo
                                                    </a>
                                                  </div>
                                                  <div className="file_details">
                                                    <span
                                                      className={
                                                        "imagename-" +
                                                        product_details.product_id +
                                                        "-" +
                                                        menuCmpId
                                                      }
                                                    >
                                                      {" "}
                                                    </span>
                                                    <a
                                                      href="javascript:void(0)"
                                                      className={
                                                        "delete_image-" +
                                                        product_details.product_id +
                                                        "-" +
                                                        menuCmpId
                                                      }
                                                      style={{
                                                        display: "none"
                                                      }}
                                                    >
                                                      <img
                                                        src={close}
                                                        alt="close"
                                                        onClick={this.handleunlinkImage.bind(
                                                          this,
                                                          product_details.product_id,
                                                          menuCmpId
                                                        )}
                                                      />
                                                    </a>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}

                                              <div
                                                className="text_center det-wrap custom_checkbox"
                                                id={
                                                  "mdf-" + cindex + "-" + mindex
                                                }
                                                data-productid={
                                                  product_details.product_id
                                                }
                                                data-mdfsub={
                                                  "simple" +
                                                  "~~" +
                                                  product_details.product_id +
                                                  "~~1~~" +
                                                  product_details.product_price
                                                }
                                              >
                                                <p className="text_center">
                                                  <b className="popup_price" style={{ display: "none" }} >
                                                  {parseFloat( product_details.product_price) > 0 ? showPriceValue( product_details.product_price ) + " / pax": ""}
                                                  </b>
                                                </p>
                                                <a
                                                  href="javascript:void(0)"
                                                  className="btn btn_green btn_sm show_details"
                                                  onClick={this.incComboQty.bind(
                                                    this,
                                                    menuCmpId,
                                                    product_details.product_primary_id,
                                                    product_details.modifiers
                                                      .length > 0
                                                      ? "extraModifier"
                                                      : "simple",
                                                    product_details.product_id,
                                                    "",
                                                    product_details.product_quantity_inc ===
                                                      1
                                                      ? 1
                                                      : 0,
                                                    1
                                                  )}
                                                  data-modifierapply={
                                                    product_details.modifiers
                                                      .length > 0
                                                      ? 1
                                                      : 0
                                                  }
                                                  data-customproduct={
                                                    product_details.product_quantity_inc ===
                                                    1
                                                      ? 1
                                                      : 0
                                                  }
                                                >
                                                  <label
                                                    type="hidden"
                                                    disabled=""
                                                    data-qtyval=""
                                                    className="combo-input-label combo-input combolst_qty_value"
                                                  ></label>{" "}
                                                  Select
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  {(Object.keys(productDetail.delivery).length > 0 ||
                    Object.keys(productDetail.buffet).length > 0) && (
                    <div className="additionals additionals_ex">
                      <h5>
                        <span>Setup</span>
                        <small>(Choose your buffet setup)</small>
                      </h5>
                      <div
                        className="pkgerror_txt min_product_err deliverySetupError"
                        style={{ fontWeight: "bold", display: "none" }}
                      >
                        Please select your delivery setup
                      </div>
                      <div
                        className="pkgerror_txt min_product_err buffetSetupError"
                        style={{ fontWeight: "bold", display: "none" }}
                      >
                        Please select your buffet setup
                      </div>
                      <div className="additionals_setuplist">
                        {Object.keys(productDetail.delivery).length > 0 ? (
                          <li id={"setup_" + index}>
                            <div className="custom_radio">
                              <input
                                type="radio"
                                name="settup_details"
                                value="delivery"
                                defaultChecked={
                                  this.state.settupType === "delivery"
                                    ? true
                                    : false
                                }
                                onChange={this.handleUpdateCurrentSetup.bind(
                                  this,
                                  "delivery"
                                )}
                              />{" "}
                              <span>Delivery</span>
                            </div>
                            {this.state.settupType === "delivery" && (
                              <ul className="additionals-deliverylist additionals_deliverylist">
                                {this.getDeliverysetup(
                                  index,
                                  productDetail.delivery
                                )}
                              </ul>
                            )}
                          </li>
                        ) : (
                          ""
                        )}

                        {Object.keys(productDetail.buffet).length > 0 ? (
                          <li id={"bufsetup_" + index}>
                            <div className="custom_radio">
                              <input
                                type="radio"
                                name="settup_details"
                                value="buffet"
                                defaultChecked={
                                  this.state.settupType === "buffet"
                                    ? true
                                    : false
                                }
                                onChange={this.handleUpdateCurrentSetup.bind(
                                  this,
                                  "buffet"
                                )}
                              />{" "}
                              <span>Buffet</span>
                            </div>
                            {this.state.settupType === "buffet" && (
                              <ul className="additionals-deliverylist buffet_listing_div">
                                {this.getBuffetsetup(
                                  index,
                                  productDetail.buffet
                                )}
                              </ul>
                            )}
                          </li>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}

                  {Object.keys(productDetail.self_collection).length > 0 && (
                    <div className="additionals additionals_ex">
                      <div className="row">
                        <div
                          className="col-lg-12"
                          id={"selfcollectionsetup_" + index}
                        >
                          <h5>Self Collection</h5>

                          <ul className="additionals-deliverylist self_collection_listing_div">
                            {this.getSelfCollectionsetup(
                              index,
                              productDetail.self_collection
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {Object.keys(productDetail.equipment).length > 0 && (
                    <div className="additionals additionals_ex">
                      <div className="row">
                        <div
                          className="col-lg-12"
                          id={"equipmentsetup_" + index}
                        >
                          <h5>Equipment</h5>

                          <ul className="additionals-deliverylist additionals_equipmentlist equipment_listing_div">
                            {this.getEquipmentsetup(
                              index,
                              productDetail.equipment
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {Object.keys(productDetail.addons_setup).length > 0 ? (
                    <div className="additionals additionals_ex">
                      <h5>
                        <span>Addons</span>
                        <small>(Choose your addons )</small>
                      </h5>
                      <ul className="addon_checklist clearfix">
                        {this.productAddonsSetup(
                          index,
                          productDetail.addons_setup
                        )}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}

        <div className="additionals">
        <div className="row">
           
           
             
                {(staticblockData || []).map((staticblock, index) => {
                  return (
                    <div key={index}>
                      {staticblock.staticblocks_slug === "popup-disclaimer"
                        ? Parser(
                            stripslashes(staticblock.staticblocks_description)
                          )
                        : ""}
                    </div>
                  );
                })}
               

            <div className="col-sm-12 instruction">
                  <h5>Add special instruction</h5>
                  <div className="form-group">
                    <div className="input_field">
                      <textarea
                        className="form-control spl_instruction"
                        name="spl_instruction"
                        value={this.state.specialInstruction}
                        onChange={this.handleSpecialInstruction.bind(this)}
                      ></textarea>
                    </div>
                  </div>
            <input
              type="hidden"
              id="set_menu_component_type"
              name="set_menu_component_type"
              value={minMaxSelect}
            />
            <input
              type="hidden"
              id="product_unitprice"
              name="product_unitprice"
              value={compoTotalPrice}
            />

            <Link
              to="#"
              className="btn btn_green btn_upp btn_fill btn_lg add_to_cart"
              onClick={this.addToCartCombo.bind(this, productData, "done", "0")}
            >
              Add To Cart
            </Link>

             </div>

		</div>
 </div> 
          
          </div>
      </div>
    );
  }
}
export default Productdetails;
