import React from "react";
import ReactTooltip from "react-tooltip";
import cookie from "react-cookies";
import $ from "jquery";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
// import InputMask from "react-input-mask";
import crossImg from "../../../common/images/cross1.png";
// import delete_white from "../../../common/images/delete-white.png";
// import warning from "../../../common/images/warning.png";
import moment from "moment";
import noimage from "../../../common/images/no-img-product.png";

import {
  stripslashes,
  getReferenceID,
  showPriceValue,
  getCalculatedAmount,
  getGstValue,
  getSurchargesplitup,
  showLoader,
  timeToConv12
} from "../../Helpers/SettingHelper";
import {
  appId,
  apiUrlCtrng,
  cateringId,
  apiUrl,
  CountryTxt
} from "../../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  DESTROY_CART_DETAIL,
  GET_STATIC_BLOCK,
  GET_AVAILABLE_TIME,
  GET_AVAILABLE_DATE,
  GET_ACTIVITYCOUNT
} from "../../../actions";

var Parser = require("html-react-parser");
var qs = require("qs");

class Ordersummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartDetailsMain: [],      
    };

    this.loadCartDet();
  }

  componentDidMount() {
    this.props.getCartdatedata({
      dateFlagkey: "START",
      outletId: cookie.load("cateringOutletId")
    });
    cookie.remove("Success", "");

    const inputKeys = ["promotionwithoutuqc", "reward", "testtxt"];
    this.props.getActivityCount(JSON.stringify(inputKeys));

    var current_object = this;

    $(document).on("click", ".cart_qty_plus,.cart_qty_minus", function() {
      var roots = $(this).closest(".products_info_item");
      var amount = $(this)
        .parent()
        .attr("data-unitprice");

      var product_quantity = roots.find(".product_quantity").text();
      var prd_qty;
      
       var Minqty = roots.find(".cart-counter").attr("data-minqty");

    /*  if ($(this).data("type") === "plus") {
        prd_qty = parseInt(product_quantity) + 1;
      } else {
        prd_qty = parseInt(product_quantity) - 1;
      }
	*/

		let pax_increase = 10;

		if ($(this).data("type") === "minus") {
		   prd_qty = parseInt(product_quantity) > Minqty ? parseInt(product_quantity) - pax_increase : 0;
		  if (prd_qty >= pax_increase) {
			prd_qty = parseInt(product_quantity) - pax_increase;
		  }
		} else {
		  prd_qty = parseInt(product_quantity) + pax_increase;
		}

      var minqty = $(this)
        .parent()
        .attr("data-minqty");
      let allowqty = 1;
      if (minqty !== "") {
        if (minqty > prd_qty) {
          allowqty = 0;
        }
      }
      if (allowqty === 1) {
        var total_price_update = parseFloat(amount) * parseInt(prd_qty);
        var productId = roots.find(".cart-counter").attr("data-productid");
        var cartItemId = roots.find(".cart-counter").attr("data-itemid");
       
        if (Minqty <= prd_qty) {
          roots.find(".product_quantity").text(parseInt(prd_qty));
          var postObject = {};
          postObject = {
            app_id: appId,
            cart_item_id: cartItemId,
            product_id: productId,
            product_qty: prd_qty,
            cartAction: "update"
          };

          if (typeof cookie.load("UserId") === "undefined") {
            postObject["reference_id"] = getReferenceID();
          } else {
            postObject["customer_id"] = cookie.load("UserId");
          }
          axios
            .post(apiUrlCtrng + "cart/update", qs.stringify(postObject))
            .then(res => {
              if (res.data.status === "ok") {
                $(".total_cart_price").text(
                  showPriceValue(
                    res.data.contents.cart_details.cart_grand_total
                  )
                );
                $(".products_total_amount")
                  .find("span")
                  .text(
                    showPriceValue(
                      res.data.contents.cart_details.cart_grand_total
                    )
                  );
                swal("Success!", res.data.message, "success");
                current_object.loadCartDet();
                // window.location.reload(true);
              } else if (res.data.status === "error") {
                swal("Error!", res.data.message, "error");
              }
            });

          roots
            .find(".cart_indvl_price")
            .text(showPriceValue(parseFloat(total_price_update)));
        }
      }
    });
  }

  componentWillMount() {}
  componentDidUpdate() {
    // if (!cookie.load("UserId")) {
    //   const { history } = this.props;
    //   history.push("/");
    // }
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.globalSettings !== nextProps.settingsArr) {
      this.setState({ globalSettings: nextProps.settingsArr });
    }

    if (
      this.props.cartdate !== nextProps.cartdate &&
      typeof nextProps.cartdate != "undefined"
    ) {
      if (nextProps.cartdate.status === "success") {
        let dateArrList = [];
        let commonArrLt = [];
        let timeslotArrLt = [];
        let interval_time = 15;
        let mindate = new Date();
        let maxdate,
          orderDate = "";

        dateArrList = nextProps.cartdate.result_set;
        commonArrLt = nextProps.cartdate.common_data;
        timeslotArrLt = nextProps.cartdate.timeslot_data;
        interval_time = nextProps.cartdate.interval_time;
        mindate = new Date(nextProps.cartdate.result_set[0].fulldate);
        orderDate = new Date(nextProps.cartdate.result_set[0].fulldate);
        let data_max = Object.keys(dateArrList).length;
        let ind = parseInt(data_max) - parseInt(1);
        maxdate = new Date(nextProps.cartdate.result_set[ind].fulldate);
        this.setState({ available_datelist: dateArrList });
        this.setState({ common_datalist: commonArrLt });
        this.setState({ time_slotlist: timeslotArrLt });
        this.setState({ interval_time_sat: interval_time });
        var catergDate = [];
        var disable_dates = [];

        if (catergDate === "" && Object.keys(dateArrList).length > 0) {
          dateArrList.map((date, index) => {
            if (date.isclose === "No") {
              catergDate.push(new Date(date.fulldate));
              return false;
            } else {
              disable_dates.push(new Date(date.fulldate));
              return false;
            }
          });
        }

        var errorHtml = "";
        if (Object.keys(dateArrList).length > 0) {
          this.setState({ date_errorHtml: "" });
        } else {
          this.setState({ date_errorHtml: errorHtml });
        }

        this.setState({ catDateLoading: true });

        this.setState(
          {
            catTimeLoading: false,
            catering_date: catergDate,
            disable_dates: disable_dates,
            mindate: mindate,
            orderDate: orderDate,
            maxdate: maxdate
          },
          () => {
            this.getAvailableTime();
          }
        );
      }
    }
    /*Get time list*/

    if (typeof nextProps.carttime != "undefined") {
      this.setState({ catTimeLoading: true });

      let timeArrList = [];
      let comnTmArrLt = [];

      if (nextProps.carttime.status === "success") {
        timeArrList = nextProps.carttime.result_set;
        comnTmArrLt = nextProps.carttime.common_data;
      }

      let cateringTime =
        Object.keys(timeArrList).length > 0 ? timeArrList[0].timetext : "";
      this.setState({ timeslot_arr: timeArrList });
      this.setState({ common_timelist: comnTmArrLt });

      if (this.state.orderDate === "") {
        this.setState({ catering_time: cateringTime });
        // this.props.sateValChangefun('catering_time', cateringTime);
      }

      if (Object.keys(timeArrList).length > 0) {
        var catergTime = [];
        this.setState({ time_errorHtml: "", catergTime: catergTime });
      }
    }

    if (this.props.activitycountArr !== nextProps.activitycountArr) {
      this.setState({
        promotion_count: nextProps.activitycountArr.promotionwithoutuqc
      });
    }

  }

  getSurCharge() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }
    axios
      .get(
        apiUrlCtrng + "orders/getSurCharge?app_id=" + appId + customerParam
      )
      .then(res => {
        if (res.data.status === "ok") {
          var surcharge = 0;
          if (
            typeof res.data.total_charge !== "undefined" &&
            res.data.total_charge > 0
          ) {
            surcharge = res.data.total_charge;
          }
          this.setState({ surcharge: surcharge, surchargeLog: res.data.logs });
        }
      });
  }

  /* Validate Int Value */
  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  getdayBasedTimeList() {
    var timeSltList = this.state.time_slotlist;
    var caterngDate = this.state.startDate;
    var selectedDate = new Date(caterngDate);
    var dayintt = selectedDate.getDay();
    var currentslotData = [];
    if (dayintt === 0) {
      currentslotData = timeSltList.sun;
    }
    if (dayintt === 1) {
      currentslotData = timeSltList.mon;
    }
    if (dayintt === 2) {
      currentslotData = timeSltList.tue;
    }
    if (dayintt === 3) {
      currentslotData = timeSltList.wed;
    }
    if (dayintt === 4) {
      currentslotData = timeSltList.thu;
    }
    if (dayintt === 5) {
      currentslotData = timeSltList.fri;
    }
    if (dayintt === 6) {
      currentslotData = timeSltList.sat;
    }

    return currentslotData;
  }

  getAvailableTime() {
    var timeSlotList = this.state.time_slotlist;
    var cateringDate = this.state
      .orderDate; /*
    var errorHtml = "";
    this.setState({'time_errorHtml': errorHtml});*/
    var breakTimeHtml = "";
    this.setState({ break_timeHtml: breakTimeHtml });

    /*&& this.state.selected_outletid !== ''*/
    if (Object.keys(timeSlotList).length > 0 && cateringDate !== "") {
      var timeSlotArr = this.getdayBasedTimeList();

      if (Object.keys(timeSlotArr).length > 0) {
        timeSlotArr.map(
          (timeLt, index) =>
            (breakTimeHtml +=
              "<option value='" + timeLt + "'>" + timeLt + "</option>")
        );
      }

      var breakTimeDropDown = Parser(breakTimeHtml);
      this.setState({ break_timeHtml: breakTimeDropDown });
      /*this.props.sateValChangefun('break_timeHtml', breakTimeDropDown);*/

      var time_flagkey = this.state.time_flag_key;
      var intervalTime = this.state.interval_time_sat;

      var nextTime = "";
      var prevTime = "";
      var commonTimeList = this.state.common_timelist
        ? this.state.common_timelist
        : "";
      if (
        commonTimeList !== "undefined" &&
        Object.keys(commonTimeList).length > 0
      ) {
        nextTime = commonTimeList.next_time;
        prevTime = commonTimeList.prev_time;
      }

      // this.props.getCarttimedata({
      //   time_slotdata: timeSlotArr,
      //   interval_time: intervalTime,
      //   time_flagkey: time_flagkey,
      //   next_time: nextTime,
      //   prev_time: prevTime
      // });
    }
  }

  loadCartDet() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }

    axios
      .get(apiUrlCtrng + "cart/contents?app_id=" + appId + customerParam)
      .then(res => {
        if (res.data.status === "ok") {
          if (typeof res.data.result_set === "undefined") {
          } else {
            var cartDetailsMain = res.data.result_set.cart_details;
            var cartItems = res.data.result_set.cart_items;
          }
          if (cookie.load("redirect_page")) {
            this.props.history.push("/package/checkout");
          }
        } else {
        //   this.props.history.push("/package");
        }
        if (cartDetailsMain !== undefined && cartDetailsMain !== null) {
          this.setState(
            { cartDetailsMain: cartDetailsMain, cartItems: cartItems },
            function() {
              this.makeBrkTimeList();
              this.getSurCharge();
            }.bind(this)
          );
        }
      });
  }

  handleChange = date => {
    cookie.save("order_date", moment(date).format("MM/DD/YYYY"), { path: "/" });
    $("select").val("");
    this.setState({ startDate: date }, () => {
      this.getAvailableTime();
    });
  };

  makeBrkTimeList() {
    var cartDetailsSate = this.props.categoryState.cartDetailsMain;
    var cartItems = this.props.categoryState.cartItems;
    var cartBrkTmList = [];
    if (
      cartDetailsSate !== undefined &&
      cartDetailsSate !== null &&
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.cart_breaktime_enable;
      var breaktimeCount = cartDetailsSate.cart_breaktime_count;
      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = [];
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].cart_item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = [];
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].cart_item_breaktime_indexflag,
              break_time_start: cartItems[key].cart_item_breaktime_started,
              break_time_end: cartItems[key].cart_item_breaktime_ended
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }

    this.setState({ cart_brktime_list: cartBrkTmList });
  }

  getCartDetList() {
    var cartDetailsSate = this.props.categoryState.cartDetailsMain;
    cookie.save("cartDetail", cartDetailsSate, { path: "/" });
    var cartBrktimeLst = this.state.cart_brktime_list;

    if (
      cartDetailsSate !== undefined &&
      cartDetailsSate !== null &&
      Object.keys(cartDetailsSate).length > 0 
    //   && cartBrktimeLst !== null &&
    //   cartBrktimeLst !== undefined
    ) {
    //   var breaktimeEnable = cartDetailsSate.cart_breaktime_enable;
    //   if (breaktimeEnable === "Yes" && cartBrktimeLst.length > 0) {
    //     return this.cartBrkTimeList();
    //   } else {
        return this.cartListOnly();
    //   }
    }
  }

  deleteCartItm(cart_item_id) {
    if (cart_item_id) {
      // this.props.deleteCartDetail(cart_item_id);
      var postCrtObject = {
        app_id: appId,
        cart_item_id: cart_item_id,
        cartAction: 'Delete'
      };
      if (typeof cookie.load("UserId") === "undefined") {
        postCrtObject["reference_id"] = getReferenceID();
      } else {
        postCrtObject["customer_id"] = cookie.load("UserId");
      }

      axios.post( apiUrlCtrng + "cart/delete", qs.stringify(postCrtObject)).then(res => {
        if (res.data.status === "ok") {
          this.loadCartDet();
          swal("Success!", "Nice! Products updated to your cart", "success");
        } else {
          swal("Error!", "Sorry! Products can`t update your cart", "error");
        }
      }).catch(function(error) {
        console.log(error);
        swal("Error!", "something went wrong", "error");
      });
    } else {
      swal("Error!", "You haven’t added any items yet !", "error");
    }
  }

  cartBrkTimeList() {
    ReactTooltip.rebuild();
    var cartBrktimeLst = this.state.cart_brktime_list;
    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => {
      var order_no = parseInt(indx) + 1;
      var CartItemList = "";
      cartBrk["cart_items"].map(function(item) {
        CartItemList = CartItemList + item.cart_item_id + ",";
      });
      return (
        <div
          key={indx}
          className="order_card dark"
          id={"tileSlot_" + indx}
          style={{ display: this.state.cart_display }}
        >
          <div className="order_card_head">
            Order Details {"-"} {order_no}
            {/* <Link
              onClick={this.deleteCartItm.bind(
                this,
                cartBrk["cart_items"][0]["cart_item_id"]
              )}
              to="#"
            >
              <img src={delete_white} alt="delete" />
            </Link> */}
          </div>
          <div className="order_card_body">
            <div className="products_info">
              {this.cartItemlist(cartBrk["cart_items"])}
            </div>
          </div>
        </div>
      );
    });

    return cartBrktmhtml;
  }

  getbreaktimelist(selcted) {
    var breakTimeHtml = "";
    if (this.state.timeslot_arr) {
      breakTimeHtml += "<option value=''>Select</option>";
      this.state.timeslot_arr.map((option, index) => {
        if (option.isclose === "No") {
        //   breakTimeHtml +=
        //     "<option value='" +
        //     option.timetext +
        //     "' disabled>" +
        //     option.timetext +
        //     "</option>";
        // } else {
          if (selcted === option.timetext) {
            breakTimeHtml +=
              "<option value='" +
              option.timetext +
              "' selected='selected'>" +
              option.timetext +
              "</option>";
          } else {
            breakTimeHtml +=
              "<option value='" +
              option.timetext +
              "' >" +
              option.timetext +
              "</option>";
          }
        }
      });
    }
    return breakTimeHtml;
  }

  cartListOnly() {
    var cartItems = this.props.categoryState.cartItems;
    return <div className="products_info">{this.cartItemlist(cartItems)}</div>;
  }

  cartItemlist(itemLst) {
    if (Object.keys(itemLst).length > 0) {
      const cartlsthtml = itemLst.map((cartLst, indx1) => (
        <div key={indx1}>

          <div className="products_list_image">
            {cartLst.cart_item_product_image !== "" ? (
              <img
                src={ cartLst.cart_item_product_image }
                alt={ cartLst.cart_item_product_name }
              />
              ) : (
              <img src={noimage} alt={ cartLst.cart_item_product_name } />
            )}
            {/* <img src="https://ccpl.ninjaos.com/media/dev_team/staticimages/bb3613cd866c04e5523dbe8d00d76e6b.jpg" alt="catering_summary" /> */}
            <h4>{ cartLst.cart_item_product_name }</h4>
          </div>

          {/* package div start */}
          <div className="products_info_item">
            <h3>{ cartLst.cart_item_qty } pax</h3>
            <h5>
              {" "}
              {showPriceValue(cartLst.cart_item_unit_price) } per pax
              {/* {stripslashes(cartLst.cart_item_product_name)} */}
            </h5>

            {this.loadModifierItems(
              cartLst.cart_item_type,
              cartLst.modifiers,
              cartLst.set_menu_component,
              cartLst.cart_item_qty,
              cartLst.cart_item_unit_price,
              cartLst.cart_item_id,
              cartLst.cart_item_product_id,
              cartLst.cart_item_min_qty
            )}
          </div>
          {/* package div end */}


          {/* {Object.keys(cartLst.addons_setup).length > 0 ? (
            <div className="addon">
              <h4>ADD-ONS</h4>
              {this.addonsSetupLst(cartLst)}
            </div>
          ) : (
            ""
          )} */}

          <div className="brline"></div>
          <div className="pkgslist">
            {this.checkSettup(cartLst.setup, "Buffet") && (
              <div className="pkgsli_row row">
                <div className="col-sm-8 col-7">
                  <label>Buffet:</label>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Buffet")}
                  </div>
                </div>
                <div className="col-sm-4 col-5 pkgsli_right text-right">
                  <span className="price">
                    {showPriceValue(
                      this.settuptotalPrice(cartLst.setup, "Buffet")
                    )}
                  </span>
                  <a
                    className="remove"
                    href="javascript:void(0)"
                    onClick={this.removeCartIndividual.bind(
                      this,
                      cartLst.cart_item_id,
                      "",
                      "setup",
                      "Buffet"
                    )}
                  >
                    <img src={crossImg} alt="" />
                  </a>
                </div>
              </div>
            )}
            {this.checkSettup(cartLst.setup, "Delivery") && (
              <div className="pkgsli_row row">
                <div className="col-sm-8 col-7">
                  <label>Delivery:</label>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Delivery")}
                  </div>
                </div>
                <div className="col-sm-4 col-5 pkgsli_right text-right">
                  <span className="price">
                    {showPriceValue(
                      this.settuptotalPrice(cartLst.setup, "Delivery")
                    )}
                  </span>
                  <a
                    className="remove"
                    href="javascript:void(0)"
                    onClick={this.removeCartIndividual.bind(
                      this,
                      cartLst.cart_item_id,
                      "",
                      "setup",
                      "Delivery"
                    )}
                  >
                    <img src={crossImg} alt="" />
                  </a>
                </div>
              </div>
            )}
            {this.checkSettup(cartLst.setup, "Self Collection") && (
              <div className="pkgsli_row row">
                <div className="col-sm-8 col-7">
                  <label>Self Collection:</label>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Self Collection")}
                  </div>
                </div>
                <div className="col-sm-4 col-5 pkgsli_right text-right">
                  <span className="price">
                    {showPriceValue(
                      this.settuptotalPrice(cartLst.setup, "Self Collection")
                    )}
                  </span>
                  <a
                    className="remove"
                    href="javascript:void(0)"
                    onClick={this.removeCartIndividual.bind(
                      this,
                      cartLst.cart_item_id,
                      "",
                      "setup",
                      "Self Collection"
                    )}
                  >
                   <img src={crossImg} alt="" />
                  </a>
                </div>
              </div>
            )}
            {cartLst.equipment !== "" &&
              cartLst.equipment !== undefined &&
              cartLst.equipment.length > 0 && (
                <div className="pkgsli_row row">
                  <div className="col-sm-8 col-7">
                    <label>Equipment:</label>
                    <div className="pkgslist_addition">
                      {this.loadSettup(cartLst.equipment, "Equipment")}
                    </div>
                  </div>
                  <div className="col-sm-4 col-5 pkgsli_right text-right">
                    <span className="price">
                      {showPriceValue(
                        this.settuptotalPrice(cartLst.equipment, "Equipment")
                      )}
                    </span>
                    <a
                      className="remove"
                      href="javascript:void(0)"
                      onClick={this.removeCartIndividual.bind(
                        this,
                        cartLst.cart_item_id,
                        "",
                        "equipment",
                        "equipment"
                      )}
                    >
                      <img src={crossImg} alt="" />
                    </a>
                  </div>
                </div>
              )}

              {cartLst.cart_item_breaktime_started !== "" &&
              cartLst.cart_item_breaktime_started !== undefined &&
              cartLst.cart_item_breaktime_ended !== "" &&
              cartLst.cart_item_breaktime_ended !== undefined && (
                <div className="pkgsli_row">
                  <label>Event Date & Time:</label>
                  <div className="pkgslist_addition">
                    { moment(cookie.load("order_date")).format("DD/MM/YY") + " " + timeToConv12(cartLst.cart_item_breaktime_started)+"-"+ timeToConv12(cartLst.cart_item_breaktime_ended) }
                  </div>
                </div>
              )}

            {cookie.load("catering_address_line") !== "" &&
              cookie.load("catering_address_line") !== undefined && (
                <div className="pkgsli_row">
                  <label>Venue:</label>
                  <div className="pkgslist_addition">
                    { cookie.load("catering_address_line") }
                  </div>
                </div>
              )}

            {cartLst.addons_setup !== "" &&
              cartLst.addons_setup !== undefined &&
              cartLst.addons_setup.length > 0 && (
                <div className="pkgsli_row">
                  <label>ADD-ONS:</label>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.addons_setup, "Addons")}
                  </div>
                </div>
              )}
          </div>

          {/* Object.keys(cartLst.setup).length > 0 ? this.setupDet(cartLst) : "" */}

          {cartLst.cart_item_special_notes !== ""
            ? this.specialNotes(cartLst)
            : ""}
        </div>
      ));
      return cartlsthtml;
    }
  }

  removeCartIndividual(cartItmId, cartPryId, type, setupType = "") {
    if (
      cartItmId !== "" &&
      (cartPryId !== "" ||
        type === "setup" ||
        type === "equipment" ||
        type === "addons_setup") &&
      type !== ""
    ) {
      // showLoader("cartlist_main_divcls", "class");
      var postCrtObject = {};
      postCrtObject = {
        app_id: appId,
        cart_item_id: cartItmId,
        p_id: cartPryId,
        type: type,
        setupType: setupType
      };
      if (typeof cookie.load("UserId") === "undefined") {
        postCrtObject["reference_id"] = getReferenceID();
      } else {
        postCrtObject["customer_id"] = cookie.load("UserId");
      }

      axios
        .post(
          apiUrlCtrng + "cart/delete_individual",
          qs.stringify(postCrtObject)
        )
        .then(res => {
          if (res.data.status === "ok") {
            this.loadCartDet();
            // showCustomAlert("success", "Nice! Products updated to your cart");
            swal("Success!", "Nice! Products updated to your cart", "success");
          } else {
            // showCustomAlert("error", "Sorry! Products can`t update your cart");
            swal("Error!", "Sorry! Products can`t update your cart", "error");
            /* this.handleShowAlertFun(
              "error",
              "Sorry! Products can`t update your cart"
            ); */
          }
          // hideLoader("cartlist_main_divcls", "class");
        })
        .catch(function(error) {
          console.log(error);
          swal("Error!", "something went wrong", "error");
          // showCustomAlert("error", "something went wrong");
          //  this.handleShowAlertFun("error", "something went wrong");
          // hideLoader("cartlist_main_divcls", "class");
        });
    }
  }

  loadModifierItems(
    itemType,
    modifiers,
    combo,
    cart_item_qty,
    cart_item_unit_price,
    cart_item_id,
    cart_item_product_id,
    cart_item_min_qty
  ) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html =
      '<div class="products_info_options"><div class="products_info_options_wrap">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+" + modVlPrice + ")" : "";
        temp_html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p> <p>" +
        stripslashes(  modval) +
          // newModVlPrice +
          "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var q = 0, length = comboLen; q < length; q++) {
        var comboName = combo[q]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[q]["product_details"]);

        if (combo[q]["product_details"][0]["modifiers"].length) {
          // <p><b>" + stripslashes(comboName) + ":</b> </p>
          html += "<p>" + stripslashes(comboVal) + "</p> ";
        } else {
          // <p><b>" + stripslashes(comboName) + ":</b> </p>
          html += "<p>" + stripslashes(comboVal) + "</p> ";
        }
      }
      // html +=
      //   "</div><span class='counter cart-counter' data-unitprice='" +
      //   cart_item_unit_price +
      //   "' data-productid='" +
      //   cart_item_product_id +
      //   "' data-itemid='" +
      //   cart_item_id +
      //   "' data-minqty='" +
      //   cart_item_min_qty +
      //   "'><button class='qty_minus cart_qty_minus' data-type='minus'>-</button><span class='product_quantity'>" +
      //   cart_item_qty +
      //   "</span><button class='qty_plus cart_qty_plus' data-type='plus'>+</button></span></div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          // html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
          if (combos[r].modifiers.length > 0) {
            html += this.showComboModifiersValues(combos[r]);
          }
        }
      }
      return html;
    }
    return "";
  }

  showComboModifiersValues(product_details) {
    var mod_length = product_details.modifiers.length;
    var html = " ";
    for (var m = 0, leng = mod_length; m < leng; m++) {
      var cart_mod_name = product_details.modifiers[m]["cart_modifier_name"];
      var mod_val_leng = product_details.modifiers[m].modifiers_values.length;
      html +=
        "<div class='modifier_values_list_wrap'><p>" + cart_mod_name + "</p>";
      html += "<div class='modifier_values_list'>";
      for (var v = 0, lenth = mod_val_leng; v < lenth; v++) {
        var cart_modifier_name =
          product_details.modifiers[m].modifiers_values[v].cart_modifier_name;
        var cart_modifier_price =
          product_details.modifiers[m].modifiers_values[v].cart_modifier_price;
        cart_modifier_price =
          parseInt(cart_modifier_price) > 1
            ? "(" + showPriceValue(cart_modifier_price) + ")"
            : "";
        html +=
          "<p>" + stripslashes(cart_modifier_name )+ "&nbsp;" + cart_modifier_price + "</p>";
      }
      html += "</div></div>";
    }

    return html;
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    cookie.remove("catering_categoryPryId");
    cookie.remove("catering_categoryId");
    cookie.remove("catering_categoryName");

    cookie.remove("catering_eventType");
    cookie.remove("cateringOutletId");
    cookie.remove("cateringOutletName");
    cookie.remove("cateringSelectedOutlet");
    cookie.remove("catering_postal_code");
    cookie.remove("catering_address_line");
    cookie.remove("cateringOutletAddress");
    cookie.remove("catering_floor_no");
    cookie.remove("catering_unit_no");
    cookie.remove("order_date", { path: "/" });

    cookie.remove("cateringHallId");
    cookie.remove("cateringHallName");
    cookie.remove("cateringHallPostalcode");
    cookie.remove("cateringHalladdressLine");
    cookie.remove("cateringHalladdress");
    cookie.remove("cateringHallPax");
    cookie.remove("cateringHallCharge");

    cookie.remove("cateringDate");
    cookie.remove("cateringTime");
    cookie.remove("breaktimeEnable");
    cookie.remove("breaktimeCount");
    cookie.remove("breaktimeList");

    cookie.remove("cartDetail");
    cookie.remove("cartTotalItems");
    cookie.remove("fromCkeckOutVld");
    cookie.remove("ChkOrderid");

    cookie.remove("applyCatrngPromo");
    cookie.remove("promoCodeVal");
    cookie.remove("promoIsDelivery");
    cookie.remove("promotionAmount");
    cookie.remove("promotionApplied");
    cookie.remove("promotionSource");
    cookie.remove("promotionType");
  }

  handleAddrChange() {

    if ($("#same_address").is(":checked")) {

      $(".billing_add_postalcode_error").hide();
      $(".billing_add_addressline_error").hide();

      cookie.save(
        "catering_billing_postal",
        this.state.delivery_add_postalcode, { path: "/" }
      );

      cookie.save(
        "catering_billing_addressline",
        this.state.delivery_add_addressline, { path: "/" }
      );

      cookie.save("catering_billing_floorno", this.state.delivery_add_floorno, { path: "/" });
      cookie.save("catering_billing_unitno", this.state.delivery_add_unitno, { path: "/" });
      cookie.save("same_as_del_address", 1, { path: "/" });

       this.setState({
		flag: "yes",
		billing_add_postalcode: this.state.delivery_add_postalcode,
        billing_add_addressline: this.state.delivery_add_addressline,
        billing_add_floor_no: this.state.delivery_add_floorno,
        billing_add_unitno: this.state.delivery_add_unitno });

    } else {
      this.setState({
        flag: "no",
        billing_add_postalcode: "",
        billing_add_addressline: "",
        billing_add_floor_no: "",
        billing_add_unitno: ""
      });
      cookie.remove("catering_billing_postal");
      cookie.remove("catering_billing_addressline");
      cookie.remove("catering_billing_floorno");
      cookie.remove("catering_billing_unitno");
      cookie.remove("same_as_del_address");
    }
  }

  showSuccessPage(localOrderNo, orderPrimaryId) {
    this.props.destroyCartDetail();
    this.deleteOrderCookie();
    /*this.sendMailFun(orderPrimaryId);*/
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    const { history } = this.props;
    setTimeout(function() {
      history.push("/thankyou/" + localOrderNo);
    }, 450);
  }

  acceptTerms() {
    this.setState({ acceptTerms: !this.state.acceptTerms });
    $(".terms_check_error").hide();
  }

  handleAddressChange(event) {
    if (event.target.name === "delivery_add_addressline") {
      this.setState({ delivery_add_addressline: event.target.value });
    } else if (event.target.name === "delivery_add_floorno") {
      this.setState({ delivery_add_floorno: event.target.value });
    } else if (event.target.name === "delivery_add_unitno") {
      this.setState({ delivery_add_unitno: event.target.value });
    } else if (event.target.name === "delivery_add_postalcode") {
      this.setState({ delivery_add_postalcode: event.target.value });
    } else if (event.target.name === "billing_add_postalcode") {
      if (event.target.value.length >= 6) {
        $(".billing_add_postalcode_error").hide();
        var urlShringTxt =
          apiUrl +
          "outlets/find_outlet?app_id=" +
          appId +
          "&availability_id=" +
          cateringId +
          "&postal_code=" +
          event.target.value;
        axios.get(urlShringTxt).then(res => {
          var custAddress = "";
          if (res.data.status === "ok") {
            var outltResulSet = res.data.result_set;
            if (Object.keys(outltResulSet.postal_code_information).length > 0) {
              var outletAddress =
                outltResulSet.postal_code_information.zip_buno !== ""
                  ? outltResulSet.postal_code_information.zip_buno +
                    ", " +
                    outltResulSet.postal_code_information.zip_sname
                  : outltResulSet.postal_code_information.zip_sname;
              custAddress = outletAddress + " " + CountryTxt;
            }
            this.setState({ billing_add_addressline: custAddress });
          } else {
            this.setState({ billing_add_addressline: "" });
          }
        });
      }
      this.setState({ billing_add_postalcode: event.target.value });
    } else if (event.target.name === "billing_add_addressline") {
      $(".billing_add_addressline_error").hide();
      this.setState({ billing_add_addressline: event.target.value });
    } else if (event.target.name === "billing_add_floor_no") {
      this.setState({ billing_add_floor_no: event.target.value });
    } else if (event.target.name === "billing_add_unitno") {
      this.setState({ billing_add_unitno: event.target.value });
    } else if (event.target.name === "spl_instruction") {
      this.setState({ spl_instruction: event.target.value });
    }
  }

  /* this fuction used to post order to biz panel */
  postOrder(
    paymentMode,
    validation = "No",
    captureID = "",
    payGetWayType = "",
    Paymentpop = "No"
  ) {
    var valid = true;
    $(".start_time").each(function(index) {
      if (index === 0) {
        var min = $(this).val();
      }
      if (min > $(this).val()) {
        min = $(this).val();
      }
      if (min) {
        cookie.save("orderTime", min, { path: "/" });
      }

      if ($(this).val() === "") {
        $(this).addClass("required");
        $("body, html").animate(
          {
            scrollTop: $(".order_card").offset().top - 80
          },
          300
        );
        valid = false;
      } else {
        valid = true;
        $(this).removeClass("required");
      }
    });

    $(".end_time").each(function() {
      if ($(this).val() === "") {
        $(this).addClass("required");
        $("body, html").animate(
          {
            scrollTop: $(".order_card").offset().top - 80
          },
          300
        );
        valid = false;
      } else {
        valid = true;
        $(this).removeClass("required");
      }
    });

    var delivery_add_postalcode = $("#delivery_add_postalcode").val();
    var delivery_add_addressline = $("#delivery_add_addressline").val();

    if (delivery_add_postalcode === "null") {
      $(".delivery_add_postalcode").show();
      return false;
    } else if (delivery_add_addressline === "null") {
      $(".delivery_add_addressline").show();
      return false;
    } else {
      $(".delivery_add_postalcode").hide();
      $(".delivery_add_addressline").hide();
    }
    if (paymentMode !== "initial") {
      var acceptTerms_valid, billingAddrs_valid;
      if (this.state.acceptTerms) {
        $(".terms_check_error").hide();
        acceptTerms_valid = true;
      } else {
        acceptTerms_valid = false;
        $(".terms_check_error").show();
      }

      var billingAddrsSameas = this.state.flag;
      var billingPostcode = this.state.billing_add_postalcode;
      var billingAddrss = this.state.billing_add_addressline;
      if (
        billingAddrsSameas === "no" &&
        (billingPostcode === "" || billingAddrss === "")
      ) {
        billingAddrs_valid = false;
        $(".billing_add_postalcode_error").show();
        $(".billing_add_addressline_error").show();
        $("body, html").animate(
          {
            scrollTop: $("#same_address").offset().top - 80
          },
          300
        );
      } else {
        if (billingPostcode.length !== 6) {
          billingAddrs_valid = false;
          $(".billing_add_postalcode_error").show();
          if (billingAddrss === "") {
            $(".billing_add_addressline_error").show();
          }

          $("body, html").animate(
            {
              scrollTop: $("#same_address").offset().top - 80
            },
            300
          );
        } else {
          billingAddrs_valid = true;
          $(".billing_add_postalcode_error").hide();
          $(".billing_add_addressline_error").hide();
        }
      }

      if (acceptTerms_valid === false || billingAddrs_valid === false) {
        if (billingAddrs_valid === true) {
          $("body, html").animate(
            {
              scrollTop: $(".terms_check").offset().top - 220
            },
            300
          );
        }

        return false;
      }
    }
    var finalcartItems = this.props.categoryState.cartItems;
    var products = [];
    this.props.categoryState.cartItems.map(function(item, i) {
      var addons = [];
      var addonsSetup = [];
      var equipments = [];
      var setupdata = [];
      var modifierdata = [];
      var comboData = [];
      var ComboLen = item.set_menu_component.length;
      var modifierLen = item.modifiers.length;
      if (item.cart_item_type === "Modifier" && modifierLen > 0) {
        for (var l = 0; l < modifierLen; l++) {
          var modifierVal = [];
          modifierVal.push({
            modifier_value_id:
              item.modifiers[l].modifiers_values[0].cart_modifier_id,
            modifier_value_qty:
              item.modifiers[l].modifiers_values[0].cart_modifier_qty,
            modifier_value_name:
              item.modifiers[l].modifiers_values[0].cart_modifier_name,
            modifier_value_price:
              item.modifiers[l].modifiers_values[0].cart_modifier_price
          });
          modifierdata.push({
            modifier_id: item.modifiers[l].cart_modifier_id,
            modifier_name: item.modifiers[l].cart_modifier_name,
            modifiers_values: modifierVal
          });
        }
      } else if (item.cart_item_type === "Component" && ComboLen > 0) {
        for (var m = 0; m < ComboLen; m++) {
          /* build  modifier array */
          var comborVal = [];
          var comboProductDetailslen =
            item.set_menu_component[m].product_details.length;
          if (comboProductDetailslen > 0) {
            for (
              var j = 0, lengthComboPro = comboProductDetailslen;
              j < lengthComboPro;
              j++
            ) {
              var comboModifier =
                item.set_menu_component[m].product_details[j].modifiers;
              var combomodifierdata = [];
              var comboModifierVal = [];
              if (comboModifier.length > 0) {
                for (var p = 0; p < comboModifier.length; p++) {
                  var comboModifierValss =
                    item.set_menu_component[m].product_details[j].modifiers[p]
                      .modifiers_values;

                  if (comboModifierValss.length > 0) {
                    for (var z = 0; z < comboModifierValss.length; z++) {
                      comboModifierVal.push({
                        modifier_value_id:
                          comboModifier[p].modifiers_values[z].cart_modifier_id,
                        modifier_value_qty:
                          comboModifier[p].modifiers_values[z]
                            .cart_modifier_qty,
                        modifier_value_name:
                          comboModifier[p].modifiers_values[z]
                            .cart_modifier_name,
                        modifier_value_price:
                          comboModifier[p].modifiers_values[z]
                            .cart_modifier_price
                      });
                    }
                  }

                  combomodifierdata.push({
                    modifier_id: comboModifier[p].cart_modifier_id,
                    modifier_name: comboModifier[p].cart_modifier_name,
                    modifiers_values: comboModifierVal
                  });
                }
              }
              comborVal.push({
                product_id:
                  item.set_menu_component[m].product_details[j]
                    .cart_menu_component_product_id,
                product_name:
                  item.set_menu_component[m].product_details[j]
                    .cart_menu_component_product_name,
                product_sku:
                  item.set_menu_component[m].product_details[j]
                    .cart_menu_component_product_sku,
                product_qty:
                  item.set_menu_component[m].product_details[j]
                    .cart_menu_component_product_qty,
                product_price:
                  item.set_menu_component[m].product_details[j]
                    .cart_menu_component_product_price,
                modifiers: combomodifierdata,
                product_extra_qty:
                  item.set_menu_component[m].product_details[j]
                    .cart_menu_component_product_extra_qty,
                product_extra_price:
                  item.set_menu_component[m].product_details[j]
                    .cart_menu_component_product_extra_price,
                custom_logo:
                  item.set_menu_component[m].product_details[j].custom_logo,
                custom_text:
                  item.set_menu_component[m].product_details[j].custom_text
              });
            }
          }

          comboData.push({
            menu_component_id: item.set_menu_component[m].menu_component_id,
            menu_component_name: item.set_menu_component[m].menu_component_name,
            product_details: comborVal
          });
        }
      }

      var setupLen = item.setup.length;
      if (setupLen > 0) {
        for (var l = 0; l < setupLen; l++) {
          setupdata.push({
            setup_p_id: item.setup[l].cart_setup_primary_key,
            setup_id: item.setup[l].cart_setup_id,
            setup_name: stripslashes(item.setup[l].cart_setup_name),
            setup_type: item.setup[l].cart_setup_type,
            setup_description: item.setup[l].cart_setup_description,
            setup_qty: item.setup[l].cart_setup_qty,
            setup_price: item.setup[l].cart_setup_price,
            setup_total_price: item.setup[l].cart_setup_tatalprice
          });
        }
      }

      var equipmentLen = item.equipment.length;
      if (equipmentLen > 0) {
        for (var l = 0; l < equipmentLen; l++) {
          let cartequip = item.equipment[l];
          equipments.push({
            equipment_p_id: cartequip.cart_equipment_primary_key,
            equipment_id: cartequip.cart_equipment_id,
            equipment_name: stripslashes(cartequip.cart_equipment_name),
            equipment_description: cartequip.cart_equipment_description,
            equipment_qty: cartequip.cart_equipment_qty,
            equipment_price: cartequip.cart_equipment_price,
            equipment_totalprice: cartequip.cart_equipment_totalprice
          });
        }
      }

      var addonsSetupLen = item.addons_setup.length;
      if (addonsSetupLen > 0) {
        for (var l = 0; l < addonsSetupLen; l++) {
          var tempArr = [];
          var addonsValueArr = item.addons_setup[l].addons_setup_values;
          for (var key in addonsValueArr) {
            tempArr.push({
              addon_setup_val_id: addonsValueArr[key].cart_addon_setup_val_id,
              addon_setup_val_title:
                addonsValueArr[key].cart_addon_setup_val_title,
              addon_setup_val_desc:
                addonsValueArr[key].cart_addon_setup_val_desc,
              addon_setup_val_price:
                addonsValueArr[key].cart_addon_setup_val_price,
              addon_setup_val_qty: addonsValueArr[key].cart_addon_setup_val_qty
            });
          }
          addonsSetup.push({
            addon_setup_id: item.addons_setup[l].cart_addon_setup_id,
            addon_setup_title: item.addons_setup[l].cart_addon_setup_title,
            addon_setup_selection:
              item.addons_setup[l].cart_addon_setup_selection,
            addon_setup_amount: item.addons_setup[l].cat_addon_setup_amount,
            addons_setup_values: tempArr
          });
        }
      }

      products.push({
        product_name: item.cart_item_product_name,
        product_unit_price: item.cart_item_unit_price,
        product_total_amount: item.cart_item_total_price,
        product_sku: item.cart_item_product_sku,
        product_image: item.cart_item_product_image,
        product_id: item.cart_item_product_id,
        product_qty: item.cart_item_qty,
        condiments: "",
        modifiers: modifierdata,
        bakers_modifiers: "",
        menu_set_components: comboData,
        baby_pack: "",
        product_special_notes: item.cart_item_special_notes,
        breaktime_indexflag: item.cart_item_breaktime_indexflag,
        breaktime_started: item.cart_item_breaktime_started,
        breaktime_ended: item.cart_item_breaktime_ended,
        setup: setupdata,
        equipments: equipments,
        addons_setup: addonsSetup
      });

      return products;
    });
    //     /* if  merge order date */
    var orderDate = this.state.startDate;
    var cookieDate =
      typeof cookie.load("order_date") === "undefined" ||
      cookie.load("order_date") === ""
        ? ""
        : moment(cookie.load("order_date")).format("YYYY/MM/DD");
    var orderTime =
      typeof cookie.load("orderTime") === "undefined" ||
      cookie.load("orderTime") === ""
        ? ""
        : cookie.load("orderTime");

    if (orderDate !== "" && orderTime !== "") {
      var splitedDate = cookieDate.split("/");
      var selMonth = splitedDate[1];
      var selDate = splitedDate[2];
      var selYear = splitedDate[0];
      var formatedDate = selYear + "-" + selMonth + "-" + selDate;
      orderDate = formatedDate + " " + orderTime;
    }
    console.log("orderDate", orderDate);
    //return false;
    if (orderDate === "" && paymentMode !== "initial") {
      // showAlert('Error', 'Please select order date and time.');
      // $.magnificPopup.open({
      //   items: {
      //     src: '.alert_popup'
      //   },
      //   type: 'inline'
      // });
      // return false;
    }

    if (paymentMode === "initial") {
      paymentMode = 2;
    }

    // /*orderDate = '2019-07-10 10:15';*/

    //     /* insert customner details */

    if (validation !== "Yes") {

      showLoader("order_placed", "class");

      var addressObject = {};
      var customerAddressId = 0;
      addressObject = {
        app_id: appId,
        customer_first_name: cookie.load("UserFname"),
        customer_last_name: cookie.load("UserLname"),
        customer_phone: cookie.load("UserMobile"),
        customer_email: cookie.load("UserEmail"),
        customer_address_line1: cookie.load("UserDefaultAddress"),
        customer_postal_code: cookie.load("UserDefaultPostalCode"),
        customer_address_name: this.state.delivery_add_floorno,
        customer_address_name2: this.state.delivery_add_unitno,
        refrence: cookie.load("UserId"),
        customer_status: "A",
        customer_order_status: "order"
      };
      if (cookie.load("defaultAvilablityId") === cateringId) {
        axios
          .post(
            apiUrl + "customer/secondary_address_add",
            qs.stringify(addressObject)
          )
          .then(res => {
            if (res.data.status === "ok") {
              customerAddressId = res.data.insert_id;
            }
          });
      }
    }

    var promotionApplied = cookie.load("promotionApplied");
    var promotionType = cookie.load("promotionType");
    var promotionAmount = cookie.load("promotionAmount");
    var promotionSource = cookie.load("promotionSource");
    var promoIsDelivery = cookie.load("promoIsDelivery");

    var cartDetailsArr = this.props.categoryState.cartDetailsMain;
    var promoTionArr = [];
    promoTionArr["promotionApplied"] = promotionApplied;
    promoTionArr["promotionAmount"] = promotionAmount;
    promoTionArr["promoIsDelivery"] = promoIsDelivery;
    var zoneDetailData = Array();
    zoneDetailData.push(
      cookie.load("zoneDetailData") !== "" &&
        typeof cookie.load("zoneDetailData") !== undefined
        ? cookie.load("zoneDetailData")
        : ""
    );
    /* this.props.zoneDetailData */
    
  let dynmaic_surcharge =   this.state.surcharge !== "" &&
        typeof this.state.surcharge !== undefined && Number( this.state.surcharge) &&
        this.state.surcharge > 0
          ? parseFloat(this.state.surcharge)
          : 0;

    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      zoneDetailData,
      cartDetailsArr,
      promoTionArr,
      dynmaic_surcharge
    );

    promotionAmount = promotionAmount !== "" ? promotionAmount : 0;
    /*if(promotionApplied === 'Yes') {
        var  cartGrandTotal = parseFloat(calculatedAmount['grandTotalAmount']);
      }else{
        var  cartGrandTotal = parseFloat(calculatedAmount['grandTotalAmount']);
      }*/
    var cartGrandTotal = parseFloat(calculatedAmount["grandTotalAmount"]);

    var BillingAddress = this.state.billing_add_addressline;
    var BillingPostalCode = this.state.billing_add_postalcode;
    var BillingUnitNo1 = this.state.billing_add_floor_no;
    var BillingUnitNo2 = this.state.billing_add_unitno;

    if (this.state.flag === "yes") {
      BillingAddress = this.state.delivery_add_addressline;
      BillingPostalCode = this.state.delivery_add_postalcode;
      BillingUnitNo1 = this.state.delivery_add_floorno;
      BillingUnitNo2 = this.state.delivery_add_unitno;
    }
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    var postObject = {};
    postObject = {
      /* cart details */
      app_id: appId,
      availability_id: cookie.load("defaultAvilablityId"),
      sub_total: parseFloat(calculatedAmount["cartSubTotalAmount"]).toFixed(2),
      grand_total: parseFloat(cartGrandTotal).toFixed(2),
      outlet_id: cookie.load("cateringOutletId"),
      zone_id: zoneIdTxt,
      table_number: "",
      order_status: 1,
      order_source: "Web",
      order_date: orderDate,
      order_remarks: this.state.spl_instruction,
      products: JSON.stringify(products),

      /* customer  Details */
      customer_id: cookie.load("UserId"),
      customer_address_id: customerAddressId,
      customer_fname: cookie.load("UserFname"),
      customer_lname: cookie.load("UserLname"),
      customer_mobile_no: cookie.load("UserMobile"),
      customer_email: cookie.load("UserEmail"),
      customer_address_line1: this.state.delivery_add_addressline,
      customer_address_line2: "",
      customer_postal_code: this.state.delivery_add_postalcode,
      customer_unit_no1: this.state.delivery_add_floorno,
      customer_unit_no2: this.state.delivery_add_unitno,

      billing_address_line1: BillingAddress,
      billing_postal_code: BillingPostalCode,
      billing_unit_no1: BillingUnitNo1,
      billing_unit_no2: BillingUnitNo2,

      /* Payment */
      payment_mode: 1 /*paymentMode, */,
      // "payment_reference": stripeReference,
      // "stripe_envir": 'test',
      // "payment_type": payGetWayType,
      // "order_capture_token": captureID,
      // "order_payment_getway_type": '',
      // "order_payment_getway_status": 'Yes',

      /* additional paras */
      delivery_charge: parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
        2
      ),
      order_subcharge_amount:dynmaic_surcharge,
      surchargeLog:
        this.state.surchargeLog !== ""
          ? JSON.stringify(this.state.surchargeLog)
          : "",
      additional_delivery: parseFloat(
        calculatedAmount["additionalDelivery"]
      ).toFixed(2),
      order_tat_time:
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT"),
      tax_charge: calculatedAmount["orderDisplayGst"],
      order_tax_calculate_amount: parseFloat(
        calculatedAmount["orderGstAmount"]
      ).toFixed(2),
      order_data_encode: "Yes",

      /* discount */
      order_discount_applied: "",
      order_discount_amount: "",
      order_discount_type: "",

      breaktime_enable: cartDetailsArr.cart_breaktime_enable,
      breaktime_count: cartDetailsArr.cart_breaktime_count,
      breaktime_list: cartDetailsArr.cart_breaktime_list
    };

    /* check  validation */
    if (validation === "Yes") {
      postObject["validation_only"] = "Yes";
    }

    /*  promo code object - start */
    if (promotionApplied === "Yes" && parseFloat(promotionAmount) > 0) {
      postObject["discount_applied"] = "Yes";
      postObject["discount_amount"] = promotionAmount;

      if (promotionType === "promoCode") {
        var categoryIdsDet = this.getProductIdsDet(finalcartItems);

        postObject["coupon_applied"] = "Yes";
        postObject["promo_code"] = promotionSource;
        postObject["cart_quantity"] = cartDetailsArr.cart_total_items;
        postObject["category_id"] = categoryIdsDet;
        postObject["coupon_amount"] = promotionAmount;
        postObject["promotion_delivery_charge_applied"] = promoIsDelivery;
      }

      if (promotionType === "points") {
        postObject["redeem_applied"] = "Yes";
        postObject["redeem_point"] = promotionSource;
        postObject["redeem_amount"] = promotionAmount;
      }
    }
 
    //console.log("calculatedAmount", postObject);
    //return false;
    //postObject['reward_point_status'] = 'Yes';
    if (valid) {
      $(".order_placed").addClass("loading-btn");
      axios
        .post(apiUrlCtrng + "orders/submit_order", qs.stringify(postObject))
        .then(res => {
          if (res.data.status === "ok") {
            $(".order_placed").removeClass("loading-btn");
            this.setState({ orderValidFail: 0 });
            var localOrderNo = res.data.common.local_order_no;
            this.showSuccessPage(
              localOrderNo,
              res.data.common.order_primary_id
            );
          } else if (res.data.status === "error") {
            $(".order_placed").removeClass("loading-btn");
            swal("Error!", res.data.message, "error");
          }
        })
        .catch(error => {});
    }
  }

  applyPointsAndPromo(type) {
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var reedemPointVal = this.state.reward_point_val;
    var promoCodeVal = this.state.promo_code_val;
    var usedPoints = this.state.used_reward_point;

    if (promoCodeVal === "") {
      swal("Error!", "Please enter valid promo code !", "error");
      return false;
    }

    /*var cartDetailsArr = (typeof cookie.load('cartDetail') !== 'undefined' && cookie.load('cartDetail') !== '') ? cookie.load('cartDetail') : [];
    var cartItemsSateArr = (typeof cookie.load('cartItemsList') !== 'undefined' && cookie.load('cartItemsList') !== '') ? cookie.load('cartItemsList') : [];*/

    var cartDetailsArr = this.props.categoryState.cartDetailsMain;
    var cartItemsSateArr = this.props.categoryState.cartItems;

    var subTotal =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_sub_total
        : 0;
    var totalItems =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_total_items
        : 0;

    if (type === "points") {
      if (parseFloat(reedemPointVal) > 0) {
        //showLoader('applypoints_cls','class');

        var postObject = {
          app_id: appId,
          reference_id: cookie.load("UserId"),
          redeem_point: reedemPointVal,
          cart_amount: subTotal
        };

        axios
          .post(apiUrl + "loyalty/apply_loyalityv1", qs.stringify(postObject))
          .then(res => {
            if (res.data.status === "success") {
              var pointDet = res.data.result_set;
              promotionApplied = "Yes";
              promotionType = "points";
              promotionAmount = pointDet.points_amount;
              promotionSource = reedemPointVal;
              promoIsDelivery = "";
              promoCodeVal = "";
              usedPoints = reedemPointVal;
            } else {
              if (promotionType === "points") {
                promotionApplied = "";
                promotionType = "";
                promotionAmount = "";
                promotionSource = "";
                promoIsDelivery = "";
                usedPoints = 0;
              }

              // showAlert('Rewards',res.data.message);

              $.magnificPopup.open({
                items: {
                  src: ".alert_popup"
                },
                type: "inline"
              });
            }

            // hideLoader('applypoints_cls','class');

            this.setState({
              reward_point_val: reedemPointVal,
              promo_code_val: promoCodeVal,
              promotion_applied: promotionApplied,
              promotion_type: promotionType,
              promotion_amount: promotionAmount,
              promotion_source: promotionSource,
              promoIs_delivery: promoIsDelivery,
              used_reward_point: usedPoints
            });
          });
      }
    } else if (type === "promoCode") {
      // showLoader('applypromo_cls','class');

      var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        promo_code: promoCodeVal,
        cart_amount: subTotal,
        cart_quantity: totalItems,
        category_id: categoryIdsDet,
        availability_id: cateringId
      };
      axios
        .post(
          apiUrl + "promotion_api_v2/apply_promotion",
          qs.stringify(postObject)
        )
        .then(res => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            var IsDelivery =
              pointDet.promotion_delivery_charge_applied === "Yes"
                ? "Yes"
                : "No";
            promotionApplied = "Yes";
            promotionType = "promoCode";
            promotionAmount = pointDet.promotion_amount;
            promotionSource = promoCodeVal;
            promoIsDelivery = IsDelivery;
            reedemPointVal = "";
            usedPoints = 0;
            swal("Success!", res.data.message, "success");
          } else {
            if (promotionType === "promoCode") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promotionSource = "";
              promoIsDelivery = "";
            }

            swal("Error!", res.data.message, "error");
          }

          cookie.save("reedemPointVal", reedemPointVal, { path: "/" });
          cookie.save("promoCodeVal", promoCodeVal, { path: "/" });
          cookie.save("promotionApplied", promotionApplied, { path: "/" });
          cookie.save("promotionType", promotionType, { path: "/" });
          cookie.save("promotionAmount", promotionAmount, { path: "/" });
          cookie.save("promotionSource", promotionSource, { path: "/" });
          cookie.save("promoIsDelivery", promoIsDelivery, { path: "/" });
          cookie.save("usedPoints", usedPoints, { path: "/" });

          this.setState({
            reward_point_val: reedemPointVal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
            used_reward_point: usedPoints
          });
        });
    }
  }

  removePromo(promo) {
    $("#promo_code_value").val("");
    $("#promo_code_value").trigger("focus");
    $(".apply_promo").show();
    $(".remove_promo").hide();
    cookie.remove("reedemPointVal");
    cookie.remove("promoCodeVal");
    cookie.remove("promotionApplied");
    cookie.remove("promotionType");
    cookie.remove("promotionAmount");
    cookie.remove("promotionSource");
    cookie.remove("promoIsDelivery");
    cookie.remove("applyCatrngPromo");
    cookie.remove("usedPoints");
    this.setState({
      reward_point_val: "",
      promo_code_val: "",
      promotion_applied: "",
      promotion_type: "",
      promotion_amount: "",
      promotion_source: "",
      promoIs_delivery: ""
    });
  }

  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }

  handlePromoChange(event) {
    this.setState({ promo_code_val: event.target.value });
  }
  specialNotes(cartLstDat) {
    var special_not = cartLstDat.cart_item_special_notes;

    return (
      <div className="products_info_item">
        <label className="title">Special Notes</label>
        <div className="products_info_options">
          <p>{special_not}</p>
        </div>
      </div>
    );
  }

  handleUpdateChange(e) {
    var start_validate = this.state.start_validate;
    var end_validate = this.state.end_validate;

    if (e.target.name === "start_time") {
      if (e.target.value === "") {
        this.setState(
          { start_validate: false },
          function() {
            this.showSuccess();
          }.bind(this)
        );
        $(".start_time_error").show();
      } else {
        this.setState(
          { start_validate: true, event_start: e.target.value },
          function() {
            this.showSuccess();
          }.bind(this)
        );
        $(".start_time_error").hide();
      }
    }

    if (e.target.name === "end_time") {
      if (e.target.value === "") {
        this.setState(
          { end_validate: false },
          function() {
            this.showSuccess();
          }.bind(this)
        );
        $(".end_time_error").show();
      } else {
        this.setState(
          { end_validate: true, event_end: e.target.value },
          function() {
            this.showSuccess();
          }.bind(this)
        );
        $(".end_time_error").hide();
      }
    }

    var itemid = e.target.attributes.getNamedItem("data-itemid");
    if (itemid !== "" && itemid !== undefined && itemid !== null) {
      itemid = e.target.attributes.getNamedItem("data-itemid").value;
      var rootid = e.target.attributes.getNamedItem("data-rootid").value;
      var CartItemList = itemid.split(",");
      var start_time = $("#" + rootid + " .start_time").val();
      var end_time = $("#" + rootid + " .end_time").val();
      if (start_time < end_time) {
        var currents = this;
        CartItemList.map(function(item) {
          if (item !== "") {
            currents.updateBreakTime(item, start_time, end_time);
          }
        });
      }
    }
  }

  updateBreakTime(cartItmId, startTime, endTime) {
    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
      cart_item_id: cartItmId,
      break_time: startTime,
      break_time_end: endTime
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }

    axios
      .post(
        apiUrlCtrng + "cart/updateBreakTime",
        qs.stringify(postCrtObject)
      )
      .then(res => {
        if (res.data.status === "ok") {
          this.loadCartDet();
          // swal("Success!", "Nice! Products updated to your cart", "success");
        } else {
          //swal("Error!", "Sorry! Products can`t update your cart", "error");
        }
      })
      .catch(function(error) {
        swal("Error!", "something went wrong", "error");
      });
  }

  showSuccess() {
    if (this.state.start_validate && this.state.end_validate) {
      var startTime = this.state.event_start;
      var endTime = this.state.event_end;
      if (startTime > endTime || startTime === endTime) {
        swal("Error!", "Invalid start & end time!", "error");
        $("#event_start_timer").prop("selectedIndex", "");
        $("#event_end_timer").prop("selectedIndex", "");
        this.setState({ start_validate: false, end_validate: false });
      } else {
        swal(
          "Success!",
          "All Set! Please click Order Now to continue.",
          "success"
        );
      }
    }
  }

  setupDet(cartLstDat) {
    var setupDt = cartLstDat.setup;
    var setupLbl = "";
    var setupPrice = 0;

    for (var key in setupDt) {
      setupLbl = setupDt[key].cart_setup_type;
      setupPrice += parseFloat(setupDt[key].cart_setup_tatalprice);
    }

    return (
      <div className="buffet_setup">
        <div className="row">
          <div className="col-sm-6 col-xs-8">
            <h5>
              <a href="javascript:void(0)">
                <span className="title">{setupLbl} Setup</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-4">
            <div className="amt">
              <span>{showPriceValue(setupPrice)}</span>
              <a className="remove" href="javascript:void(0)">
               <img src={crossImg} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="row">{this.setupList(setupDt)}</div>
      </div>
    );
  }

  setupList(setupDats) {
    if (Object.keys(setupDats).length > 0) {
      const setupDathtml = setupDats.map((setupVl, indx6) => (
        <p key={indx6}>
          {stripslashes(setupVl.cart_setup_name)} :{" "}
          {stripslashes(setupVl.cart_setup_description)}
        </p>
      ));
      return setupDathtml;
    }
  }

  loadSettup(setup, type) {
    let check_settup = this.checkSettup(setup, type);
    if (type === "Equipment") {
      return setup.map((settup, index) => {
        return (
          <p key={index}>
            {settup.cart_equipment_qty} X {settup.cart_equipment_description} (+
            {settup.cart_equipment_price})
          </p>
        );
      });
    } else if (type === "Addons") {
      return setup.map((settup, index) => {
        return (
          <div className="row pkgslist_additionrow" key={index}>
            <div className="col-sm-8 col-7">
              <label>{settup.cart_addon_setup_title}</label>
              <div className="pkgslist_subaddition">
                {this.loadAddonValues(settup.addons_setup_values)}
              </div>
            </div>
            <div className="col-sm-4 col-5 pkgsli_right text-right">
              {/* <span className="price">
                {showPriceValue(settup.cat_addon_setup_amount)}
              </span>
              <a
                className="remove"
                href="javascript:void(0)"
                onClick={this.removeCartIndividual.bind(
                  this,
                  settup.cart_addon_setup_cart_item_id,
                  settup.cart_addon_setup_primary_key,
                  "addons_setup",
                  "addons_setup"
                )}
              >
                <img src={crossImg} alt="" />
              </a> */}
            </div>
          </div>
        );
      });
    } else {
      if (check_settup) {
        return setup.map((settup, index) => {
          if (settup.cart_setup_type === type) {
            return (
              <div key={index}>
                {settup.cart_setup_name} ({settup.cart_setup_price}){" "}
              </div>
            );
          }
        });
      }
    }
  }

  settuptotalPrice(setup, type) {
    let totalPrice = 0;
    if (type === "Equipment") {
      setup.map(settup => {
        totalPrice += parseFloat(settup.cart_equipment_totalprice);
      });
    } else {
      setup.map(settup => {
        if (settup.cart_setup_type === type) {
          totalPrice += parseFloat(settup.cart_setup_tatalprice);
        }
      });
    }
    return totalPrice;
  }

  checkSettup(setup, type) {
    let count = 0;
    setup.map(settup => {
      if (settup.cart_setup_type === type) {
        count++;
      }
    });
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  }
  loadAddonValues(AddonValues) {
    return AddonValues.map((addonval, index) => {
      return (
        <p key={index}>
          {addonval.cart_addon_setup_val_title} (
          {addonval.cart_addon_setup_val_qty}X)
        </p>
      );
    });
  }

  checkoutProcess() {
    var order_date = this.state.orderDate;
    if (
    typeof cookie.load("UserId") === "undefined" ||
        cookie.load("UserId") === ""
    ) {
    window.$.magnificPopup.open({
        items: {
        src: "#login-popup"
        },
        type: "inline"
    });
    cookie.save("loginpopupTrigger", "fromchecatering", { path: "/" });
    // cookie.save("redirect_page", "/package/checkout");
    } else {
    //this.updateCartPackage(this.state.productsdetail.result_set, 0, 0);
    this.props.history.push("/package/checkout");
    // this.loadCartDet();
    }
  }

  render() {
      var totalAmnt = 0;
      if(cookie.load("promoCodeVal") && parseInt(cookie.load("promotionAmount")) > 0) {
          if(this.props.categoryState.cartDetailsMain) {
            totalAmnt = parseFloat(this.props.categoryState.cartDetailsMain.cart_grand_total) - parseFloat(cookie.load("promotionAmount"));   
          }
      } else {
        if(this.props.categoryState.cartDetailsMain) {
            totalAmnt = this.props.categoryState.cartDetailsMain.cart_grand_total;
        }
      }
    // var totalAmnt =
    //   cookie.load("promoCodeVal") &&
    //   parseInt(cookie.load("promotionAmount")) > 0
    //     ? parseFloat(this.props.categoryState.cartDetailsMain.cart_grand_total) -
    //       parseFloat(cookie.load("promotionAmount"))
    //     : this.props.categoryState.cartDetailsMain.cart_grand_total;
    let staticblockData = [];
    if (this.props.staticblockData) {
      staticblockData = this.props.staticblockData.result_set;
    }

    var zone_delivery_charge =
      this.state.zone_delivery_charge !== "" &&
      typeof this.state.zone_delivery_charge !== undefined
        ? parseFloat(this.state.zone_delivery_charge)
        : 0;
    if (zone_delivery_charge > 0) {
      totalAmnt = parseFloat(totalAmnt) + parseFloat(zone_delivery_charge);
    }

    var zone_additional_delivery_charge =
      this.state.zone_additional_delivery_charge !== "" &&
      typeof this.state.zone_additional_delivery_charge !== undefined
        ? parseFloat(this.state.zone_additional_delivery_charge)
        : 0;

    if (zone_additional_delivery_charge > 0) {
      totalAmnt =
        parseFloat(totalAmnt) + parseFloat(zone_additional_delivery_charge);
    }

    var surcharge =
      this.state.surcharge !== "" && typeof this.state.surcharge !== undefined
        ? parseFloat(this.state.surcharge)
        : 0;

	let {sur_deliverysetup_fee,sur_ear_deliverysetup_fee,sur_lat_teardown_fee,sur_deliverysetup_fee_name,sur_ear_deliverysetup_fee_name} = getSurchargesplitup(surcharge,this.state.surchargeLog);

    if (surcharge > 0) {
      totalAmnt = parseFloat(totalAmnt) + parseFloat(surcharge);
    }

    var gstPar = 0;
    var comSettings = this.state.globalSettings;
    if (comSettings && Object.keys(comSettings).length > 0) {
      gstPar =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;
      var gstAmount = getGstValue(gstPar, totalAmnt, "format");
      var gstAmountTxt = getGstValue(gstPar, totalAmnt, "");
      var totalAmnt = parseFloat(totalAmnt) + parseFloat(gstAmountTxt);
    }

    var same_as_del_address =
      parseInt(cookie.load("same_as_del_address")) === 1 ? true : false;
    return (
      <>

        <div className="container">
              <div className="text_center">
                <h4 className="checkout_title">Order Summary</h4>
              </div>
              <div className="product_orders">
                
                <div className="product_delivery product-card">
                  
                  <div className="order_det">
                    
                    {this.getCartDetList()}

                  </div>
                  <div
                    className="products_total"
                    style={{
                      display: totalAmnt > 0
                        ? "block"
                        : "none"
                    }}
                  >
                    <div className="products_total_item">
                      <p>Subtotal</p>
                      <span className="total_cart_price">
                        {this.props.categoryState.cartDetailsMain
                          ? showPriceValue(
                            this.props.categoryState.cartDetailsMain.cart_grand_total
                            )
                          : ""}
                      </span>
                    </div>
                    <div
                      className="products_total_item"
                      style={{
                        display: cookie.load("promotionAmount") ? "" : "none"
                      }}
                    >
                      <p>Discount </p>
                      <span>
                        {cookie.load("promotionAmount")
                          ? showPriceValue(cookie.load("promotionAmount"))
                          : ""}
                      </span>
                    </div>
                    
                    { (parseFloat(zone_delivery_charge) > 0  || parseFloat(zone_additional_delivery_charge) > 0 ) && 
                      <div className="products_total_item">
                        <p>Location surcharge</p>
                        <span>{showPriceValue(parseFloat(zone_delivery_charge)+parseFloat(zone_additional_delivery_charge))}</span>
                      </div>
                    }

                    {parseFloat(this.state.surcharge) > 0 && sur_deliverysetup_fee > 0 &&
                      <div className="products_total_item">
                        <p>Delivery & setup fee {sur_deliverysetup_fee_name}</p>
                        <span>{showPriceValue(sur_deliverysetup_fee)}</span>
                      </div>
                    }

                    {parseFloat(this.state.surcharge) > 0 && sur_ear_deliverysetup_fee > 0 &&
                      <div className="products_total_item">
                        <p>Early setup fee / Late setup fee {sur_ear_deliverysetup_fee_name}</p>
                        <span>{showPriceValue(sur_ear_deliverysetup_fee)}</span>
                      </div>
                    }

                    {parseFloat(this.state.surcharge) > 0 && sur_lat_teardown_fee > 0 &&
                      <div className="products_total_item">
                        <p>Late teardown fee</p>
                        <span>{showPriceValue(sur_lat_teardown_fee)}</span>
                      </div>
                    }
 
                    {parseFloat(gstPar) > 0 && (
                      <div className="products_total_item">
                        <p className="text-uppercase">GST ({gstPar}%)</p>
                        <span>{showPriceValue(gstAmount)}</span>
                      </div>
                    )}

                    <div className="products_total_item products_total_amount">
                      <p>Total </p>

                      <span>{showPriceValue(totalAmnt)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <Link
              className="btn btn_dark btn_br btn_upp btn_fill btn_big order_placed"
              to="#"
              onClick={this.postOrder.bind(this, 1)}
            >
              Checkout
            </Link> */}

                <Link
                  className="btn btn_green btn_fill btn_upp checkout_btn"
                  to="#"
                  onClick={this.checkoutProcess.bind(this)}
                >
                  Checkout
                </Link>
                
        <ReactTooltip />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZonedetail: () => {
      dispatch({
        type: GET_ZONE_DETAIL,
        outletId: cookie.load("cateringOutletId"),
        zoneId: ""
      });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getStaticblock: () => {
      dispatch({
        type: GET_STATIC_BLOCK,
        staticblock_list: "terms-and-conditions"
      });
    },
    getCarttimedata: obj => {
      dispatch({ type: GET_AVAILABLE_TIME, avlDateFlg: obj });
    },
    getCartdatedata: obj => {
      dispatch({ type: GET_AVAILABLE_DATE, avlDateFlg: obj });
    },
    getActivityCount: getObject => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    }
  };
};

const mapStateToProps = state => {
  var globalSettings, cartdate, carttime, staticblockData = [];

  if (
    state.settings !== undefined &&
    Object.keys(state.settings).length > 0
  ) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }
  var activityCountArr = [];
  if (
    state.activitycount !== undefined &&
    Object.keys(state.activitycount).length > 0
  ) {
    if (state.activitycount[0].status === "ok") {
      activityCountArr = state.activitycount[0].result_set;
    }
  }

  if (Object.keys(state.availabiledate).length > 0) {
    if ( state.availabiledate[0].status === "success" && Object.keys(state.availabiledate[0].result_set).length > 0 ) {
      cartdate = state.availabiledate[0];
    }
  }

  if (Object.keys(state.availabiletime).length > 0) {
    if ( state.availabiletime[0].status === "success" && Object.keys(state.availabiletime[0].result_set).length > 0 ) {
      carttime = state.availabiletime[0];
    }
  }

  if (Object.keys(state.staticblack).length > 0) {
    if ( state.staticblack[0].status === "ok" && Object.keys(state.staticblack[0].result_set).length > 0 ) {
      staticblockData = state.staticblack[0];
    }
  }

  return {
    settingsArr: globalSettings,
    zoneDetailData: state.zoneDetailData,
    staticblockData: staticblockData,
    cartdate: cartdate,
    carttime: carttime,
    activitycountArr: activityCountArr
  };
};

Ordersummary = connect(mapStateToProps, mapDispatchToProps)(Ordersummary);

export default withRouter(Ordersummary);
