/* Live */
export const appId = "01DF0942-0B19-4E95-8EBA-2BEFFD2EFFE9";

export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlCtrng = "https://ccpl.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";

// export const apiUrl = "https://ccpl.promobuddy.asia/api/";
// export const apiUrlV2 = "https://ccpl.promobuddy.asia/apiv2/";
// export const apiUrlPro = "https://ccpl.promobuddy.asia/ninjapro/";
// export const apiUrlCtrng = "https://ccpl.promobuddy.asia/cateringv2/";
// export const apiUrlNotify = "https://ccpl.promobuddy.asia/Pushorder/";

// export const baseUrl = "http://foodbarn.promobuddy.asia/";
// export const baseUrl = "http://localhost:3001/";
export const baseUrl = "https://thefoodbarn.com.sg/";

export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";
export const outletUrl = "https://ccpl.ninjaos.com/media/dev_team/outlet/";

// export const timThumpUrl = "https://ccpl.promobuddy.asia/timthumb.php?src=";
// export const mediaUrl = "https://ccpl.promobuddy.asia/media/dev_team/";
// export const blogImageUrl = "https://ccpl.promobuddy.asia/media/dev_team/blog/";
// export const tagImageUrl = "https://ccpl.promobuddy.asia/media/dev_team/tag/";
// export const proFolderUrl = "https://ccpl.promobuddy.asia/media/dev_team/products/";
// export const outletUrl = "https://ccpl.promobuddy.asia/media/dev_team/outlet/";

export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";
export const dineinId = "EF9FB350-4FD4-4894-9381-3E859AB74019";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Foodbarn";

export const fbAppId = "284924283452340";

export const stripeKey = "pk_test_mSKQhHZYbRvm1YvuX9mKN4zx";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "foodbarn";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const ConfigoutletCategory = { 1: "Cafe", 2: "Pantry" };
export const defaultpantryOutlet = "321";

export const instaToken = "IGQVJXVVBvS205QXppOFFpSzlrd3dWRXc4Sk9JcVF5RUkxbmJxd3JHYV9RMHpNc001bHA1MU5xQnRoX2k3SV94MHRtMlRvTTVzNk1iQW9tak5fRWhORURqT0l0SEFGcGtqYlN2OFhzc2NRVDN6eHc0SgZDZD";