/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { appId, apiUrlV2, deliveryId } from "../../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
} from "../../Helpers/SettingHelper";
import noimage from "../../../common/images/no-img-product.png";
var qs = require("qs");

import { GET_HIGHLIGHTPROLIST } from "../../../actions";

var Parser = require("html-react-parser");

class PromoProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentWillMount() {
    this.props.getHighlightList();
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            removePromoCkValue();
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            return false;
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#ordernowpopup",
        },
        type: "inline",
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        $("#proIndex-" + productDetail.product_primary_id).addClass("active");
        this.props.sateValChange("view_pro_data", productSlug);
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#ordernowpopup",
        },
        type: "inline",
      });
    }
    return false;
  }

  render() {
    let highlightArr = this.props.highlightproduct;
    let highlightlist = [];
    let highlightimagesource = "";
    let tagimagesource = "";

    if (Object.keys(highlightArr).length > 0) {
      if (highlightArr[0].status === "ok") {
        highlightlist = highlightArr[0].result_set;
        highlightimagesource = highlightArr[0].common.image_source;
        tagimagesource = highlightArr[0].common.tag_image_source;
      }
    }
    return (
      <section className="featuredpro-section">
        {Object.keys(highlightlist).length > 0 ? (
          <div className="featured-products-section">
            <div className="container">
              {this.props.homepromoBlk}
              <div className="featured-products-main">
                <ul className="products-list-ulmain">
                  {highlightlist.map((highlight, index) => {
                    var prodivId = "proIndex-" + highlight.product_primary_id;
                    var comboProId = "comboPro-" + highlight.product_slug;
                    if (index < 3) {
                      return (
                        <li
                          className="products-single-li"
                          id={prodivId}
                          key={index}
                        >
                          {highlight.product_tag_info !== "" &&
                            highlight.product_tag_info !== null && (
                              <div className="ribbon">
                                <span>{highlight.product_tag_info}</span>
                              </div>
                            )}
                          <div className="products-image-div">
                            {highlight.product_thumbnail !== "" ? (
                              <img
                                src={
                                  highlightimagesource +
                                  "/" +
                                  highlight.product_thumbnail
                                }
                              />
                            ) : (
                              <img src={noimage} />
                            )}
                          </div>

                          <div className="product-info-div">
                            <div className="product-title-maindiv">
                              <div className="product-title">
                                <h3>
                                  {highlight.product_alias !== ""
                                    ? stripslashes(highlight.product_alias)
                                    : highlight.product_name
                                    ? stripslashes(highlight.product_name)
                                    : ""}
                                </h3>
                              </div>
                            </div>
                            {highlight.product_cost > 0 && (
                              <h4 className="original-price">
                                {showPriceValue(highlight.product_cost)}
                              </h4>
                            )}

                            <div className="products-ordernow-action">
                              <div className="product-price">
                                <h3>
                                  {showPriceValue(highlight.product_price)}
                                </h3>
                              </div>
                              <div className="addcart_row addcart_done_maindiv">
                                <div className="qty_bx">
                                  <span
                                    className="qty_minus"
                                    onClick={this.proQtyAction.bind(
                                      this,
                                      highlight.product_primary_id,
                                      "decr"
                                    )}
                                  >
                                    -
                                  </span>
                                  <input
                                    type="text"
                                    className="proqty_input"
                                    readOnly
                                    value="1"
                                  />
                                  <span
                                    className="qty_plus"
                                    onClick={this.proQtyAction.bind(
                                      this,
                                      highlight.product_primary_id,
                                      "incr"
                                    )}
                                  >
                                    +
                                  </span>
                                </div>
                                <button
                                  className="button btn_black order_done"
                                  onClick={this.addToCartSimple.bind(
                                    this,
                                    highlight,
                                    "done"
                                  )}
                                >
                                  Done
                                </button>
                              </div>

                              {highlight.product_stock > 0 ||
                              highlight.product_stock === null ? (
                                highlight.product_type === "1" ? (
                                  <a
                                    className="button order_nowdiv smiple_product_lk disbl_href_action"
                                    href="/"
                                    onClick={this.addToCartSimple.bind(
                                      this,
                                      highlight,
                                      "initial"
                                    )}
                                  >
                                    Add To Cart
                                  </a>
                                ) : (
                                  <a
                                    href="/"
                                    onClick={this.viewProDetail.bind(
                                      this,
                                      highlight
                                    )}
                                    title="Product Details"
                                    id={comboProId}
                                    className="button order_nowdiv compo_product_lk"
                                  >
                                    Order Now
                                  </a>
                                )
                              ) : (
                                <a
                                  className="button disabled disbl_href_action"
                                  href="/"
                                >
                                  Sold Out
                                </a>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
              <div className="home-bottom-link">
                <Link to={"/promotions"}>
                  <strong>Shop</strong> All Promotions
                </Link>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    highlightproduct: state.highlightproductlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHighlightList: () => {
      dispatch({ type: GET_HIGHLIGHTPROLIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(PromoProducts);
